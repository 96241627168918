import React, {useContext} from "react"
import { Link } from "react-router-dom"
import { AppContext } from "../App"

const Footer = () => {

    var context = useContext(AppContext)

    return <footer>
        {/*
    <div className="footer-top-wrap">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-sm-6">
                    <div className="footer-box-layout1">
                        <div className="footer-logo">
                         <Link to="/" className="temp-logo">
                                <h2 className="font-weight-bold text-uppercase p-0 m-0">
                                    <span style={{color:"red"}}>a</span>
                                    <span style={{color:"green"}}>v</span>
                                    <span style={{color:"blue"}}>g</span>
                                    <span style={{color:"orange"}}>u</span>
                                </h2>
                            </Link>
                        </div>
                        <p>
                            {context.langdata["slogan"]}
                        </p>
                        <ul className="footer-social">
                            <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i className="fab fa-google-plus-g"></i></a></li>
                            <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i className="fab fa-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="footer-box-layout1">
                        <div className="footer-title">
                            <h3>{context.langdata["HeaderLinks1Title"]}</h3>
                        </div>
                        <div className="footer-menu-box">
                            <ul>
                                <li><a href="#">Selling Tips</a></li>
                                <li><a href="#">Buy and Sell Quickly</a></li>
                                <li><a href="#">Membership</a></li>
                                <li><a href="#">Banner Advertising</a></li>
                                <li><a href="#">Promote Your Ad</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="footer-box-layout1">
                        <div className="footer-title">
                            <h3>{context.langdata["HeaderLinks2Title"]}</h3>
                        </div>
                        <div className="footer-menu-box">
                            <ul>
                                <li><a href="#">Company & Contact Info</a></li>
                                <li><a href="#">Blog & Articles</a></li>
                                <li><a href="#">Sitemap</a></li>
                                <li><a href="#">Terms of Service</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="footer-box-layout1">
                        <div className="footer-title">
                            <h3>{context.langdata["HeaderLinks3Title"]}</h3>
                        </div>
                        <div className="footer-menu-box">
                            <ul>
                                <li><a href="#">Live Chat</a></li>
                                <li><a href="#">FAQ</a></li>
                                <li><a href="#">How to Stay Safe</a></li>
                                <li><a href="#">Terms & Conditions</a></li>
                                <li><a href="#">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
*/}
    <div className="footer-bottom-wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-2">
                    <div className="copyright-text">
                        © Copyright 2023.
                    </div>
                </div>
                <div className="col-md-8">
                    <ul className="list-inline w-100 footerLinks">
                               <li className="list-inline-item">
                                    <Link to="/ads/category/1">{context.langdata["Auto"]}</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to="/ads/category/2">{context.langdata["Realty"]}</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to="/ads/category/3">{context.langdata["Work"]}</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to="/ads/category/4">{context.langdata["Services"]}</Link>
                                </li>
                    </ul>   
                </div>
                <div className="col-md-2">
                        <Link to="/" className="temp-logo">
                                <h2 className="font-weight-bold text-uppercase p-0 m-0">
                                    <span style={{color:"red"}}>a</span>
                                    <span style={{color:"green"}}>v</span>
                                    <span style={{color:"blue"}}>g</span>
                                    <span style={{color:"orange"}}>u</span>
                                </h2>
                        </Link>
                </div>
            </div>
        </div>
    </div>
</footer>
}

export default Footer