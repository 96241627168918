import { AdFavorites } from "../../models/User"
import { ReducerAction } from "./reducers"

const FavoritesCommands = {
    SET_USER_FAVOURITES: 'SET_USER_FAVOURITES',
    TOGGLE_FAVORITE_COUNTRY: 'TOGGLE_FAVORITE_COUNTRY',
}

export const userfavorites = (state:any = null, action:ReducerAction) => {
    switch (action.type) {
        case FavoritesCommands.SET_USER_FAVOURITES:
            return action.params
        case FavoritesCommands.TOGGLE_FAVORITE_COUNTRY:
            var newfavor = [...state]
            if (action.params.newstate == true)
            {
                if (newfavor.find((f:AdFavorites)=>f.ad.id == action.params.id) == undefined)
                {
                    newfavor.push({ad: {id: action.params.id}})
                }
                
                return [...newfavor]
            } else
            {
                var newfavordata = newfavor.filter((f:AdFavorites)=>f.ad.id != action.params.id)    
                return [...newfavordata]
            }
            return {...state}
        default:
            return state
    }
}

export {FavoritesCommands}
