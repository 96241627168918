import React, {useContext, useEffect, useState} from "react"
import { AppContext } from "../App"
import { PlaceItem } from "../models/PlaceItem"
import { GetByLanguage } from "./langs/languages"
import { Link } from "react-router-dom"
import Moment from 'react-moment';
import moment from 'moment';

import {
    Routes,
    Route,
    useLocation,
} from "react-router-dom"

interface ILocationProps
{
    item:PlaceItem
    lang:string
}

const Location = (props:ILocationProps)=>
{
    var imageSrc = props.item.photos ? props.item.photos[0] : "https://i.pinimg.com/originals/25/4c/2c/254c2c4ed58b0382d8aba149da711eb0.jpg"
    return <div className="location-box-layout1">
    <Link to={"/locations/"+props.item?.id}>
        <div className="item-img">
            <img src={imageSrc} alt="Brooklyn"/>
        </div>
        <div className="item-content">
            <h3 className="item-title">{GetByLanguage(props.item, props.lang)}</h3>
            <div className="item-count">{props.item.views} Ads</div>
        </div>
    </Link>
</div>
}

var lastPlaces:any = null
const Locations = ()=>
{
    var context = useContext(AppContext)
    var [locations, setLocations]  = useState<PlaceItem[]>(context.dataStore?.getState()?.places ?? [])
    var browserlocation = useLocation()

    const StoreUpdate = () =>
    {
        var currentPlaces = context.dataStore.getState().places
        if (lastPlaces!=currentPlaces)
        {
            setLocations(context.dataStore.getState().places);
            lastPlaces = currentPlaces
        }
    }

    useEffect(()=>
    {
        context.dataStore?.subscribe(StoreUpdate);
    }, []);

    useEffect(()=>
    {
        var ev = new Event('update_carousels_places', { bubbles: true});
        document.dispatchEvent(ev);
    }, [locations, browserlocation.pathname])
    
    return  <section className="section-padding-top-heading bg-accent" style={{paddingTop:0}}>
    <div className="container">
        <div className="flex-heading-layout1">
            <div className="heading-layout2">
                <Link to="/locations" className="item-btn">
                    <h2 className="heading-title" style={{fontSize:"12sp"}}>{context.langdata['The best places']}</h2>
                </Link>
            </div>
            <div id="owl-nav10" className="smart-nav-layout1 mg-t-5">
                <span className="rt-prev">
                    <i className="fas fa-angle-left"></i>
                </span>
                <span className="rt-next">
                    <i className="fas fa-angle-right"></i>
                </span>
            </div>
        </div>
        <div className="rc-carousel places_carousels" data-loop="true" data-items="10" data-margin="0" data-custom-nav="#owl-nav10" data-autoplay="true" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" data-nav="false" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="false" data-r-x-small-dots="false" data-r-x-medium="2" data-r-x-medium-nav="false" data-r-x-medium-dots="false" data-r-small="3" data-r-small-nav="false" data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="false" data-r-medium-dots="false" data-r-large="1" data-r-large-nav="false" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="false" data-r-extra-large-dots="false">
        {locations?.map((item, index)=>
        {
            return <Location item={item} lang={context.lang}></Location>
        })}

        </div>


        <div className="d-flex justify-content-center">
                <div className="header-action-layout1">
                    <ul>
                        <li className="header-btn">
                            <Link to="/locations" className="item-btn">{context.langdata["Open locations"]}</Link>
                        </li>
                    </ul>
                </div>
            </div>
    </div>
</section>
    
}

export default Locations