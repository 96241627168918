import { ReducerAction } from "./reducers"

const RegionsCommands = {
    REGIONS_LOADED: 'REGIONS_LOADED',
    COUNTIRES_LOADED: 'COUNTIRES_LOADED',
    CITIES_LOADED: 'CITIES_LOADED',
    ACTIVE_COUNTRY_SET: 'ACTIVE_COUNTRY_SET',
    ACTIVE_LOCATION_SET: 'ACTIVE_LOCATION_SET'
}

export const regions = (state:any = null, action:ReducerAction) => {
    switch (action.type) {
        case RegionsCommands.COUNTIRES_LOADED:
            var newstate = {...state};
            newstate["Countries"] = [...action.params]
            return newstate;
        case RegionsCommands.REGIONS_LOADED:
            var newstate = {...state};
            newstate["Regions"] = [...action.params]
            return newstate;
        case RegionsCommands.CITIES_LOADED:
            var newstate = {...state};
            newstate["Cities"] = [...action.params]
            return newstate;
        case RegionsCommands.ACTIVE_COUNTRY_SET:
            var newstate = {...state};
            newstate["ActivedCountry"] = action.params
            return newstate;
        case RegionsCommands.ACTIVE_LOCATION_SET:
                var newstate = {...state};
                newstate["ActivedLocation"] = action.params
                return newstate;
        default:
            return state
    }
}

export {RegionsCommands}