import {User} from './User'

import { FiDollarSign } from 'react-icons/fi';
import {BsCurrencyEuro} from 'react-icons/bs'
import {BiRuble} from 'react-icons/bi'
import {BiPound} from 'react-icons/bi'
import {BiLira} from 'react-icons/bi'
import { IconType } from 'react-icons';

import {
    ApolloProvider, createHttpLink, ApolloClient, InMemoryCache, from, HttpLink, useLazyQuery, ApolloError, useMutation
  } from "@apollo/client"; 

import graphql from "../gql/gqlqueries";
import { CategoryItem } from './Category';
import { Generation, Marka, Model } from './CarMarka';
import { CityItem, CountryItem, RegionItem } from './Region';

export type PhotoItem = 
{
    url:string
    title:string
}

export type PhotoAlbum = 
{
   albumTitle:string
   photos:PhotoItem[]
}

export type UrlVideo = 
{
    url:string
    title:string
}

enum Сurrency {
    Pound, TurkishLira, Euro, Ruble, Dollar
}

const GetCurrencyIcon = (currency:string | number):JSX.Element =>
{
    if (typeof currency == "number")
    {
        if (currency == Сurrency.Dollar) return <FiDollarSign></FiDollarSign>
        if (currency == Сurrency.Euro) return <BsCurrencyEuro></BsCurrencyEuro>
        if (currency == Сurrency.Ruble) return <BiRuble></BiRuble>
        if (currency == Сurrency.Pound) return <BiPound></BiPound>
        if (currency == Сurrency.TurkishLira) return <BiLira></BiLira>
        return <></>    
    }

    if (typeof currency == "string")
    {
        if (currency.toLowerCase() == "dollar") return <FiDollarSign></FiDollarSign>
        if (currency.toLowerCase() == "euro") return <BsCurrencyEuro></BsCurrencyEuro>
        if (currency.toLowerCase() == "ruble") return <BiRuble></BiRuble>
        if (currency.toLowerCase() == "pound") return <BiPound></BiPound>
        if (currency.toLowerCase() == "lira") return <BiLira></BiLira>
        return <></>
    }

    

    return <></>
}

type adownerEnum = undefined | "OWNER" | "POSREDNIK" | "MAKER"
type propertyEnum = undefined | "FLAT" | "APARTMENT" | "HOME"
type propertyStateEnum = undefined | "NEW" | "INUSE"
type bathroomEnum = undefined | "COMBINED" | "SEPARATED"
type repairEnum = undefined | "REQUIRED" | "COSMETIC" | "EURO" | "DESIGNER"
type hometypeEnum = undefined | "BRICK" | "PANEL" | "BLOCK" | "MONOLITHIC" | "MONOLITHIC-BRICK" | "WOOD"
type caradtypeEnum = undefined | "PERSONAL" | "FORSALE"
type carstateEnum = undefined | "NOTBROKEN" | "BROKEN"
type costtypeEnum = undefined | "FIXED" | "NEGOTIABLE" | "CALLFORPRICE"
type authenticityEnum = undefined | "ORIGINAL" | "COPY"
type currencyType = "DOLLAR" | "EURO" | "RUBLE" | "POUND" | "LIRA"

type UserData = 
{
    id: string
    avatar: string
    UserF: string
    UserI: string
    UserO: string
    account: string
    phone: string
    hasShop: boolean
    shoplogo: string
    shopbanner: string
}

type Review = 
{
    id: string
    owner: UserData
    review_ru: string
    review_eng: string
    review_tur: string 
    review_greek: string 
    review_deutsch: string 
    createdAt: Date 
    updatedAt: Date 
    healthy: number
    dealCompleted: boolean 
    completedbySeller: boolean
}

type Cusov = 
{
    id: string
}

type Engine = 
{
    id: string
}

type Driveunit = 
{
    id: string
}

type Transmission = 
{
    id: string
}

type Rudderposition = 
{
    id: string
}

type Color = 
{
    id: string
}

type AdItem =
{
    id: string
    user: UserData
    category: CategoryItem
    
    title_ru: string
    title_eng: string
    title_tur: string
    title_greek: string
    title_deutsch: string
    
    costtype: costtypeEnum
    cost: number
    recalculatedcost: number
    state: propertyStateEnum
    brand: string
    authenticity: authenticityEnum

    description_ru: string
    description_eng: string
    description_tur: string
    description_greek: string
    description_deutsch: string
    
    features_ru: string
    features_eng: string
    features_tur: string
    features_greek: string
    features_deutsch: string

    photos?:[string]
    videos?:[string]

    // если недвижка
    address_ru: string
    address_eng: string
    address_tur: string
    address_greek: string
    address_deutsch: string

    adowner: adownerEnum
    proptype: propertyEnum
    propstate: propertyStateEnum
    level: number
    roomsCount: number
    balcony: boolean
    loggia: boolean
    wardrobe: boolean
    panoramicwindows: boolean
    totalarea: number
    livingspace: number
    сeilingheight: number
    bathroom: bathroomEnum
    
    windowstothecourtyard: boolean
    windowstothestreet: boolean
    windowsonthesunnyside: boolean
    kitchen: boolean
    clothesstorage: boolean
    sleepingplaces: boolean
    airconditioner: boolean
    fridge: boolean
    washingmachine: boolean
    dishwasher: boolean
    waterheater: boolean
    
    repair: repairEnum

    homeType: hometypeEnum
    concierge: boolean
    garbagechute: boolean
    gas: boolean

    makeYear: number
    levelsInHome: number
    passengerelevator: number
    servicelift: number

    yardclosedarea: boolean
    yardplayground: boolean
    yardsportsground: boolean

    parkingunderground: boolean
    parkinggroundmultilevel: boolean
    parkingopenintheyard: boolean
    parkingbehindthegateintheyard: boolean

    // если авто
    caradtype: caradtypeEnum
    color?: Color
    video: string
    mileage: number
    carstate: carstateEnum
    vin: string
    carnumber: string
    marka?: Marka
    model?: Model
    generation?: Generation
    yearofissue: number
    cusov?: Cusov
    engine?: Engine
    driveunit?: Driveunit
    transmission?: Transmission
    rudderposition: number

    // конктакты продавца
    country?: CountryItem
    region?: RegionItem
    city?: CityItem
    postalcode: string
    contactaddress_ru: string
    contactaddress_eng: string
    contactaddress_tur: string
    contactaddress_greek: string
    contactaddress_deutsch: string
    phones: string
    whatsapp: string
    email: string

    location: string
    locationLong?: number
    locationAlt?: number
    dontshowmap: boolean
    
    currency:currencyType
    trending?: boolean
    toprated?: boolean

    makedAt: string
    updatedAt: string
    createdAt: string
    Actived: boolean
    draft: boolean
    views:number

    reviews: Review[]
}

var TestUser = User.TestUser()

const useAdsList = (OnCompleted:(data:any)=>void, OnError:(error:ApolloError)=>void) =>
{
    const [LoadAds] = useLazyQuery(graphql.querues.ADS_QUERY,
        {
            fetchPolicy: "network-only",
            nextFetchPolicy: "network-only",
            onCompleted:(data)=>
            {
                OnCompleted(data)               
            },
            onError: (error) =>
            {
                OnError(error)
            }
        });    

    return LoadAds
}


var adsList: AdItem[] = []

const EmptyAdItem: AdItem = {
    Actived: false,
    id: '',
    user: {
        UserF: '',
        UserI: '',
        UserO: '',
        account: '',
        avatar: '',
        id: '',
        phone: '',
        hasShop: false,
        shopbanner: '',
        shoplogo: '',
    },
    category: {id:'', title_ru:'', title_eng:'', title_tur:'', title_greek:'', title_deutsch:'', autocar: false, property: false, form: '', icon: [], itemsCount:0, inChildItemsCount: 0},
    title_ru: '',
    title_eng: '',
    title_tur: '',
    title_greek: '',
    title_deutsch: '',
    costtype: undefined,
    cost: 0,
    recalculatedcost: 0,
    state: undefined,
    brand: '',
    authenticity: undefined,
    description_ru: '',
    description_eng: '',
    description_tur: '',
    description_greek: '',
    description_deutsch: '',
    features_ru: '',
    features_eng: '',
    features_tur: '',
    features_greek: '',
    features_deutsch: '',
    address_ru: '',
    address_eng: '',
    address_tur: '',
    address_greek: '',
    address_deutsch: '',
    adowner: undefined,
    proptype: undefined,
    propstate: undefined,
    level: 0,
    roomsCount: 0,
    balcony: false,
    loggia: false,
    wardrobe: false,
    panoramicwindows: false,
    totalarea: 0,
    livingspace: 0,
    сeilingheight: 0,
    bathroom: undefined,
    windowstothecourtyard: false,
    windowstothestreet: false,
    windowsonthesunnyside: false,
    kitchen: false,
    clothesstorage: false,
    sleepingplaces: false,
    airconditioner: false,
    fridge: false,
    washingmachine: false,
    dishwasher: false,
    waterheater: false,
    repair: undefined,
    homeType: undefined,
    concierge: false,
    garbagechute: false,
    gas: false,
    makeYear: 0,
    levelsInHome: 0,
    passengerelevator: 0,
    servicelift: 0,
    yardclosedarea: false,
    yardplayground: false,
    yardsportsground: false,
    parkingunderground: false,
    parkinggroundmultilevel: false,
    parkingopenintheyard: false,
    parkingbehindthegateintheyard: false,
    caradtype: undefined,
    color: undefined,
    video: '',
    mileage: 0,
    carstate: undefined,
    vin: '',
    carnumber: '',
    marka: undefined,
    model: undefined,
    generation: undefined,
    yearofissue: 0,
    cusov: undefined,
    engine: undefined,
    driveunit: undefined,
    transmission: undefined,
    rudderposition: 0,
    country: undefined,
    region: undefined,
    city: undefined,
    postalcode: '',
    contactaddress_ru: '',
    contactaddress_eng: '',
    contactaddress_tur: '',
    contactaddress_greek: '',
    contactaddress_deutsch: '',
    phones: '',
    whatsapp: '',
    email: '',
    location: '',
    dontshowmap: false,
    currency: 'EURO',
    makedAt: '',
    updatedAt: '',
    createdAt: '',
    draft: false,
    views: 0,
    reviews: []
}

export {Сurrency, adsList, GetCurrencyIcon, useAdsList, EmptyAdItem}
export type {AdItem, currencyType, Review, Cusov, Engine, Driveunit, Transmission, Color }
