class Store
{
    id:string = ""
    userId:string=""
    banner: string=""
    logo: string=""
    openalways: Boolean = false
    houers: string = ""
    name: string = ""
    email1: string = ""
    email2: string = ""
    website: string = ""
    address: string = ""
    description: string = ""
    vk: string = ""
    facebook: string = ""
    gmail: string = ""
    whatsup: string = ""
    telegram: string = ""
    twitter: string = ""
    youtube: string = ""
    phone1: string = ""
    phone2: string = ""
    slogan: string=""
    likes: Number = 0
    dislikes: Number = 0
    views: Number = 0
    commentsCount: Number = 0

    constructor(data?:any)
    {
        this.id = data?.id ?? ""
        this.userId = data?.userId ?? ""
        this.banner = data?.banner ?? ""
        this.logo = data?.logo ?? ""
        this.openalways = data?.openalways ?? false
        this.houers = data?.houers ?? ""
        this.name = data?.name ?? ""
        this.email1 = data?.email1 ?? ""
        this.email2 = data?.email2 ?? ""
        this.website = data?.website ?? ""
        this.address = data?.address ?? ""
        this.description = data?.description ?? ""
        this.slogan = data?.slogan ?? ""
        this.vk = data?.vk ?? ""
        this.gmail = data?.gmail ?? ""
        this.whatsup = data?.whatsup ?? ""
        this.telegram = data?.telegram ?? ""
        this.twitter = data?.twitter ?? ""
        this.youtube = data?.youtube ?? ""
        this.facebook = data?.facebook ?? ""
        this.phone1 = data?.phone1 ?? ""
        this.phone2 = data?.phone2 ?? ""
        this.likes = data?.likes ?? 0
        this.dislikes = data?.dislikes ?? 0
        this.views = data?.views ?? 0
        this.commentsCount = data?.commentsCount ?? 0
    }
}


export {Store}

