import * as React from 'react';

import {
    ApolloProvider, createHttpLink, ApolloClient, InMemoryCache, from, HttpLink, useLazyQuery, ApolloError
  } from "@apollo/client"; 

import graphql from "../gql/gqlqueries";


type CategoryItem  = 
{
    id:string
    title_ru:string
    title_eng:string
    title_tur:string
    title_greek:string
    title_deutsch:string
    parent?:string
    autocar: boolean
    property: boolean
    icon:string[]
    form: string
    itemsCount: number
    inChildItemsCount: number
}

const useCategory = (OnDone:(data:any)=>void, OnError?:(error:ApolloError)=>void) =>
{

    const [LoadCategories, { loading:l6, error:userAccountError, data:d6 }] = useLazyQuery(graphql.querues.CATEGORIES_QUERY,
        {
            fetchPolicy: "network-only",
            nextFetchPolicy: "network-only",
            onCompleted:function (data)
            {
                OnDone(data)
            },
            onError: (error) =>
            {
                if (OnError) OnError(error)
            }
        });    

    return LoadCategories
}


export {useCategory}
export type {CategoryItem}