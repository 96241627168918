import React, { useContext, useState, useEffect } from "react";
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import graphql from "../gql/gqlqueries";

import { AdItem } from "../models/AdItem";

const useAd = (id: string | undefined): [AdItem | undefined, any, any] => {

    var [item, setItem] = useState<AdItem | undefined>(undefined);
    
    var [loadAdItem] = useLazyQuery(graphql.querues.AD_QUERY, 
        { variables:{id:id}, 
        onCompleted: (data) => {
          setItem(data.ad)
    }})

    var [viewAdd] = useMutation(graphql.mutations.AD_VIEW_ADD, 
        { variables:{id:id},
        onCompleted: (data) => {
            
    }})

    return [item, loadAdItem, viewAdd]
}

const useSubscribe = (id: string | undefined): [boolean | undefined, any] => {
    var [item, setItem] = useState<boolean | undefined>(undefined);
    
    var [subscribeAd] = useMutation(graphql.mutations.AD_SUBSCRIBE, 
    {
        onCompleted: (data) => {
           setItem(data.subscribeAd)
    }})

    var [subscribeAdGet] = useLazyQuery(graphql.querues.AD_SUBSCRIBESTATE, 
        { variables:{id:id}, 
        onCompleted: (data) => {
            setItem(data.subscribeState)
    }})

    useEffect(() => {
        subscribeAdGet()
    }, [id])

    return [item, subscribeAd]
}

export default useAd;
export {useSubscribe};