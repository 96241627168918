import React, { MouseEventHandler, useContext, useEffect, useState, FC } from "react";
import {PaymentItem} from '../../models/PaymentItem'
import {AppContext} from '../../App'

interface IPaymentsProps
{
    items?:PaymentItem[]
}

const Payments:FC<IPaymentsProps> = ({items}) => {

    var context = useContext(AppContext)

    if (items == null || items.length == 0)
    return <h3 className="text-center">{context.langdata["No data"]}</h3>


    return <div className="table-responsive">
    <table className="table table-bordered table-striped">
        <thead>
            <tr>
                <th>Payment ID</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Status</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody>
            {items?.map(item => {
                return <tr>
                <td><a href="#">2125</a></td>
                <td>
                    <div className="price-amount">
                        10
                        <span className="currency-symbol">$</span>
                    </div>
                </td>
                <td>Direct Bank Transfer</td>
                <td>Pending</td>
                <td>December 14, 2019 @ 6:49 am</td>
            </tr>
            })}
        </tbody>
    </table>
</div>
}


export default Payments