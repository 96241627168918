import { ReducerAction } from "./reducers"

const CurrencyCommands = {
    SET_CURRENCY: 'SET_CURRENCY'
}

export const currency = (state = {}, action:ReducerAction) => {
    switch (action.type) {
        case CurrencyCommands.SET_CURRENCY:
            var newstate = {...state}
            newstate[action.params.currency] = action.params.coefficient
            return newstate
        default:
            return state
    }
}

export {CurrencyCommands}