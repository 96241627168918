import { AdItem } from "../../models/AdItem"
import { CategoryItem } from "../../models/Category"
import { NewItem } from "../../models/NewItem"
import { PlaceItem } from "../../models/PlaceItem"
import { CityItem, CountryItem, RegionItem } from "../../models/Region"


var GetByLanguage = (item:(CategoryItem | RegionItem | CountryItem | CityItem | NewItem | PlaceItem | AdItem), lang:string, prop:String = "") =>
{
    if ((item as CountryItem).Country_ru)
    {
        if (lang == "ru") return (item as CountryItem).Country_ru ?? ""
        if (lang == "en") return (item as CountryItem).Country_eng ?? ""
        if (lang == "tur") return (item as CountryItem).Country_tur ?? ""
        if (lang == "greek") return (item as CountryItem).Country_greek ?? ""
        if (lang == "deutsch") return (item as CountryItem).Country_deutsch ?? ""
    }

    if ((item as CategoryItem).title_ru)
    {
        if (lang == "ru") return (item as CategoryItem).title_ru ?? ""
        if (lang == "en") return (item as CategoryItem).title_eng ?? ""
        if (lang == "tur") return (item as CategoryItem).title_tur ?? ""
        if (lang == "greek") return (item as CategoryItem).title_ru ?? ""
        if (lang == "deutsch") return (item as CategoryItem).title_ru ?? ""
    }

    if ((item as RegionItem).Region_ru)
    {
        if (lang == "ru") return (item as RegionItem).Region_ru ?? ""
        if (lang == "en") return (item as RegionItem).Region_eng ?? ""
        if (lang == "tur") return (item as RegionItem).Region_tur ?? ""
        if (lang == "greek") return (item as RegionItem).Region_greek ?? ""
        if (lang == "deutsch") return (item as RegionItem).Region_deutsch ?? ""
    }

    if ((item as CityItem).City_ru)
    {
        if (lang == "ru") return (item as CityItem).City_ru ?? ""
        if (lang == "en") return (item as CityItem).City_eng ?? ""
        if (lang == "tur") return (item as CityItem).City_tur ?? ""
        if (lang == "greek") return (item as CityItem).City_greek ?? ""
        if (lang == "deutsch") return (item as CityItem).City_deutsch ?? ""
    }

    if ((item as NewItem).title_ru && prop == "")
    {
        if (lang == "ru") return (item as NewItem).title_ru ?? ""
        if (lang == "en") return (item as NewItem).title_eng ?? ""
        if (lang == "tur") return (item as NewItem).title_tur ?? ""
        if (lang == "greek") return (item as NewItem).title_greek ?? ""
        if (lang == "deutsch") return (item as NewItem).title_deutsch ?? ""
    }

    if ((item as PlaceItem).title_ru)
    {
        if (lang == "ru") return (item as PlaceItem).title_ru ?? ""
        if (lang == "en") return (item as PlaceItem).title_eng ?? ""
        if (lang == "tur") return (item as PlaceItem).title_tur ?? ""
        if (lang == "greek") return (item as PlaceItem).title_greek ?? ""
        if (lang == "deutsch") return (item as PlaceItem).title_deutsch ?? ""
    }

    if ((item as AdItem).title_ru)
    {
        if (lang == "ru") return (item as AdItem).title_ru ?? ""
        if (lang == "en") return (item as AdItem).title_eng ?? ""
        if (lang == "tur") return (item as AdItem).title_tur ?? ""
        if (lang == "greek") return (item as AdItem).title_greek ?? ""
        if (lang == "deutsch") return (item as AdItem).title_deutsch ?? ""
    }
}

export {GetByLanguage}