import React, {
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import Moment from "react-moment";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { Store } from "react-notifications-component";
import { css } from "@emotion/react";
import { User } from "../../models/User";
import ClipLoader from "react-spinners/ClipLoader";
import graphql from "../../gql/gqlqueries";

import Banner from "../../media/figure/store-banner.jpg";
import Store10 from "../../media/figure/store10.png";
import Avatar from "../../media/figure/avatar.jpg";

import ReduxActions from "../../redux/reducers/actions";
import { AppContext } from "../../App";
import { Store as AvguStore } from "../../models/Store";

import { GoodItem } from "./gooditem";
import Payments from "./payments";
import { AdItem } from "../../models/AdItem";
import { PaymentItem } from "../../models/PaymentItem";

import * as mapboxgl from "mapbox-gl";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
  margin-right: 10px;
`;

interface IAccountProps {
  OnLogin: (user: User) => void;
  OnLogout: () => void;
  SetUserStore: (store: AvguStore) => void;
}

interface PaginatorProps {}

const Paginator = (props: PaginatorProps) => {
  var context = useContext(AppContext);

  return (
    <div className="pagination-layout1">
      <div className="btn-prev disabled">
        <a href="javascript:void(0)">
          <i className="fas fa-angle-double-left"></i>
          {context.langdata["Previous"]}
        </a>
      </div>
      <div className="page-number">
        <a href="javascript:void(0)" className="active">
          1
        </a>
        <a href="javascript:void(0)">2</a>
      </div>
      <div className="btn-next">
        <a href="javascript:void(0)">
          {context.langdata["Next"]}
          <i className="fas fa-angle-double-right"></i>
        </a>
      </div>
    </div>
  );
};

const DataUploader = ({ visible }: { visible: boolean }) => {
  return visible ? (
    <div className="spinner-border text-danger" role="status">
      <span className="visually-hidden"></span>
    </div>
  ) : null;
};

var filename = "";

const Account = (props: IAccountProps) => {
  var context = useContext(AppContext);

  var state = context.dataStore.getState();
  const userProfile = useSelector((state: any) => state.user);
  const dispatcher = useDispatch();
  const [uploadData, SetUploadData] = useState(0);

  const [Login, { loading: l1, error: loginError, data: d1 }] = useLazyQuery(
    graphql.querues.LOGIN_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        localStorage.setItem("token", data.login.tocken);

        var user = new User(data.login.profile);
        context.dispatch(ReduxActions.users.setUser(user));
        props.OnLogin(user);
        //document.location = document.location
        setloginStart(false);
      },
      onError: (error) => {
        setloginStart(false);
        Store.addNotification({
          title: "Ошибки сервера!",
          message: error.message,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      },
    }
  );

  const [Register, { loading: l2, error: e2, data: s2 }] = useMutation(
    graphql.mutations.REGISTER_QUERY,
    {
      onCompleted: (data) => {
        setregStart(false);
        Store.addNotification({
          title: "Регистрация!",
          message: "Пользователь успешно зарегестирован.",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      },
      onError: (error) => {
        setregStart(false);
        Store.addNotification({
          title: "Ошибки сервера!",
          message: error.message,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      },
    }
  );

  const [SaveProfile, { loading: l3, error: e3, data: s3 }] = useMutation(
    graphql.mutations.USERDATA_MUT,
    {
      onCompleted: (data) => {
        Store.addNotification({
          title: "Обновление!",
          message: "Ваши персональные данные обновились.",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      },
      onError: (error) => {
        Store.addNotification({
          title: "Ошибки!",
          message: error.message,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      },
    }
  );

  const [SaveShop, { loading: l4, error: e4, data: s4 }] = useMutation(
    graphql.mutations.SHOPDATA_MUT,
    {
      onCompleted: (data) => {
        LoadUserShop({ variables: { userId: context.profile?.id.toString() } });
        Store.addNotification({
          title: "Обновление!",
          message: "Ваши персональные данные обновились.",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      },
      onError: (error) => {
        Store.addNotification({
          title: "Ошибки!",
          message: error.message,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      },
    }
  );

  const [LoadUserShop] = useLazyQuery(graphql.querues.SHOP_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      SetUploadData(0);
      props.SetUserStore(new AvguStore(data.shop));
    },
    onError: (error) => {
      SetUploadData(0);
      setloginStart(false);
      Store.addNotification({
        title: "Ошибки сервера!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    },
  });

  const [LoadUserAccountStats] = useLazyQuery(graphql.querues.USER_QUERY_STAT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      SetUploadData(0);
      props.OnLogin(new User(data.userbytoken));
    },
    onError: (error) => {
      SetUploadData(0);
    },
  });

  const [LoadUserAccount] = useLazyQuery(graphql.querues.USER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      SetUploadData(0);
      props.OnLogin(new User(data.userbytoken));
    },
    onError: (error) => {
      SetUploadData(0);
      setloginStart(false);
      Store.addNotification({
        title: "Ошибки сервера!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    },
  });

  const [LoadUserAds] = useLazyQuery(graphql.querues.ADS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      SetUploadData(0);
      setMyads(data.adslist.items);
    },
    onError: (error) => {
      SetUploadData(0);
    },
  });

  const [LoadUserFavorites] = useLazyQuery(
    graphql.querues.FAVORITES_ADS_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        SetUploadData(0);
        setFavourites(data.favorites);
      },
      onError: (error) => {
        SetUploadData(0);
      },
    }
  );

  const mapContainer = useRef(null);
  const map = useRef(null);

  var loginUsername = React.createRef<HTMLInputElement>();
  var loginPassword = React.createRef<HTMLInputElement>();
  var rememberMe = React.createRef<HTMLInputElement>();
  var regUsername = React.createRef<HTMLInputElement>();
  var regEmail = React.createRef<HTMLInputElement>();
  var regPhone = React.createRef<HTMLInputElement>();
  var regPassword = React.createRef<HTMLInputElement>();
  var regPassword2 = React.createRef<HTMLInputElement>();

  var AccountRef = React.createRef<HTMLInputElement>();
  var FirstNameRef = React.createRef<HTMLInputElement>();
  var LastNameRef = React.createRef<HTMLInputElement>();
  var EmailRef = React.createRef<HTMLInputElement>();
  var PhoneRef = React.createRef<HTMLInputElement>();
  var WhatsupRef = React.createRef<HTMLInputElement>();
  var TelegramRef = React.createRef<HTMLInputElement>();
  var WebsiteRef = React.createRef<HTMLInputElement>();
  var PostCodeRef = React.createRef<HTMLInputElement>();
  var AddressRef = React.createRef<HTMLTextAreaElement>();

  var ShopIdRef = React.createRef<HTMLInputElement>();
  var ShopWebsiteRef = React.createRef<HTMLInputElement>();
  var ShopNameRef = React.createRef<HTMLInputElement>();
  var ShopSloganRef = React.createRef<HTMLInputElement>();
  var ShopEmail1Ref = React.createRef<HTMLInputElement>();
  var ShopEmail2Ref = React.createRef<HTMLInputElement>();
  var ShopPhone1Ref = React.createRef<HTMLInputElement>();
  var ShopPhone2Ref = React.createRef<HTMLInputElement>();
  var ShopAddressRef = React.createRef<HTMLTextAreaElement>();
  var ShopDescriptionRef = React.createRef<HTMLTextAreaElement>();
  var WorkHouersRef = React.createRef<HTMLTextAreaElement>();
  var ShopFacebookRef = React.createRef<HTMLInputElement>();
  var ShopVkRef = React.createRef<HTMLInputElement>();
  var ShopTwitterRef = React.createRef<HTMLInputElement>();
  var ShopYoutubeRef = React.createRef<HTMLInputElement>();
  var ShopTelegramRef = React.createRef<HTMLInputElement>();
  var ShopWhatsupRef = React.createRef<HTMLInputElement>();
  var fileInput = React.createRef<HTMLInputElement>();
  var shopBannerRef = React.createRef<HTMLImageElement>();
  var shopLogoRef = React.createRef<HTMLImageElement>();

  var [loginStart, setloginStart] = useState(false);
  var [regStart, setregStart] = useState(false);
  var [scheduleMode, SetScheduleMode] = useState("alwaysopen");

  var [favourites, setFavourites] = useState<AdItem[]>();
  var [storegoods, setStoregoods] = useState<AdItem[]>();
  var [myads, setMyads] = useState<AdItem[]>();
  var [payments, setPayments] = useState<PaymentItem[]>();

  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  const TryLogin = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    var username = loginUsername.current?.value;
    var password = loginPassword.current?.value;
    var errors: Array<React.ReactChild> = [];

    loginUsername.current?.classList.remove("border-danger");
    loginPassword.current?.classList.remove("border-danger");

    if (username == "") {
      loginUsername.current?.classList.add("border-danger");
      errors.push(<p className="text-white">Имя пользователя не задано.</p>);
    }

    if (password == "") {
      loginPassword.current?.classList.add("border-danger");
      errors.push(<p className="text-white">Пароль не задан.</p>);
    }

    if (errors.length > 0) {
      Store.addNotification({
        title: "Ошибки заполнения формы авторизации!",
        message: errors,
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
      return;
    }

    if (rememberMe.current?.checked) {
      localStorage.setItem("loginUsername", username!);
      localStorage.setItem("loginUserpass", password!);
    }

    setloginStart(true);
    Login({ variables: { username: username, password: password } });
  };

  const TryRegister = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    var username = regUsername.current?.value;
    var email = regEmail.current?.value;
    var phone = regPhone.current?.value;
    var pass1 = regPassword.current?.value;
    var pass2 = regPassword2.current?.value;
    var errors: Array<React.ReactChild> = [];

    regUsername.current?.classList.remove("border-danger");
    regPhone.current?.classList.remove("border-danger");
    regEmail.current?.classList.remove("border-danger");
    regPassword.current?.classList.remove("border-danger");
    regPassword2.current?.classList.remove("border-danger");

    if (username == "") {
      regUsername.current?.classList.add("border-danger");
      errors.push(<p className="text-white">Имя пользователя не задано.</p>);
    }

    if (email == "") {
      regEmail.current?.classList.add("border-danger");
      errors.push(<p className="text-white">Почта пользователя не задана.</p>);
    }

    if (phone == "") {
      regPhone.current?.classList.add("border-danger");
      errors.push(<p className="text-white">Телефон пользователя не задан.</p>);
    }

    if (pass1 == "" || pass1 != pass2) {
      regPassword.current?.classList.add("border-danger");
      regPassword2.current?.classList.add("border-danger");
      errors.push(
        <p className="text-white">Пароль не задан или они не совпадают.</p>
      );
    }

    if (errors.length > 0) {
      Store.addNotification({
        title: "Ошибки заполнения формы регистрации!",
        message: errors,
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    }

    setregStart(true);
    Register({
      variables: {
        username: username,
        phone: phone,
        email: email,
        password: pass1,
      },
    });
  };

  const SaveUserProfile = (event: React.MouseEvent) => {
    event.preventDefault();

    var errors = 0;
    errors += ErrorTest(AccountRef);
    errors += ErrorTest(EmailRef);
    errors += ErrorTest(PhoneRef);

    if (errors > 0) {
      Store.addNotification({
        title: "Ошибки в профиле!",
        message: "Не все поля заполнены",
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });

      return;
    }

    SaveProfile({
      variables: {
        id: context?.profile?.id ?? "",
        account: AccountRef?.current?.value,
        phone: PhoneRef?.current?.value,
        email: EmailRef?.current?.value,
        address: AddressRef?.current?.value,
        postalcode: PostCodeRef?.current?.value,
        UserI: FirstNameRef?.current?.value,
        UserF: LastNameRef?.current?.value,
        whatsapp: WhatsupRef?.current?.value,
        telegram: TelegramRef?.current?.value,
        website: WebsiteRef?.current?.value,
      },
    });
  };

  const SaveUserShop = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    var errors = 0;
    errors += ErrorTest(ShopNameRef);
    errors += ErrorTest(ShopEmail1Ref);
    errors += ErrorTest(ShopPhone1Ref);

    if (errors > 0) {
      Store.addNotification({
        title: "Ошибки в данных магазина!",
        message: "Не все поля заполнены",
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });

      return;
    }

    SaveShop({
      variables: {
        id: context?.store?.id ?? "",
        userId: context?.profile?.id ?? "",
        name: ShopNameRef?.current?.value ?? "",
        email1: ShopEmail1Ref?.current?.value ?? "",
        phone1: ShopPhone1Ref?.current?.value ?? "",
        email2: ShopEmail2Ref?.current?.value ?? "",
        phone2: ShopPhone2Ref?.current?.value ?? "",
        slogan: ShopSloganRef?.current?.value ?? "",
        description: ShopDescriptionRef?.current?.value ?? "",
        address: ShopAddressRef?.current?.value ?? "",
        website: ShopWebsiteRef?.current?.value ?? "",
        facebook: ShopFacebookRef?.current?.value ?? "",
        vk: ShopVkRef?.current?.value ?? "",
        twitter: ShopTwitterRef?.current?.value ?? "",
        youtube: ShopYoutubeRef?.current?.value ?? "",
        telegram: ShopTelegramRef?.current?.value ?? "",
        whatsup: ShopWhatsupRef?.current?.value ?? "",
        workmode:
          scheduleMode == "schedule" ? WorkHouersRef?.current?.value ?? "" : "",
      },
    });
  };

  const Logout = () => {
    localStorage.removeItem("token");
    props.OnLogout();
    context.dispatch(ReduxActions.users.setUser(null));
    document.location = "/";
  };

  const OpenDashboard = () => {
    SetUploadData(1);
    LoadUserAccountStats({
      variables: {
        key: new Date(),
      },
    });
  };

  const OpenMyListings = () => {
    SetUploadData(2);
    LoadUserAds({
      variables: {
        startKey: new Date(),
        owner: context.profile?.id.toString(),
        page: 1,
        itemsCount: 25,
      },
    });
  };

  const OpenStore = () => {
    SetUploadData(3);
    LoadUserShop({
      variables: {
        startKey: new Date(),
        userId: context.profile?.id.toString(),
      },
    });
    UploadImages();
  };

  const OpenStoreGoods = () => {};

  const OpenFavourites = () => {
    SetUploadData(4);
    LoadUserFavorites({
      variables: {
        startKey: new Date(),
        userid: context.profile?.id.toString(),
        page: 1,
        itemsCount: 25,
      },
    });
  };

  const OpenPayments = () => {};

  const OpenAccount = () => {
    SetUploadData(5);
    LoadUserAccount({ variables: { key: new Date() } });
  };

  const OnPublishedChange = (id: string) => {
    SetUploadData(2);
    LoadUserAds({
      variables: {
        userid: context.profile?.id.toString(),
        page: 1,
        itemsCount: 25,
      },
    });
  };

  const OnRemoved = (id: string) => {
    LoadUserAds({
      variables: {
        userid: context.profile?.id.toString(),
        page: 1,
        itemsCount: 25,
      },
    });
  };

  const ErrorTest = (element: React.RefObject<HTMLInputElement>): number => {
    var ret =
      element == undefined ||
      element.current?.value == undefined ||
      element.current?.value.length == 0
        ? 1
        : 0;
    if (ret > 0) {
      element.current?.classList.add("error-input");
    } else {
      element.current?.classList.remove("error-input");
    }
    return ret;
  };

  const [UploadImage] = useMutation(graphql.mutations.UPLOAD_USER_FILE, {
    errorPolicy: "all",
    onCompleted: function (data) {
      if (filename == "shopbanner") {
        context.dispatch(
          ReduxActions.users.setUserBanner(data.uploadUserFile.path)
        );
      }

      if (filename == "shoplogo") {
        context.dispatch(
          ReduxActions.users.setUserLogo(data.uploadUserFile.path)
        );
      }

      filename = "";
    },
    onError: function (data) {},
  });

  const [RemoveImageFromServer] = useMutation(graphql.mutations.REMOVE_FILE, {
    onCompleted: function (data) {
      if (filename == "shopbanner") {
        context.dispatch(ReduxActions.users.removeUserBanner());
      }

      if (filename == "shoplogo") {
        context.dispatch(ReduxActions.users.removeUserLogo());
      }

      filename = "";
    },
    onError: function (data) {},
  });

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files && userProfile && filename != "") {
      if (filename == "shopbanner") {
        UploadImage({
          variables: {
            file: event.target.files[0],
            savefilename: filename,
            width: 1180,
            height: 300,
          },
        });
      }

      if (filename == "shoplogo") {
        UploadImage({
          variables: {
            file: event.target.files[0],
            savefilename: filename,
            width: 180,
            height: 140,
          },
        });
      }
    }
  };

  const UploadShopBanner = (event: React.MouseEvent) => {
    event.preventDefault();
    filename = "shopbanner";
    fileInput?.current?.click();
  };

  const DeleteShopBanner = (filefordelete: string) => {
    filename = "shopbanner";
    RemoveImageFromServer({ variables: { filename: filefordelete } });
  };

  const UploadShopLogo = (event: React.MouseEvent) => {
    event.preventDefault();
    filename = "shoplogo";
    fileInput?.current?.click();
  };

  const DeleteShopLogo = (filefordelete: string) => {
    filename = "shoplogo";
    RemoveImageFromServer({ variables: { filename: filefordelete } });
  };

  useEffect(() => {
    const el = document.createElement("div");
    el.className = "marker";
    var geopoint: any = null;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    if (map.current) {
      (map.current as any).on("click", (e: any) => {
        if (geopoint) {
          geopoint.setLngLat(e.lngLat);
          setLng(e.lngLat.lng);
          setLat(e.lngLat.lat);
        } else
          geopoint = new mapboxgl.Marker(el)
            .setLngLat(e.lngLat)
            .addTo(map.current);
      });
    }
  }, []);

  useEffect(() => {
    UploadImages();
  }, [userProfile]);

  useEffect(() => {
    if (loginUsername.current)
      loginUsername.current!.value = localStorage.getItem("loginUsername")
        ? localStorage.getItem("loginUsername")!
        : "";
    if (loginPassword.current)
      loginPassword.current!.value = localStorage.getItem("loginUserpass")
        ? localStorage.getItem("loginUserpass")!
        : "";

    if (AccountRef.current)
      AccountRef.current.value = context.profile?.Account() ?? "";
    if (EmailRef.current) EmailRef.current.value = context.profile?.email ?? "";
    if (PhoneRef.current) PhoneRef.current.value = context.profile?.phone ?? "";
    if (FirstNameRef.current)
      FirstNameRef.current.value = context.profile?.i ?? "";
    if (LastNameRef.current)
      LastNameRef.current.value = context.profile?.f ?? "";
    if (WhatsupRef.current)
      WhatsupRef.current.value = context.profile?.whatsapp ?? "";
    if (TelegramRef.current)
      TelegramRef.current.value = context.profile?.telegram ?? "";
    if (WebsiteRef.current)
      WebsiteRef.current.value = context.profile?.website ?? "";
    if (PostCodeRef.current)
      PostCodeRef.current.value = context.profile?.postalcode ?? "";
    if (AddressRef.current)
      AddressRef.current.value = context.profile?.address ?? "";

    if (ShopNameRef.current)
      ShopNameRef.current.value = context.store?.name.toString() ?? "";
    if (ShopEmail1Ref.current)
      ShopEmail1Ref.current.value = context.store?.phone1 ?? "";
    if (ShopPhone1Ref.current)
      ShopPhone1Ref.current.value = context.store?.email1 ?? "";
    if (ShopEmail2Ref.current)
      ShopEmail2Ref.current.value = context.store?.phone2 ?? "";
    if (ShopPhone2Ref.current)
      ShopPhone2Ref.current.value = context.store?.email2 ?? "";
    if (ShopSloganRef.current)
      ShopSloganRef.current.value = context.store?.slogan ?? "";
    if (ShopDescriptionRef.current)
      ShopDescriptionRef.current.value = context.store?.description ?? "";
    if (ShopFacebookRef.current)
      ShopFacebookRef.current.value = context.store?.facebook ?? "";
    if (ShopVkRef.current) ShopVkRef.current.value = context.store?.vk ?? "";
    if (ShopTwitterRef.current)
      ShopTwitterRef.current.value = context.store?.twitter ?? "";
    if (ShopYoutubeRef.current)
      ShopYoutubeRef.current.value = context.store?.youtube ?? "";
    if (ShopTelegramRef.current)
      ShopTelegramRef.current.value = context.store?.telegram ?? "";
    if (ShopWhatsupRef.current)
      ShopWhatsupRef.current.value = context.store?.whatsup ?? "";
    if (ShopAddressRef.current)
      ShopAddressRef.current.value = context.store?.address ?? "";
    if (ShopWebsiteRef.current)
      ShopWebsiteRef.current.value = context.store?.website ?? "";

    if (context.store?.houers != "") {
      SetScheduleMode("schedule");
      if (WorkHouersRef.current)
        WorkHouersRef.current.value = context.store?.houers ?? "";
    }
  }, [context.store, context.profile]);

  const UploadImages = () => {
    if (userProfile) {
      if (shopBannerRef.current && userProfile?.shopbanner) {
        shopBannerRef.current.src =
          userProfile?.shopbanner + "?" + new Date().getMilliseconds();
      }

      if (shopLogoRef.current && userProfile?.shoplog) {
        shopLogoRef.current.src =
          userProfile?.shoplogo + "?" + new Date().getMilliseconds();
      }
    }
  };

  return (
    <section className="section-padding-equal-70 bg-accent">
      <div className="container">
        <div className="row">
          {userProfile ? (
            <div className="col-lg-3 sidebar-break-sm sidebar-widget-area mt-0">
              <div className="widget-bottom-margin widget-account-menu widget-light-bg">
                <h3 className="widget-border-title">
                  {context.langdata["Personal cabinet"]}
                </h3>
                <ul className="nav nav-tabs flex-column" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link d-flex align-items-center justify-content-between active"
                      onClick={OpenDashboard}
                      id="tab1"
                      data-toggle="tab"
                      href="#dashboard"
                      role="tab"
                      aria-selected="true"
                    >
                      <span>{context.langdata["Dashboard"]}</span>
                      <DataUploader visible={uploadData === 1} />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link d-flex align-items-center justify-content-between"
                      onClick={OpenMyListings}
                      data-toggle="tab"
                      href="#my-listing"
                      role="tab"
                      aria-selected="false"
                    >
                      <span>{context.langdata["My Listings"]}</span>
                      <DataUploader visible={uploadData === 2} />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link d-flex align-items-center justify-content-between"
                      onClick={OpenStore}
                      data-toggle="tab"
                      href="#store"
                      role="tab"
                      aria-selected="false"
                    >
                      <span>{context.langdata["Store"]}</span>
                      <DataUploader visible={uploadData === 3} />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link d-flex align-items-center justify-content-between"
                      onClick={OpenFavourites}
                      data-toggle="tab"
                      href="#favourite"
                      role="tab"
                      aria-selected="false"
                    >
                      <span>{context.langdata["Favourites"]}</span>
                      <DataUploader visible={uploadData === 4} />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link d-flex align-items-center justify-content-between"
                      onClick={OpenAccount}
                      data-toggle="tab"
                      href="#accout-detail"
                      role="tab"
                      aria-selected="false"
                    >
                      <span>{context.langdata["Account details"]}</span>
                      <DataUploader visible={uploadData === 5} />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="javascript:void(0)"
                      onClick={Logout}
                      role="tab"
                      aria-selected="false"
                    >
                      {context.langdata["Logout"]}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={userProfile ? "col-lg-9" : "col-lg-12"}>
            <div className="tab-content">
              <div
                className={"tab-pane fade" + (userProfile ? "show active" : "")}
                id="dashboard"
                role="tabpanel"
              >
                <div className="myaccount-dashboard light-shadow-bg">
                  <div className="light-box-content">
                    <div className="media-box">
                      <div className="item-img">
                        <img src={Avatar} alt="avatar" />
                      </div>
                      <div className="item-content">
                        <h3 className="item-title">
                          {context.profile?.FullFIO()}
                        </h3>
                        <div className="item-email">
                          <span>Email: </span>
                          {context.profile?.email}
                        </div>
                      </div>
                    </div>
                    <div className="static-report">
                      <h3 className="report-title">
                        {context.langdata["Membership Report"]}
                      </h3>
                      <div className="report-list">
                        <div className="report-item">
                          <label>{context.langdata["Status"]}</label>
                          <div className="item-value">
                            {context.profile?.active
                              ? context.langdata["Active"]
                              : context.langdata["Blocked"]}
                          </div>
                        </div>
                      </div>
                      <div className="report-list">
                        <div className="report-item">
                          <label>{context.langdata["Created"]}</label>
                          <div className="item-value">
                            <Moment format="DD.MM.YYYY HH:mm">
                              {context.profile?.registerDateTime}
                            </Moment>
                          </div>
                        </div>
                      </div>
                      <div className="report-list">
                        <div className="report-item">
                          <label>{context.langdata["LastVisit"]}</label>
                          <div className="item-value">
                            <Moment format="DD.MM.YYYY HH:mm">
                              {context.profile?.lastVisitDateTime}
                            </Moment>
                          </div>
                        </div>
                      </div>
                      <div className="report-list">
                        <div className="report-item">
                          <label>{context.langdata["Ads created"]}</label>
                          <div className="item-value">
                            {context.profile?.adsCount}
                          </div>
                        </div>
                      </div>
                      <div className="report-list">
                        <div className="report-item">
                          <label>
                            {context.langdata["Finished transactions"]}
                          </label>
                          <div className="item-value">
                            {context.profile?.transCount}
                          </div>
                        </div>
                      </div>
                      <div className="report-list">
                        <div className="report-item">
                          <label>
                            {context.langdata["Items in the store"]}
                          </label>
                          <div className="item-value">
                            {context.profile?.storeItemsCount}
                          </div>
                        </div>
                      </div>
                      <div className="report-list">
                        <div className="report-item">
                          <label>{context.langdata["Goods sold"]}</label>
                          <div className="item-value">
                            {context.profile?.soldGoodsCount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="my-listing" role="tabpanel">
                <div className="myaccount-listing">
                  {myads == undefined || (myads && myads?.length == 0) ? (
                    <h3 className="text-center">
                      {context.langdata["No data"]}
                    </h3>
                  ) : (
                    <></>
                  )}
                  {myads?.map((item, index) => {
                    return (
                      <GoodItem
                        key={index}
                        good={item}
                        lang={context.lang}
                        OnPublishedChange={OnPublishedChange}
                        OnRemoved={OnRemoved}
                      ></GoodItem>
                    );
                  })}
                </div>
                {myads && myads?.length > 0 ? <Paginator></Paginator> : <></>}
              </div>
              <div className="tab-pane fade" id="store" role="tabpanel">
                <div className="light-shadow-bg post-ad-box-layout1 myaccount-store-settings">
                  <div className="light-box-content">
                    <form action="#">
                      <input
                        type="file"
                        ref={fileInput}
                        multiple={false}
                        style={{ display: "none" }}
                        onChange={handleFilesChange}
                      />
                      <div className="post-section store-banner">
                        <div className="post-ad-title">
                          <i className="far fa-image"></i>
                          <h3 className="item-title">
                            {context.langdata["Store Images"]}
                          </h3>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Store Banner"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <div className="store-banner-wrapper">
                                <div className="banenr-img">
                                  <img
                                    ref={shopBannerRef}
                                    src={userProfile?.shopbanner || Banner}
                                    alt=""
                                  />
                                  <div className="media-action">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={UploadShopBanner}
                                      className="media-add"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        DeleteShopBanner(
                                          userProfile?.shopbanner
                                        )
                                      }
                                      className="media-delete"
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </a>
                                  </div>
                                </div>
                                <div className="alert alert-danger">
                                  {
                                    context.langdata[
                                      "Recommended image size to"
                                    ]
                                  }{" "}
                                  (1180x300)px,{" "}
                                  {context.langdata["Maximum file size"]} 3 MB,{" "}
                                  {context.langdata["Allowed image type"]} (png,
                                  jpg, jpeg)
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Store Logo"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <div className="store-banner-wrapper">
                                <div className="banenr-img">
                                  <img
                                    ref={shopLogoRef}
                                    src={userProfile?.shoplogo || Store10}
                                    alt=""
                                  />
                                  <div className="media-action">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={UploadShopLogo}
                                      className="media-add"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        DeleteShopLogo(userProfile?.shoplogo)
                                      }
                                      className="media-delete"
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </a>
                                  </div>
                                </div>
                                <div className="alert alert-danger">
                                  {
                                    context.langdata[
                                      "Recommended image size to"
                                    ]
                                  }{" "}
                                  (180x140)px,{" "}
                                  {context.langdata["Maximum file size"]} 3 MB,{" "}
                                  {context.langdata["Allowed image type"]} (png,
                                  jpg, jpeg)
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="post-section store-schedule">
                        <div className="post-ad-title">
                          <i className="far fa-calendar"></i>
                          <h3 className="item-title">
                            {context.langdata["Store Schedule"]}
                          </h3>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Opening Hours"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <div className="form-check form-radio-btn">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="exampleRadios1"
                                  checked={scheduleMode == "alwaysopen"}
                                  onChange={(e) => {
                                    if (e.currentTarget.checked) {
                                      SetScheduleMode("alwaysopen");
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  {context.langdata["Always open"]}
                                </label>
                              </div>
                              <div className="form-check form-radio-btn">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="exampleRadios2"
                                  checked={scheduleMode == "schedule"}
                                  onChange={(e) => {
                                    if (e.currentTarget.checked) {
                                      SetScheduleMode("schedule");
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios2"
                                >
                                  {context.langdata["Select Opening Hours"]}
                                </label>
                              </div>
                            </div>
                            {scheduleMode === "schedule" ? (
                              <div className="form-group">
                                <textarea
                                  ref={WorkHouersRef}
                                  className="form-control textarea"
                                  id="workhouers"
                                  rows={7}
                                ></textarea>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="post-section store-information">
                        <div className="post-ad-title">
                          <i className="fas fa-folder-open"></i>
                          <h3 className="item-title">
                            {context.langdata["Store Information"]}
                          </h3>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">Id</label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                value={context.store?.id.toString()}
                                className="form-control"
                                name="id"
                                id="store-id"
                                readOnly={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Name"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={ShopNameRef}
                                className="form-control"
                                name="name"
                                id="store-name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Slogan"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={ShopSloganRef}
                                className="form-control"
                                name="slogan"
                                id="store-slogan"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">Email</label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="email"
                                ref={ShopEmail1Ref}
                                className="form-control"
                                name="email1"
                                id="store-email"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">Email</label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="email"
                                ref={ShopEmail2Ref}
                                className="form-control"
                                name="email2"
                                id="store-phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Phone"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={ShopPhone1Ref}
                                className="form-control"
                                name="phone1"
                                id="store-phone1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Phone"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={ShopPhone2Ref}
                                className="form-control"
                                name="phone2"
                                id="store-phone2"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Website"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={ShopWebsiteRef}
                                className="form-control"
                                name="website"
                                id="store-website"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Address"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <textarea
                                ref={ShopAddressRef}
                                className="form-control textarea"
                                id="address"
                                cols={30}
                                rows={2}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Description"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <textarea
                                ref={ShopDescriptionRef}
                                className="form-control textarea"
                                id="discription"
                                cols={30}
                                rows={6}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Socials"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group store-social">
                              <input
                                type="text"
                                ref={ShopFacebookRef}
                                className="form-control"
                                name="facebook"
                                id="store-facebook"
                                placeholder="Facebook"
                              />
                              <input
                                type="text"
                                ref={ShopVkRef}
                                className="form-control"
                                name="vk"
                                id="store-vk"
                                placeholder="Vk"
                              />
                              <input
                                type="text"
                                ref={ShopTwitterRef}
                                className="form-control"
                                name="twitter"
                                id="store-twitter"
                                placeholder="Twitter"
                              />
                              <input
                                type="text"
                                ref={ShopYoutubeRef}
                                className="form-control"
                                name="youtube"
                                id="store-youtube"
                                placeholder="Youtube"
                              />
                              <input
                                type="text"
                                ref={ShopTelegramRef}
                                className="form-control"
                                name="telegram"
                                id="store-telegram"
                                placeholder="Telegram"
                              />
                              <input
                                type="text"
                                ref={ShopWhatsupRef}
                                className="form-control"
                                name="whatsup"
                                id="store-whatsup"
                                placeholder="Whatsup"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3"></div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="button"
                                onClick={SaveUserShop}
                                className="submit-btn"
                                value={context.langdata["Update Store"]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="favourite" role="tabpanel">
                <div className="myaccount-listing">
                  {favourites == undefined ||
                  (favourites && favourites.length == 0) ? (
                    <h3 className="text-center">
                      {context.langdata["No data"]}
                    </h3>
                  ) : (
                    <></>
                  )}
                  {favourites?.map((item, index) => {
                    return (
                      <GoodItem
                        key={index}
                        good={item}
                        lang={context.lang}
                      ></GoodItem>
                    );
                  })}
                </div>
                {favourites && favourites?.length > 0 ? (
                  <Paginator></Paginator>
                ) : (
                  <></>
                )}
              </div>
              <div className="tab-pane fade" id="storegoods" role="tabpanel">
                <div className="myaccount-listing">
                  {storegoods == undefined ||
                  (storegoods && storegoods.length == 0) ? (
                    <h3 className="text-center">
                      {context.langdata["No data"]}
                    </h3>
                  ) : (
                    <></>
                  )}
                  {storegoods?.map((item, index) => {
                    return (
                      <GoodItem
                        key={index}
                        {...item}
                        OnPublishedChange={OnPublishedChange}
                        OnRemoved={OnRemoved}
                      ></GoodItem>
                    );
                  })}
                </div>
                {storegoods && storegoods?.length > 0 ? (
                  <Paginator></Paginator>
                ) : (
                  <></>
                )}
              </div>
              <div className="tab-pane fade" id="payment" role="tabpanel">
                {payments == undefined || (payments && payments.length == 0) ? (
                  <h3 className="text-center">{context.langdata["No data"]}</h3>
                ) : (
                  <div className="myaccount-payment light-shadow-bg">
                    <div className="light-box-content">
                      <Payments items={payments}></Payments>
                    </div>
                  </div>
                )}

                {payments && payments?.length > 0 ? (
                  <Paginator></Paginator>
                ) : (
                  <></>
                )}
              </div>
              <div className="tab-pane fade" id="accout-detail" role="tabpanel">
                <div className="light-shadow-bg post-ad-box-layout1 myaccount-store-settings myaccount-detail">
                  <div className="light-box-content">
                    <form action="#">
                      <div className="post-section basic-information">
                        <div className="post-ad-title">
                          <i className="fas fa-user"></i>
                          <h3 className="item-title">
                            {context.langdata["Basic Information"]}
                          </h3>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Account"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={AccountRef}
                                className="form-control"
                                name="account-name"
                                id="account-name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["First Name"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={FirstNameRef}
                                className="form-control"
                                name="first-name"
                                id="first-name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Last Name"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={LastNameRef}
                                className="form-control"
                                name="last-name"
                                id="last-name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              Email
                              <span>*</span>
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="email"
                                ref={EmailRef}
                                className="form-control"
                                name="email"
                                id="email"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Change Password"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group mb-5">
                              <div className="form-check form-check-box">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="password"
                                  value="password"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Phone"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={PhoneRef}
                                className="form-control"
                                name="phone1"
                                id="phone1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["WhatsApp Phone"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={WhatsupRef}
                                className="form-control"
                                name="phone2"
                                id="phone2"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">Telegram</label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={TelegramRef}
                                className="form-control"
                                name="telegram"
                                id="telegram"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Website"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={WebsiteRef}
                                className="form-control"
                                name="website"
                                id="website"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="post-section location-detail">
                        <div className="post-ad-title">
                          <i className="fas fa-map-marker-alt"></i>
                          <h3 className="item-title">
                            {context.langdata["Location"]}
                          </h3>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Country"]}
                              <span>*</span>
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <select className="form-control select-box">
                                <option value="0">Select Country</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["State"]}
                              <span>*</span>
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <select className="form-control select-box">
                                <option value="0">Select State/Region</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["City"]}
                              <span>*</span>
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <select className="form-control select-box">
                                <option value="0">Select City</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Zip Code"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="text"
                                ref={PostCodeRef}
                                className="form-control"
                                name="zipcode"
                                id="post-zip"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Address"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <textarea
                                name="address1"
                                ref={AddressRef}
                                className="form-control textarea"
                                id="address1"
                                cols={30}
                                rows={2}
                              >
                                Melbourne
                              </textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="control-label">
                              {context.langdata["Map"]}
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <div
                              ref={mapContainer}
                              className="map-container"
                              style={{
                                width: "100%",
                                marginBottom: 20,
                                height: "400px",
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3"></div>
                          <div className="col-sm-9">
                            <div className="form-group">
                              <input
                                type="button"
                                onClick={SaveUserProfile}
                                className="submit-btn"
                                value={context.langdata["Update Account"]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={
                  "tab-pane fade" + (userProfile == null ? "show active" : "")
                }
                id="logout"
                role="tabpanel"
              >
                <div className="myaccount-login-form light-shadow-bg">
                  <div className="light-box-content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-box login-form">
                          <h3 className="item-title">
                            {context.langdata["Login"]}
                          </h3>
                          <form action="#">
                            <div className="form-group">
                              <label>
                                {context.langdata["Username or E-mail"]}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="login-username"
                                id="login-username"
                                ref={loginUsername}
                              />
                            </div>
                            <div className="form-group">
                              <label>{context.langdata["Password"]}</label>
                              <input
                                type="text"
                                className="form-control"
                                name="login-password"
                                id="login-password"
                                ref={loginPassword}
                              />
                            </div>
                            <div className="form-group d-flex">
                              <button
                                className="submit-btn"
                                onClick={(e) => TryLogin(e)}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <ClipLoader
                                    color="white"
                                    loading={loginStart}
                                    css={override}
                                    size={20}
                                  />
                                  {context.langdata["Login"]}
                                </div>
                              </button>
                              <div className="form-check form-check-box">
                                <input
                                  ref={rememberMe}
                                  className="form-check-input"
                                  type="checkbox"
                                  id="check-password"
                                />
                                <label htmlFor="check-password">
                                  {context.langdata["Remember Me"]}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <a href="#" className="forgot-password">
                                {context.langdata["Forgot your password"]}?
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-box registration-form">
                          <h3 className="item-title">
                            {context.langdata["Register"]}
                          </h3>
                          <form action="#">
                            <div className="form-group">
                              <label>{context.langdata["Username"]} *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="registration-username"
                                id="registration-username"
                                ref={regUsername}
                              />
                              <div className="help-block">
                                {context.langdata["Username cannot be changed"]}
                                .
                              </div>
                            </div>
                            <div className="form-group">
                              <label>{context.langdata["Phone"]} *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="registration-phone"
                                id="registration-phone"
                                ref={regPhone}
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                {context.langdata["Email address"]} *
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="registration-email"
                                id="registration-email"
                                ref={regEmail}
                              />
                            </div>
                            <div className="form-group">
                              <label>{context.langdata["Password"]} *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="registration-password"
                                id="registration-password"
                                ref={regPassword}
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                {context.langdata["Repeat password"]} *
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="registration-password2"
                                id="registration-password2"
                                ref={regPassword2}
                              />
                            </div>
                            <div className="form-group">
                              <button
                                className="submit-btn"
                                onClick={(e) => TryRegister(e)}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <ClipLoader
                                    color="white"
                                    loading={regStart}
                                    css={override}
                                    size={20}
                                  />
                                  {context.langdata["Register"]}
                                </div>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
