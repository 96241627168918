import React, {useContext, useState, useEffect} from "react"
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { AppContext } from "../App"
import {NewItem} from "../models/NewItem"
import { Link } from "react-router-dom"
import { GetByLanguage } from "./langs/languages"
import Moment from 'react-moment';
import moment from 'moment';

import graphql from "../gql/gqlqueries"

const InfoBlock = ({label, value}:{label:string, value:number}) =>
{
    var context = useContext(AppContext)
    return <div className="d-flex justify-content-between align-items-center"><small className="heading-title">{context.langdata[label]}</small><b style={{fontSize:20}}>{value}</b></div>
}

const Info = ()=>
{
    var context = useContext(AppContext)
    var [stats, SetStats] = useState({usersCount:0, usersVerified:0, newusersCount: 0, shopsCount:0, adsCount:0, dealsCount:0})
    
    const [Stats] = useLazyQuery(graphql.querues.STATS_QUERY, {
        pollInterval: 5000,
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        onCompleted: (data) => {
            SetStats(data.stat)
        },
    });

    useEffect(()=>
    {
        Stats()
    }, []);

    return <section className="section-padding-top-heading bg-accent" style={{paddingTop:0, paddingBottom:10}}>
    <div className="container">
        <div className="flex-heading-layout1">
            <div className="heading-layout2">
            <Link to="/news" className="item-btn">
                <h2 className="heading-title">{context.langdata["Info"]}</h2>
            </Link> 
            </div>
        </div>

        <InfoBlock label="Total ads" value={stats.adsCount} />
        <InfoBlock label="Total users" value={stats.usersCount} />
        <InfoBlock label="Total shops" value={stats.shopsCount} />
        <InfoBlock label="Total deals" value={stats.dealsCount} />

        <div className="flex-heading-layout1 mt-4 mb-0">
            <div className="heading-layout2  mb-0">
            <Link to="/documents/adsrules" className="item-btn d-block mb-2">
                <h5 className="heading-title">{context.langdata["Rules for posting ads"]}</h5>
            </Link> 
            <Link to="/documents/advertising" className="item-btn d-block mb-2">
                <h5 className="heading-title">{context.langdata["Advertising"]}</h5>
            </Link>     
          </div>
        </div>
        
    </div>
</section>
}

export default Info
