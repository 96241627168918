import React, { useContext } from "react"
import { AppContext } from "../App"
import { Route, Link } from "react-router-dom"

interface BreadCroombsHeaderInterface
{
    title:string
}

const BreadCroombsHeader = (props:BreadCroombsHeaderInterface) => {

    var context = useContext(AppContext)

    return <section className="inner-page-banner" data-bg-image="media/banner/banner1.jpg">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="breadcrumbs-area">
                        <h1>{props.title}</h1>
                        <ul>
                            <li>
                                <Link to="/">{context.langdata["Main Page"]}</Link>
                            </li>
                            <li>{props.title}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default BreadCroombsHeader