import React, { useContext, useState, useEffect } from "react";
import { useSelector, shallowEqual, useStore } from "react-redux";
import { Routes, Route, Link, useParams, useLocation } from "react-router-dom";

import Banners from "../banners";
import { AdFavorites, User } from "../../models/User";
import {
  AdItem,
  Сurrency,
  PhotoAlbum,
  PhotoItem,
  adsList,
  GetCurrencyIcon,
  useAdsList,
} from "../../models/AdItem";
import { AppContext } from "../../App";
import AdCard, { IAdCardProps } from "../adcard/adcard";
import { NamedTupleMember } from "typescript";
import { CategoryItem } from "../../models/Category";
import { CityItem, CountryItem, RegionItem } from "../../models/Region";
import { GetByLanguage } from "../langs/languages";

import {
  ConvertAdCurrency,
  CurrencyToDollar,
  currencyFormat,
} from "../productsgrid";
import { clearInterval } from "timers";

interface IAllAds {
  type?: string;
}

interface IFilterCategory {
  id: string;
  catid: string;
  title: string;
  lang: string;
  OnSelect: (id: string) => void;
  subcats: CategoryItem[];
  icon: string[];
  itemsCount: number;
  inChildItemsCount: number;
}

const FilterCard = (props: IFilterCategory) => {
  var subcatsCount = 0;
  const ClickCategory = (id: string) => {
    props?.OnSelect(id);
  };

  return (
    <div className="card">
      <div className="card-header">
        <a
          onClick={(e) => ClickCategory(props.catid)}
          style={{
            fontWeight:
              localStorage.getItem("category") == props.catid ? "bold" : "",
          }}
          className={
            (props.subcats?.length ?? 0) > 0
              ? "parent-list collapsed"
              : "parent-list collapsed parent-list-empty"
          }
          role="button"
          data-toggle="collapse"
          href={"#" + props.id}
          aria-expanded="false"
        >
          {props.icon ? (
            <img
              src={`/media/category/${props.catid}/${props.icon[0]}`}
              alt=""
            />
          ) : (
            <></>
          )}
          {props.title}{" "}
          {props.subcats?.length ?? 0 > 0
            ? "(" + (props.itemsCount + props.inChildItemsCount) + ")"
            : ""}
        </a>
      </div>
      {props.subcats?.length ?? 0 > 0 ? (
        <div id={props.id} className="collapse" data-parent="#accordion2">
          <div className="card-body">
            <ul className="sub-list">
              {props.subcats?.map((item, itemIndex) => {
                return (
                  <li>
                    <a
                      style={{
                        fontWeight:
                          localStorage.getItem("category") == item.id
                            ? "bold"
                            : "",
                      }}
                      onClick={(e) => ClickCategory(item.id)}
                      href="javascript:void(0)"
                    >
                      {GetByLanguage(item, props.lang)} (
                      {item.itemsCount + item.inChildItemsCount})
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

interface IAllAdsFilterProps {
  minCost: number;
  maxCost: number;
  categories: [CategoryItem];
  countries: [CountryItem];
  regions: [RegionItem];
  cities: [CityItem];
  changeMinCost: (newCost: number) => void;
  changeMaxCost: (newCost: number) => void;
  changeCategory: (newCategory: string) => void;
  changeCountry: (newCountry: string) => void;
  submit: () => void;
  clear: () => void;
}

const AllAdsFilter = ({
  minCost,
  maxCost,
  changeMinCost,
  changeMaxCost,
  changeCategory,
  changeCountry,
  submit,
  clear,
  countries,
  regions,
  cities,
  categories,
}: IAllAdsFilterProps) => {
  var context = useContext(AppContext);

  const OnSelectCategory = (id: string) => {
    changeCategory(id);
  };

  const OnSelectCountry = (id: string) => {
    changeCountry(id);
  };

  return (
    <div className="widget-bottom-margin-md widget-accordian widget-filter">
      <h3 className="widget-bg-title">{context.langdata["Filter"]}</h3>
      <form action="#">
        <div className="accordion-box">
          <div className="card filter-category multi-accordion filter-item-list">
            <div className="card-header">
              <a
                className="parent-list"
                role="button"
                data-toggle="collapse"
                href="#collapseTwo"
                aria-expanded="true"
              >
                {context.langdata["Category"]}
              </a>
            </div>
            <div
              id="collapseTwo"
              className="collapse show"
              data-parent="#accordion"
            >
              <div className="card-body">
                <div className="multi-accordion-content" id="accordion2">
                  {categories
                    .filter((c) => c.parent == null)
                    .map((category: CategoryItem) => {
                      return (
                        <FilterCard
                          OnSelect={OnSelectCategory}
                          itemsCount={category.itemsCount}
                          inChildItemsCount={category.inChildItemsCount}
                          icon={category.icon}
                          title={GetByLanguage(category, context.lang) ?? ""}
                          catid={category.id}
                          id={"category" + category.id}
                          lang={context.lang}
                          subcats={categories.filter(
                            (c) => c.parent == category.id
                          )}
                        ></FilterCard>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="card filter-location multi-accordion filter-item-list">
            <div className="card-header">
              <a
                className="parent-list"
                role="button"
                data-toggle="collapse"
                href="#collapseThree"
                aria-expanded="true"
              >
                {context.langdata["Location"]}
              </a>
            </div>
            <div
              id="collapseThree"
              className="collapse show"
              data-parent="#accordion"
            >
              <div className="card-body">
                <div className="multi-accordion-content" id="accordion3">
                  {countries?.map((country: CountryItem, index) => {
                    var regionsList = regions?.filter(
                      (r) => r.Country.id == country.id
                    );

                    return (
                      <div className="card">
                        <div className="card-header">
                          <a
                            onClick={() => OnSelectCountry(country.id)}
                            style={{
                              fontWeight:
                                country.id == localStorage.getItem("country")
                                  ? "bold"
                                  : "",
                            }}
                            className={
                              (regionsList?.length ?? 0) > 0
                                ? "parent-list collapsed"
                                : "parent-list collapsed parent-list-empty"
                            }
                            role="button"
                            data-toggle="collapse"
                            href={"#location" + index}
                            aria-expanded="false"
                          >
                            {GetByLanguage(country, context.lang)}{" "}
                            {(regionsList?.length ?? 0) > 0
                              ? "(" + (regionsList?.length ?? 0) + ")"
                              : ""}
                          </a>
                        </div>
                        <div
                          id={"location" + index}
                          className="collapse"
                          data-parent="#accordion3"
                        >
                          <div className="card-body">
                            <ul className="sub-list">
                              {regionsList?.map((region: RegionItem, index) => {
                                return (
                                  <li>
                                    <a
                                      onClick={() => OnSelectCountry(region.id)}
                                      href="javascript:void(0);"
                                    >
                                      {GetByLanguage(region, context.lang)}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="card filter-price-range filter-item-list">
            <div className="card-header">
              <a
                className="parent-list"
                role="button"
                data-toggle="collapse"
                href="#collapseFour"
                aria-expanded="true"
              >
                {context.langdata["Price Range"]}
              </a>
            </div>
            <div
              id="collapseFour"
              className="collapse show"
              data-parent="#accordion"
            >
              <div className="card-body">
                <div className="price-range-content">
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <input
                        type="number"
                        name="filter-price-min"
                        className="form-control px-1"
                        value={minCost}
                        onChange={(e) =>
                          changeMinCost(parseFloat(e.target.value))
                        }
                        placeholder="min"
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <input
                        type="number"
                        name="filter-price-max"
                        className="form-control px-1"
                        value={maxCost}
                        onChange={(e) =>
                          changeMaxCost(parseFloat(e.target.value))
                        }
                        placeholder="max"
                      />
                    </div>
                    <div className="col-12 form-group">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          submit();
                        }}
                        className="filter-btn"
                      >
                        {context.langdata["Apply Filters"]}
                      </button>
                    </div>
                    <div className="col-12 form-group">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          clear();
                        }}
                        className="filter-btn"
                      >
                        {context.langdata["Clear Filters"]}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

interface IAllAdsHeaderFilterProps {
  OnToggleView: (view: string) => void;
  totalItems: number;
  startItenmNumber: number;
  itemsPerPage: number;
  sorting: string;
  SetSortBy: (sortBy: string) => void;
}

const AllAdsHeaderFilter = (props: IAllAdsHeaderFilterProps) => {
  var context = useContext(AppContext);
  var [viewMode, SetViewMode] = useState("grid");

  return (
    <div className="product-filter-heading">
      <div className="row align-items-center">
        <div className="col-md-6">
          <h2 className="item-title">
            {context.langdata["Showing"]} {props.startItenmNumber}–
            {Math.min(
              props.startItenmNumber + props.itemsPerPage - 1,
              props.totalItems
            )}{" "}
            {context.langdata["of"]} {props.totalItems}{" "}
            {context.langdata["results"]}
          </h2>
        </div>
        <div className="col-md-6 d-flex justify-content-md-end justify-content-center">
          <div className="product-sorting">
            <div className="ordering-controller">
              <button
                className="ordering-btn dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {props.sorting == "" ? context.langdata["Sort By"] : <></>}
                {props.sorting == "A->Z" ? (
                  context.langdata["A to Z (title)"]
                ) : (
                  <></>
                )}
                {props.sorting == "Z->A" ? (
                  context.langdata["Z to A (title)"]
                ) : (
                  <></>
                )}
                {props.sorting == "CreateDt" ? (
                  context.langdata["Data Added (oldest)"]
                ) : (
                  <></>
                )}
                {props.sorting == "ViewsAsk" ? (
                  context.langdata["Most Viewed"]
                ) : (
                  <></>
                )}
                {props.sorting == "ViewsDesk" ? (
                  context.langdata["Less Viewed"]
                ) : (
                  <></>
                )}
                {props.sorting == "PriceAsk" ? (
                  context.langdata["Price (low to high)"]
                ) : (
                  <></>
                )}
                {props.sorting == "PriceDesk" ? (
                  context.langdata["Price (high to low)"]
                ) : (
                  <></>
                )}
              </button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  href="javascript:void(0);"
                  onClick={() => props.SetSortBy("A->Z")}
                >
                  {context.langdata["A to Z (title)"]}
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0);"
                  onClick={() => props.SetSortBy("Z->A")}
                >
                  {context.langdata["Z to A (title)"]}
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0);"
                  onClick={() => props.SetSortBy("CreateDt")}
                >
                  {context.langdata["Data Added (oldest)"]}
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0);"
                  onClick={() => props.SetSortBy("ViewsAsk")}
                >
                  {context.langdata["Most Viewed"]}
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0);"
                  onClick={() => props.SetSortBy("ViewsDesk")}
                >
                  {context.langdata["Less Viewed"]}
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0);"
                  onClick={() => props.SetSortBy("PriceAsk")}
                >
                  {context.langdata["Price (low to high)"]}
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0);"
                  onClick={() => props.SetSortBy("PriceDesk")}
                >
                  {context.langdata["Price (high to low)"]}
                </a>
              </div>
            </div>
            <div className="layout-switcher">
              <ul>
                <li className={viewMode == "list" ? "active" : ""}>
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      SetViewMode("list");
                      props.OnToggleView("list");
                    }}
                    className="product-view-trigger"
                    data-type="product-box-list"
                  >
                    <i className="fas fa-th-list"></i>
                  </a>
                </li>
                <li className={viewMode == "grid" ? "active" : ""}>
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      SetViewMode("grid");
                      props.OnToggleView("grid");
                    }}
                    className="product-view-trigger"
                    data-type="product-box-grid"
                  >
                    <i className="fas fa-th-large"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IAllAdsPaginator {
  totalItems: number;
  itemsPerPage: number;
  changePageNumber: (newPage: number) => void;
  activePage: number;
}

const AllAdsPaginator = (props: IAllAdsPaginator) => {
  var context = useContext(AppContext);
  var pagesCount = props.totalItems / props.itemsPerPage;

  function ShowPages() {
    var pages: Array<React.ReactChild> = [];
    for (let i = 1; i <= pagesCount; i++) {
      pages.push(
        <a
          href="javascrtipt:void(0)"
          onClick={() => props.changePageNumber(i)}
          className={i == props.activePage ? "active" : ""}
        >
          {i}
        </a>
      );
    }

    return pages;
  }

  return (
    <div className="pagination-layout1">
      <div className="btn-prev disabled">
        <a href="#">
          <i className="fas fa-angle-double-left"></i>
          {context.langdata["Previous"]}
        </a>
      </div>
      <div className="page-number">{ShowPages()}</div>
      <div className="btn-next">
        <a href="#">
          {context.langdata["Next"]}
          <i className="fas fa-angle-double-right"></i>
        </a>
      </div>
    </div>
  );
};

var unsubscribe = null;
var lastCategories: any = null;
var lastCountries: any = null;
var lastRegions: any = null;
var lastCities: any = null;
var searchtimer: any = null;

const AllAds = (props: IAllAds) => {
  var context = useContext(AppContext);

  var browserlocation = useLocation();
  var { category } = useParams();

  var [view, SetView] = useState("grid");
  var [pageNumber, SetPageNumber] = useState(1);
  var [loader, SetLoader] = useState(false);
  const [AdsFilter, SetAdsFilter] = useState("All ads");
  const [adsList, setadsList] = useState<AdItem[]>([]);
  const [totalads, setTotalAds] = useState(0);
  const [preparedadsList, setPreparedadsList] = useState<AdItem[]>([]);
  var currency = useSelector((state: any) => state.currency);
  var userfavorites = useSelector((state: any) => state.userfavorites);
  var categories = useSelector((state: any) => state.categories);
  var countries = useSelector((state: any) => state.regions.Countries);
  var regions = useSelector((state: any) => state.regions.Regions);
  var cities = useSelector((state: any) => state.regions.Cities);

  var state = context.dataStore?.getState();

  var [minCost, setMinCost] = useState(
    parseFloat(localStorage.getItem("minCost") ?? "0")
  );
  var [maxCost, setMaxCost] = useState(
    parseFloat(localStorage.getItem("maxCost") ?? "0")
  );
  var [sorting, setSorting] = useState(
    localStorage.getItem("sorting") ?? "A->Z"
  );
  var [selectedcategory, setCategory] = useState(
    category || localStorage.getItem("category")
  );
  var [country, setCountry] = useState(localStorage.getItem("country") ?? "");
  var [region, setRegion] = useState(localStorage.getItem("region") ?? "");
  var [city, setCity] = useState(localStorage.getItem("city") ?? "");
  var [search, setSearch] = useState("");

  var adsListLoader = useAdsList(
    (data) => {
      setTotalAds(data?.adslist?.total ?? 0);
      setadsList(data?.adslist?.items ?? []);
    },
    (error) => {
      console.log(`Не удалось загрузить объявления! [${error}].`);
    }
  );

  const SaveMinCost = (newCost: number) => {
    localStorage.setItem("minCost", newCost.toString());
    setMinCost(newCost);
  };

  const SaveMaxCost = (newCost: number) => {
    localStorage.setItem("maxCost", newCost.toString());
    setMaxCost(newCost);
  };

  const SaveCategory = (newCategory: string) => {
    localStorage.setItem("category", newCategory);
    setCategory(newCategory);
  };

  const SaveCountry = (newCountry: string) => {
    localStorage.setItem("country", newCountry);
    setCountry(newCountry);
  };

  const SetSorting = (sortBy: string) => {
    localStorage.setItem("sorting", sortBy);
    setSorting(sortBy);
  };

  const SubmitFilter = () => {
    SetLoader(true);
    adsListLoader({
      variables: {
        startKey: new Date().toISOString(),
        itemsCount: 27,
        adstype: AdsFilter,
        actived: true,
        minCost: minCost,
        maxCost: maxCost,
        category: selectedcategory,
        country: country,
        region: region,
        city: city,
        sort: sorting,
        search: search,
      },
    });
  };

  const ClearFilter = () => {
    localStorage.removeItem("country");
    localStorage.removeItem("category");
    localStorage.setItem("minCost", "0");
    localStorage.setItem("maxCost", "0");
    setCountry("");
    setCategory("");
    setMinCost(0);
    setMaxCost(0);
  };

  const SearchTextChange = (text: string) => {
    clearTimeout(searchtimer);
    searchtimer = setTimeout(() => {
      setSearch(text);
    }, 500);
  };

  useEffect(() => {
    if (
      props?.type &&
      props?.type != "" &&
      categories &&
      categories.length > 0
    ) {
      var transport = categories.filter(
        (c) => c.parent == null && c.title_ru == "Транспорт"
      );
      var realty = categories.filter(
        (c) => c.parent == null && c.title_ru == "Недвижимость"
      );
      var work = categories.filter(
        (c) => c.parent == null && c.title_ru == "Работа"
      );
      var services = categories.filter(
        (c) => c.parent == null && c.title_ru == "Услуги"
      );

      if (props.type == "cars" && transport.length > 0)
        SaveCategory(transport[0].id);
      if (props.type == "realty" && realty.length > 0)
        SaveCategory(realty[0].id);
      if (props.type == "work" && work.length > 0) SaveCategory(work[0].id);
      if (props.type == "services" && services.length > 0)
        SaveCategory(services[0].id);
    }

    if (category && category != "" && categories && categories.length > 0) {
      SaveCategory(category);
    }
  }, [props?.type, categories, category]);

  useEffect(() => {
    if (selectedcategory) {
      SetLoader(true);
      adsListLoader({
        variables: {
          startKey: new Date().toISOString(),
          itemsCount: 27,
          adstype: AdsFilter,
          actived: true,
          minCost: minCost,
          maxCost: maxCost,
          category: selectedcategory,
          country: country,
          region: region,
          city: city,
          sort: sorting,
          search: search,
        },
      });
    }
    //unsubscribe = context.dataStore?.subscribe(StoreUpdate);
  }, [selectedcategory, search, sorting]);

  useEffect(() => {
    //Dollar, Euro, Ruble, Pound, Lira
    adsList.forEach((ad: AdItem) => {
      var newad = ConvertAdCurrency(ad, context.currency, currency);
      ad.recalculatedcost = newad.recalculatedcost;
    });

    setPreparedadsList([...adsList]);
  }, [currency, context.currency, adsList]);

  return (
    <>
      <Banners onlySearch OnSearchChange={SearchTextChange}></Banners>
      <section className="product-inner-wrap-layout1 bg-accent">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-3 col-lg-4 sidebar-break-md sidebar-widget-area"
              id="accordion"
            >
              <AllAdsFilter
                categories={categories}
                countries={countries}
                regions={regions}
                cities={cities}
                minCost={minCost}
                submit={SubmitFilter}
                clear={ClearFilter}
                maxCost={maxCost}
                changeCountry={SaveCountry}
                changeCategory={SaveCategory}
                changeMaxCost={SaveMaxCost}
                changeMinCost={SaveMinCost}
              ></AllAdsFilter>
            </div>
            <div className="col-xl-9 col-lg-8">
              <AllAdsHeaderFilter
                SetSortBy={SetSorting}
                sorting={sorting}
                totalItems={totalads}
                itemsPerPage={10}
                startItenmNumber={totalads > 0 ? 1 : 0}
                OnToggleView={(view) => {
                  SetView(view);
                }}
              ></AllAdsHeaderFilter>
              <div
                id="product-view"
                className={
                  view == "list" ? "product-box-list" : "product-box-grid"
                }
              >
                <div className="row">
                  {preparedadsList.map((item: AdItem, index: number) => {
                    if ((userfavorites as Array<AdFavorites>)?.length > 0) {
                      var infav =
                        (userfavorites as Array<AdFavorites>)?.find(
                          (f) => f.ad.id == item.id
                        ) != undefined;
                      return (
                        <AdCard
                          key={index}
                          view={view}
                          new={false}
                          item={item}
                          infavorites={infav}
                        ></AdCard>
                      );
                    } else {
                      return (
                        <AdCard
                          key={index}
                          view={view}
                          new={false}
                          item={item}
                          infavorites={false}
                        ></AdCard>
                      );
                    }
                  })}
                </div>
              </div>
              <AllAdsPaginator
                changePageNumber={(pageNumber) => SetPageNumber(pageNumber)}
                activePage={pageNumber}
                totalItems={adsList.length}
                itemsPerPage={10}
              ></AllAdsPaginator>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllAds;
export { AllAdsFilter, AllAdsHeaderFilter };
