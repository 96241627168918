import React, { useContext, useState, useEffect } from "react";
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import graphql from "../gql/gqlqueries";

import { AdItem } from "../models/AdItem";
import { ReviewItem } from "../models/ReviewItem";

const useReviews = (adid: string | undefined, itemsCount = 5, fromDate = new Date()): [ReviewItem[], any] => {

    var [items, setItems] = useState<ReviewItem[]>([]);
    
    var [loadReviews] = useLazyQuery(graphql.querues.REVIEWS_QUERY, 
        { variables:{adid:adid, itemsCount: itemsCount, from: fromDate}, 
        onCompleted: (data) => {
            setItems(data.ad)
    }})

    return [items, loadReviews]
}

export default useReviews;