import { ReducerAction } from "./reducers"

const UsersCommands = {
    SET_USER: 'SET_USER',
    GET_USER: 'GET_USER',
    REMOVE_USER_BANNER: 'REMOVE_USER_BANNER',
    REMOVE_USER_LOGO: 'REMOVE_USER_LOGO',
    REMOVE_USER_AVATAR: 'REMOVE_USER_AVATAR',
    SET_USER_BANNER: 'SET_USER_BANNER',
    SET_USER_LOGO: 'SET_USER_LOGO',
    SET_USER_AVATAR: 'SET_USER_AVATAR',
    SET_USER_FAVOURITES: 'SET_USER_FAVOURITES',
}

export const user = (state:any = null, action:ReducerAction) => {
    switch (action.type) {
        case UsersCommands.REMOVE_USER_BANNER:
            var newuser = {...state}
            newuser.shopbanner = ""
            return newuser
        case UsersCommands.REMOVE_USER_LOGO:
            var newuser = {...state}
            newuser.shoplogo = ""
            return newuser            
        case UsersCommands.REMOVE_USER_AVATAR:
            var newuser = {...state}
            newuser.avatar = ""
            return newuser                            
        case UsersCommands.SET_USER_BANNER:
            var newuser = {...state}
            newuser.shopbanner = action.params
            return newuser
        case UsersCommands.SET_USER_LOGO:
            var newuser = {...state}
            newuser.shoplogo = action.params
            return newuser            
        case UsersCommands.SET_USER_AVATAR:
            var newuser = {...state}
            newuser.avatar = action.params
            return newuser                                        
        case UsersCommands.SET_USER:
            return action.params
        default:
            return state
    }
}


export {UsersCommands}