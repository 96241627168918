/* eslint import/no-webpack-loader-syntax: off */

import React, { useContext, useEffect, useState, useRef, useReducer } from "react";
import {
    Link,
} from "react-router-dom"

import InputMask from 'react-input-mask';

//import mapboxgl from '!mapbox-gl'; 
import * as mapboxgl from 'mapbox-gl';

import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { Store } from 'react-notifications-component';
import { css } from "@emotion/react";
import {CategoryItem,useCategory} from "../../models/Category"
import { GetByLanguage } from "../langs/languages"
import ClipLoader from "react-spinners/ClipLoader";
import graphql from "../../gql/gqlqueries"

import {AppContext} from '../../App'
import { User } from "../../models/User";
import { Marka, Model, Generation } from "../../models/CarMarka";
import { useCarMarka, useCarModel, useCarGeneration } from "../../models/CarMarka";
import { AdItem, EmptyAdItem } from "../../models/AdItem";
import { useSelector } from 'react-redux'

import {FileItem} from '../../models/FileItem'
import { CityItem, CountryItem, RegionItem } from "../../models/Region";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
  margin-right:10px;
`;

var rootid = "", subcat1id = "", subcat2id = "", subcat3id = "", subcat4id = "", subcat5id = "", selectedcatId = "", lastId = ""
var autoad = true, propertyad = false
var lastUser:any = null

mapboxgl.accessToken = 'pk.eyJ1IjoiaXZrb21lIiwiYSI6ImNsOGN5Mm96NTBuanczdnE5dzAxNXV3eWUifQ.y7jzCxJf2lsg5hFTaKH_VQ';

interface IAdEditorProps
{
    id:string;
}

var SaveAsDraft = false

const DisplayError = (props:any) =>
{
    return (props.show ?<div className="alert alert-danger" role="alert">{props.title}</div> : <></>)
}

const NewAdForm = (props:IAdEditorProps) =>
{
    var context = useContext(AppContext)

    var state = context.dataStore.getState()
    const carEdits = useSelector((state: any) => state.cars?.Edits)
    const userProfile = useSelector((state: any) => state.user)
    const cars = useSelector((state: any) => state.cars)
    const countries = useSelector((state: any) => state.regions.Countries)
    const regions = useSelector((state: any) => state.regions.Regions)
    const cities = useSelector((state: any) => state.regions.Cities)
    
    const mapContainer = useRef(null);
    const map = useRef(null);
    var postalHRef = React.createRef<HTMLInputElement>()
    var addressHRef = React.createRef<HTMLTextAreaElement>()
    var phoneHRef = React.createRef<HTMLInputElement>()
    var whatsappHRef = React.createRef<HTMLInputElement>()
    var emailHRef = React.createRef<HTMLInputElement>()
    var fileInput = React.createRef<HTMLInputElement>()
    
    var [selectedCountry, setSelectedCountry] = useState("0")
    var [selectedRegion, setSelectedRegion] = useState("0")
    var [featureLang, setFeatureLang] = useState(context.lang)
    var [descriptionLang, setDescriptionLang] = useState(context.lang)
    var [titleLang, setTitleLang] = useState(context.lang)
    var [tempfiles, SetTempFiles] = useState<String[]>([])
    const [lng, setLng] = useState(-70.9);
    const [lat, setLat] = useState(42.35);
    const [zoom, setZoom] = useState(9);
    
    
    var [marks, setMarks] = useState<Marka[]>(cars.Marks)
    var [models, setModels] = useState<Model[]>(cars.Models)
    var [generations, setGenerations] = useState<Generation[]>(cars.Generations)

    var [newad, SetNewAd] = useReducer((newad, newDetails)=>({...newad, ...newDetails}), {currency:"", country:"0", region:"0", city:"0", authenticity:"ORIGINAL"})// useState<AdItem>()

    var RootCats = useCategory((data)=>{setRootcas(data.categories)})
    var SubCats1 = useCategory((data)=>{setSubcat1(data.categories)})
    var SubCats2 = useCategory((data)=>{setSubcat2(data.categories)})
    var SubCats3 = useCategory((data)=>{setSubcat3(data.categories)})
    var SubCats4 = useCategory((data)=>{setSubcat4(data.categories)})

    var CarMarks = useCarMarka((data)=>{setMarks(data.marks)})
    var CarModels = useCarModel((data)=>{setModels(data.models)})
    var CarGenerations = useCarGeneration((data)=>{setGenerations(data.carcategories)})

    const [SaveAd] = useMutation(graphql.mutations.AD_CREATE,
        {
            onCompleted:function (data)
            {
                Store.addNotification({
                    title: context.langdata["NewAd"],
                    message: context.langdata["NewAdMessage"],
                    type: "success",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  });
            }
        });    

    const [GetAd] = useLazyQuery(graphql.querues.AD_QUERY,
    {
        onCompleted:function (data)
        {
            SetNewAd(data.ad)
        }
    });            

    const [UploadTempFiles] = useMutation(graphql.mutations.UPLOAD_TEMP_FILES,
        {
            errorPolicy: 'all',
            onCompleted:function (data)
            {
                LoadTempFiles({variables:{}})
            },
            onError:function (data)
            {

            }
        }) 

    const [RemoveTempFileFromServer] = useMutation(graphql.mutations.REMOVE_FILE,
    {
        onCompleted:function (data)
        {
            var newfiles = tempfiles.filter((item)=>{return item != data.removeFile})
            SetTempFiles(newfiles)
        }
    });

    const [LoadTempFiles] = useLazyQuery(graphql.querues.TEMP_FILES,
            {
                onCompleted:function (data)
                {
                    SetTempFiles(data.tempFiles)
                },
                onError:function (data)
                {
    
                }
            }) 
    
    var SubCats5 = useCategory((data)=>
    {
        setSubcat5(data.categories)
        //autoad = subcat5.find((item)=>{item.id == lastId}).autocar
        //propertyad = subcat5.find((item)=>{item.id == lastId}).property
    })

    var [step, setStep] = useState(1)
    var [rootcats, setRootcas] = useState<CategoryItem[]>([])
    var [subcat1, setSubcat1]  = useState<CategoryItem[]>([])
    var [subcat2, setSubcat2]  = useState<CategoryItem[]>([])
    var [subcat3, setSubcat3]  = useState<CategoryItem[]>([])
    var [subcat4, setSubcat4]  = useState<CategoryItem[]>([])
    var [subcat5, setSubcat5]  = useState<CategoryItem[]>([])
    var [selcat, setSelcat]  = useState(selectedcatId)

    var [queryStatus, setqueryStatus] = useState(false)
    
    var brands = ["LG", "Samsung", "HP"]
    
    const SelectCategory = async (event: React.ChangeEvent<HTMLSelectElement>, level:number) =>
    {
        event.preventDefault()
        if (level<100)
        {
            lastId = event.target.value
            setSelcat(lastId)
            if (level==1) 
            {
                rootid = lastId
                setSubcat2([])
                setSubcat3([])
                setSubcat4([])
                setSubcat5([])
                autoad = rootcats.find((item)=>item.id == lastId)?.autocar ?? false
                propertyad = rootcats.find((item)=>item.id == lastId)?.property ?? false
                
                SetNewAd({category: rootid})
                SubCats1({variables:{parentId:lastId}})
            }
            if (level==2) 
            {
                subcat1id = lastId
                setSubcat3([])
                setSubcat4([])
                setSubcat5([])
                autoad = subcat1.find((item)=>item.id == lastId)?.autocar ?? false
                propertyad = subcat1.find((item)=>item.id == lastId)?.property ?? false
                
                SetNewAd({category: subcat1id})
                SubCats2({variables:{parentId:lastId}})
            }
            if (level==3) 
            {
                subcat2id = lastId
                setSubcat4([])
                setSubcat5([])
                autoad = subcat2.find((item)=>item.id == lastId)?.autocar ?? false
                propertyad = subcat2.find((item)=>item.id == lastId)?.property ?? false

                SetNewAd({category: subcat2id})
                SubCats3({variables:{parentId:lastId}})
            }
            if (level==4) 
            {
                subcat3id = lastId
                setSubcat5([])
                autoad = subcat3.find((item)=>item.id == lastId)?.autocar ?? false
                propertyad = subcat3.find((item)=>item.id == lastId)?.property ?? false

                SetNewAd({category: subcat3id})
                SubCats4({variables:{parentId:lastId}})
            }
            if (level==5) 
            {
                subcat4id = lastId
                autoad = subcat4.find((item)=>item.id == lastId)?.autocar ?? false
                propertyad = subcat4.find((item)=>item.id == lastId)?.property ?? false

                SetNewAd({category: subcat4id})
                SubCats5({variables:{parentId:lastId}})
            }
        }
    }

    const SelectCarMarka = () =>
    {
        CarMarks()
        setModels([])
        setGenerations([])
    }

    const SelectCarModel = (marka:string) =>
    {
        CarModels({variables:{markaId: marka}})
        setGenerations([])
    }

    const SelectCarGeneration = (marka:(string | undefined), model: string) =>
    {
        if (marka && model)
        {
            CarGenerations({variables:{markaId: marka, modelId: model}})
        }
    }

    const SelectFiles = () =>
    {
        fileInput?.current?.click()
    }

    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        event.preventDefault();
        event.stopPropagation();

        if (event.target.files)
        {
            var user = context.dataStore.getState().user
            console.log(event.target.files)
            UploadTempFiles({variables:{id: user?.id, files: event.target.files}})
        }
    }

    const TrySendAdToServer = (e:any) =>{
        e.preventDefault()

        var user = context.dataStore.getState().user;
        if (user)
        {
            var adddata = {id: newad?.id, userId: user.id, category: newad?.category, 
                country: newad?.country, region: newad?.region, city: newad?.city, postalcode: newad?.postalcode, 
                contactaddress: newad?.contactaddress_ru, 
                phones: newad?.phones, whatsapp: newad?.whatsapp, email: newad?.email, 
                location: newad?.location, locationLong: newad?.locationLong, locationAlt: newad?.locationAlt, dontshowmap: newad?.dontshowmap, 
                currency: newad?.currency ?? "DOLLAR",
                title_ru: newad?.title_ru, title_eng: newad?.title_eng, title_tur: newad?.title_tur, title_greek: newad?.title_greek, title_deutsch: newad?.title_deutsch, 
                costtype: newad?.costtype, cost: newad?.cost, state: newad?.state, brand: newad?.brand, authenticity: newad?.authenticity,
                description_ru: newad?.description_ru, description_eng: newad?.description_eng, description_tur: newad?.description_tur, description_greek: newad?.description_greek, 
                description_deutsch: newad?.description_deutsch, 
                features_ru: newad?.features_ru, features_eng: newad?.features_eng, features_tur: newad?.features_tur, features_greek: newad?.features_greek, features_deutsch: newad?.features_deutsch, 
                address: newad?.address_ru }

            var propdata = {proptype: newad?.proptype, propstate: newad?.propstate, level: newad?.level, roomsCount: newad?.roomsCount, balcony: newad?.balcony, 
                loggia: newad?.loggia, wardrobe: newad?.wardrobe, panoramicwindows: newad?.panoramicwindows, totalarea: newad?.totalarea, livingspace: newad?.livingspace, ceilingheight: newad?.сeilingheight, 
                bathroom: newad?.bathroom, windowstothecourtyard: newad?.windowstothecourtyard, windowstothestreet: newad?.windowstothestreet, windowsonthesunnyside: newad?.windowsonthesunnyside, kitchen: newad?.kitchen,
                clothesstorage: newad?.clothesstorage, sleepingplaces: newad?.sleepingplaces, airconditioner: newad?.airconditioner, fridge: newad?.fridge, washingmachine: newad?.washingmachine, dishwasher: newad?.dishwasher, waterheater: newad?.waterheater,
                repair: newad?.repair, homeType: newad?.homeType, concierge: newad?.concierge, garbagechute: newad?.garbagechute, gas: newad?.gas, makeYear: newad?.makeYear, levelsInHome: newad?.levelsInHome, passengerelevator: newad?.passengerelevator, servicelift: newad?.servicelift,
                yardclosedarea: newad?.yardclosedarea, yardplayground: newad?.yardplayground, yardsportsground: newad?.yardsportsground, parkingunderground: newad?.parkingunderground, parkinggroundmultilevel: newad?.parkinggroundmultilevel, parkingopenintheyard: newad?.parkingopenintheyard,
                parkingbehindthegateintheyard: newad?.parkingbehindthegateintheyard}

            var cardata = {caradtype: newad?.caradtype, color: newad?.color, video: newad?.video, mileage: newad?.mileage, carstate: newad?.carstate, vin: newad?.vin, 
                carnumber: newad?.carnumber, marka: newad?.marka, model: newad?.model, generation: newad?.generation,
                yearofissue: newad?.yearofissue, cusov: newad?.cusov, engine: newad?.engine, driveunit: newad?.driveunit, transmission: newad?.transmission, rudderposition: newad?.rudderposition}
            
            SaveAd({variables:{addata: adddata, propdata:propdata, cardata:cardata, draft: SaveAsDraft}})
        }        
    }

    const SaveDraft = (e:any) => {
        e.preventDefault()
        SaveAsDraft = true
        TrySendAdToServer(e)
        SaveAsDraft = false
    }
    
    const RemoveTempFile = (file:string) =>
    {
        RemoveTempFileFromServer({variables:{filename: file}})
    }

    useEffect(()=>
    {
        if (postalHRef?.current) postalHRef.current.value = context?.profile?.postalcode ?? ""
        if (addressHRef?.current) addressHRef.current.value = context?.profile?.address ?? ""
        if (phoneHRef?.current) phoneHRef.current.value = context?.profile?.phone ?? ""
        if (whatsappHRef?.current) whatsappHRef.current.value = context?.profile?.whatsapp ?? ""
        if (emailHRef?.current) emailHRef.current.value = context?.profile?.email ?? ""
    })

    useEffect(()=>
    {
        if (step!=7) return

        const el = document.createElement('div');
        el.className = 'marker';
        var geopoint:any = null

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom
        });

        if (map.current)
        {
            (map.current as any).on("click", (e:any)=>{
                if (geopoint)
                {
                    geopoint.setLngLat(e.lngLat)
                    setLng(e.lngLat.lng)
                    setLat(e.lngLat.lat)
                } else geopoint = new mapboxgl.Marker(el).setLngLat(e.lngLat).addTo(map.current);
            })
        }

    }, [step])

    useEffect(()=>
    {
        if (step == 2)
        {
            SelectCarMarka()
        }

        if (step == 6)
        {
            LoadTempFiles({variables:{}})
        }
    }, [step])

    useEffect(()=>
    {
        RootCats({variables:{parentId:"0"}})
    }, [])

    useEffect(()=>
    {
        SetNewAd({postalcode: userProfile?.postalcode ?? "", contactaddress_ru: userProfile?.address ?? "", phones: userProfile?.phone ?? "", whatsapp: userProfile?.whatsapp ?? "", email: userProfile?.email ?? ""}) 
    }, [userProfile])

    useEffect(()=>
    {
        if (props.id == "")
        {
            SetNewAd(EmptyAdItem)
        } 

        if (props.id != "")
        {
            GetAd({variables:{id: props.id}})            
        }

        setStep(1)

    }, [props.id])

    return  <section className="section-padding-equal-70 bg-accent">
        <div className="container">
            <div className="post-ad-box-layout1 light-shadow-bg">
                <div className="post-ad-form light-box-content">
                    
                    {userProfile==null ? 
                    <>
                    <div className="d-flex flex-column align-items-center justify-content-center">

                    <h3>{context.langdata["cantcreateads"]}</h3> 
                    <div className="header-action-layout1">
                            <ul>
                                <li className="header-btn">
                                    <Link to="/account" className="item-btn">{context.langdata["RegisterOrLogin"]}</Link> 
                                </li>
                            </ul>
                        </div>
                    </div>
                    </>
                    : 
                    
                    (userProfile!=null && userProfile.active == false) ? 
                    <>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h3>{context.langdata["cantcreateads_blocked"]}</h3> 
                    </div>
                    </> :
                    <form action="#">
                        {step == 1 ? (
                        <div className="post-section post-category">
                            <div className="post-ad-title">
                                <i className="fas fa-tags"></i>
                                <h3 className="item-title">{context.langdata["Select Category"]}</h3>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata["Category"]}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>SelectCategory(e,1)}>
                                            <option key={0} value="0">{context.langdata["Select a category"]}</option>
                                            {
                                                rootcats.map((item, index)=>
                                                {
                                                    return rootid==item.id ?  <option  key={index} selected value={item.id}>{GetByLanguage(item, context.lang)}</option> : <option key={index} value={item.id}>{GetByLanguage(item, context.lang)}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {subcat1?.length>0 ? (<div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata["Sub Category"]}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>SelectCategory(e,2)}>
                                            <option key={0} value="0">{context.langdata["Select a subcategory"]}</option>
                                            {
                                                subcat1.map((item, index)=>
                                                {
                                                    return subcat1id==item.id ? <option key={index} selected value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option> : <option key={index} value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            ):(<></>)}

                            {subcat2?.length>0 ? (<div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata["Sub Category"]}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>SelectCategory(e,3)}>
                                            <option key={0} value="0">{context.langdata["Select a subcategory"]}</option>
                                            {
                                                subcat2.map((item, index)=>
                                                {
                                                    return subcat2id==item.id ? <option key={index} selected value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option> : <option key={index} value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            ):(<></>)} 

                            {subcat3?.length>0 ? (<div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata["Sub Category"]}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>SelectCategory(e,4)}>
                                            <option key={0} value="0">{context.langdata["Select a subcategory"]}</option>
                                            {
                                                subcat3.map((item, index)=>
                                                {
                                                    return subcat3id==item.id ? <option key={index} selected value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option> : <option key={index} value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            ):(<></>)} 

                            {subcat4?.length>0 ? (<div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata["Sub Category"]}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>SelectCategory(e,5)}>
                                            <option key={0} value="0">{context.langdata["Select a subcategory"]}</option>
                                            {
                                                subcat4.map((item, index)=>
                                                {
                                                    return subcat4id==item.id ? <option key={index} selected value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option> : <option key={index} value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            ):(<></>)} 

                            {subcat5?.length>0 ? (<div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata["Sub Category"]}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>SelectCategory(e,100)}>
                                            <option key={0} value="0">{context.langdata["Select a subcategory"]}</option>
                                            {
                                                subcat5.map((item, index)=>
                                                {
                                                    return subcat5id==item.id ? <option key={index} selected value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option> : <option key={index} value={item.id}>{context.lang == "en" ? item.title_eng : item.title_ru}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            ):(<></>)} 

                            <div className="form-box w-100">
                            <div className="form-group d-flex align-items-end">
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(autoad || propertyad ? 2 : 3)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <ClipLoader color="white" loading={queryStatus} css={override} size={20} />
                                        {context.langdata["Next step"]}
                                    </div>
                                </button>
                            </div>
                            </div>
                        </div>) : (<></>)}

                        {step == 2 && autoad ? (
                        <div className="post-section car-information">
                            <div className="post-ad-title">
                                <i className="fas fa-folder-open"></i>
                                <h3 className="item-title">{context.langdata["Auto"]} </h3>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Ad Type']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <DisplayError title={context.langdata["Car Ad type not set"]} show={!newad.caradtype || newad.caradtype == "" || newad.caradtype == undefined}></DisplayError>
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({caradtype: e.target.value}) }}>
                                            <option selected={newad?.caradtype == undefined} value="">{context.langdata["notset"]}</option>
                                            <option selected={newad?.caradtype == "PERSONAL"} value="PERSONAL">{context.langdata['Personal car']}</option>
                                            <option selected={newad?.caradtype == "FORSALE"} value="FORSALE">{context.langdata['Car bought for sale']}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Color']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <DisplayError title={context.langdata["Color not selected"]} show={!newad.color || newad.color == undefined || newad.color == "0"}></DisplayError>
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({color: e.target.value}) }}>
                                           <option selected={newad?.caradtype == undefined} value="0">{context.langdata["notset"]}</option>
                                            {cars.Edits?.CarColor?.map((item:any, index:number)=>
                                            {
                                               return <option selected={newad?.color == item.id} value={item.id}>{context.lang == "ru" ? item.colorName_ru : item.colorName_eng}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Video']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                      <input type="text" className="form-control" name="video" id="post-title" value={newad?.video ?? ""} onChange={(e)=>{ SetNewAd({video: e.target.value}) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Mileage']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <DisplayError title={context.langdata["Mileage not set"]} show={!newad.mileage || newad.mileage == undefined || newad.mileage == 0}></DisplayError>
                                    <div className="form-group">
                                        <input type="number" className="form-control" name="title" id="post-title" value={newad?.mileage ?? 0}  onChange={(e)=>{ SetNewAd({mileage: parseFloat(e.target.value)}) }} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Сar condition']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                <div className="form-group">
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="carstate1" checked={newad?.carstate == "BROKEN"} name="carstate" value="BROKEN" onChange={(e)=>{ SetNewAd({carstate: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="carstate1">
                                            {context.langdata['Not broken']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="carstate2" checked={newad?.carstate == "NOTBROKEN"} name="carstate" value="NOTBROKEN" onChange={(e)=>{ SetNewAd({carstate: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="carstate2">
                                            {context.langdata['Broken']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['VIN or body number']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <DisplayError title={context.langdata["VIN or body number not set"]} show={!newad.vin || newad.vin == undefined || newad.vin.length != 17}></DisplayError>
                                    <div className="form-group">
                                        <InputMask mask="*****************" maskChar="" value={newad?.vin ?? ""} onChange={(e)=>{ SetNewAd({vin: e.target.value})}}>
                                            {(inputProps) => <input type="text" {...inputProps} className="form-control"   /> }
                                        </InputMask>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Government number']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="price" id="post-price" value={newad?.carnumber ?? ""} onChange={(e)=>{ SetNewAd({carnumber: e.target.value}) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Marka']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>{ 
                                                SetNewAd({marka: e.target.value, model:"", generation:""})
                                                SelectCarModel(e.target.value)
                                            }}>
                                            <option selected value="0">{context.langdata["notset"]}</option>
                                            {marks?.map((item, index)=>
                                            {
                                               return <option value={item.id}>{context.lang == "ru" ? item.Marka_ru : item.Marka_eng}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Model']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>{ 
                                                    SetNewAd({model: e.target.value, generation:""}) 
                                                    SelectCarGeneration(newad?.marka, e.target.value)
                                                }}>
                                            <option value="0">{context.langdata["notset"]}</option>
                                            {models?.filter((e)=>e.markaId == newad?.marka).map((item, index)=>
                                            {
                                                return <option value={item.id}>{context.lang == "ru" ? item.Model_ru : item.Model_eng}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Generation']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({...newad, generation: e.target.value} as AdItem) }}>
                                        <option value="0">{context.langdata["notset"]}</option>
                                            {generations?.filter((e)=>e.modelId == newad?.model).map((item, index)=>
                                            {
                                                return <option value={item.id}>{context.lang == "ru" ? item.Generation_ru : item.Generation_eng}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Year of issue']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <input type="number" className="form-control" name="title" id="post-title" value={newad?.makeYear ?? 0}  onChange={(e)=>{ SetNewAd({makeYear: parseInt(e.target.value)}) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Body']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({cusov: e.target.value}) }}>
                                            <option value="0" selected={newad?.cusov == undefined}>{context.langdata["notset"]}</option>
                                            {carEdits?.CarBody?.map((item:any, index:number)=>
                                            {
                                                return <option selected={newad?.cusov == item.id} value={item.id}>{context.lang == "ru" ? item.bodytype_ru : item.bodytype_eng}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                           

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata["Engine's type"]}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box"  onChange={(e)=>{ SetNewAd({engine: e.target.value}) }}>
                                            <option value="0" selected={newad?.engine == undefined}>{context.langdata["notset"]}</option>
                                            {cars.Edits?.CarEngine?.map((item:any, index:number)=>
                                            {
                                                return <option selected={newad?.engine == item.id} value={item.id}>{context.lang == "ru" ? item.engine_ru : item.engine_eng}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata["CarDrive"]}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box"  onChange={(e)=>{ SetNewAd({driveunit: e.target.value}) }}>
                                            <option value="0" selected={newad?.driveunit == undefined}>{context.langdata["notset"]}</option>
                                            {cars.Edits?.CarDrive?.map((item:any, index:number)=>
                                            {
                                                return <option value={item.id} selected={newad?.driveunit == item.id}>{context.lang == "ru" ? item.name_ru : item.name_eng}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata["Transmission"]}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box"  onChange={(e)=>{ SetNewAd({transmission: e.target.value}) }}>
                                            <option value="0" selected={newad?.transmission == undefined}>{context.langdata["notset"]}</option>
                                            {cars.Edits?.CarTransmission?.map((item:any, index:number)=>
                                            {
                                                return <option value={item.id} selected={newad?.transmission == item.id}>{context.lang == "ru" ? item.transmission_ru : item.transmission_eng}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata["Rudder position"]}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box"  onChange={(e)=>{ SetNewAd({rudderposition: e.target.value}) }}>
                                            <option value="0" selected={newad?.rudderposition == "0"}>{context.langdata["notset"]}</option>
                                            <option value="1" selected={newad?.rudderposition == "1"}>{context.langdata["wheelLeft"]}</option>
                                            <option value="2" selected={newad?.rudderposition == "2"}>{context.langdata["wheelRight"]}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="form-box w-100">
                            <div className="form-group d-flex align-items-end">
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(1)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Prev step"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={SaveDraft}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Save and exit"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(3)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Next step"]}
                                    </div>
                                </button>
                            </div>
                            </div>
                        </div>) : <></>}

                        {step == 2 && propertyad ?
                        (<div className="post-section property-information">
                            <div className="post-ad-title">
                                <i className="fas fa-folder-open"></i>
                                <h3 className="item-title">{context.langdata["Property"]}</h3>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Address']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="title" id="property-address" value={newad?.address_ru ?? ""}  onChange={(e)=>{ SetNewAd({address: e.target.value}) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Places an ad']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                    <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="Owner" name="Placesanad" value="OWNER" checked={newad?.adowner == "OWNER"} onChange={(e)=>{ SetNewAd({adowner: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="Owner">
                                            {context.langdata['Owner']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="Mediator" name="Placesanad" value="POSREDNIK" checked={newad?.adowner == "POSREDNIK"} onChange={(e)=>{ SetNewAd({adowner: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="Mediator">
                                            {context.langdata['Mediator']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="Developer" name="Placesanad" value="MAKER" checked={newad?.adowner == "MAKER"} onChange={(e)=>{ SetNewAd({adowner: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="Developer">
                                            {context.langdata['Developer']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Housing type']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="Flat" name="Housingtype" value="FLAT" checked={newad?.proptype == "FLAT"} onChange={(e)=>{ SetNewAd({proptype: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="Flat">
                                            {context.langdata['Flat']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="Apartments" name="Housingtype" value="APARTMENT" checked={newad?.proptype == "APARTMENT"} onChange={(e)=>{ SetNewAd({proptype: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="Apartments">
                                            {context.langdata['Apartments']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="House" name="Housingtype" value="HOME" checked={newad?.proptype == "HOME"} onChange={(e)=>{ SetNewAd({proptype: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="House">
                                            {context.langdata['House']}
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Property condition']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="PropertyconditionNew" name="Propertycondition" value="NEW" checked={newad?.propstate == "NEW"} onChange={(e)=>{ SetNewAd({propstate: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="PropertyconditionNew">
                                            {context.langdata['New']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="PropertyconditionUsed" name="Propertycondition" value="INUSE" checked={newad?.propstate == "INUSE"} onChange={(e)=>{ SetNewAd({propstate: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="PropertyconditionUsed">
                                            {context.langdata['Used']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Level']}
                                    </label>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                    <select className="form-control select-box"  onChange={(e)=>{ SetNewAd({level: parseInt(e.target.value)}) }}>
                                            {[...Array(100)].map((_, i)=>
                                            {
                                                return <option selected={(newad?.level == i+1)} value={i+1}>{i+1}</option>    
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Rooms']}
                                    </label>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({roomsCount: parseInt(e.target.value)}) }}>
                                            <option selected={newad?.roomsCount == 0} value="0">{context.langdata['Studio']}</option>    
                                            <option selected={newad?.roomsCount == 1} value="1">1</option>    
                                            <option selected={newad?.roomsCount == 2} value="2">2</option>    
                                            <option selected={newad?.roomsCount == 3} value="3">3</option>    
                                            <option selected={newad?.roomsCount == 4} value="4">4</option>    
                                            <option selected={newad?.roomsCount == 5} value="5">5</option>    
                                            <option selected={newad?.roomsCount == 6} value="6">6</option>    
                                            <option selected={newad?.roomsCount == 7} value="7">7</option>    
                                            <option selected={newad?.roomsCount == 8} value="8">8</option>    
                                            <option selected={newad?.roomsCount == 9} value="9">9</option>    
                                            <option selected={newad?.roomsCount == 10} value="10">{context.langdata["10 rooms and more"]}</option>    
                                            <option selected={newad?.roomsCount == 11} value="11">{context.langdata["Free layout"]}</option>    
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Balcony or loggia']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="balcon" name="balcon" checked={newad?.balcony ?? false}  onChange={(e)=>{ SetNewAd({balcony: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="balcon">
                                            {context.langdata['Balcony']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="logiya" name="logiya" checked={newad?.loggia ?? false} onChange={(e)=>{ SetNewAd({loggia: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="logiya">
                                            {context.langdata['Loggia']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="garderob" name="garderob" checked={newad?.wardrobe ?? false} onChange={(e)=>{ SetNewAd({wardrobe: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="garderob">
                                            {context.langdata['Wardrobe']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="checkbox" id="panormawindow" name="panormawindow"  checked={newad?.panoramicwindows ?? false} onChange={(e)=>{ SetNewAd({panoramicwindows: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="panormawindow">
                                            {context.langdata['Panoramic windows']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Square']}
                                    </label>
                                </div>
                                <div className="col-sm-2">
                                    <label className="control-label">
                                    {context.langdata['Total space']}
                                    </label>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <input type="text" placeholder="0.0 м2" className="form-control" name="totalSpace" id="totalSpace" value={newad?.totalarea ?? 0}  onChange={(e)=>{ SetNewAd({totalarea: parseFloat(e.target.value)}) }} />
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <label className="control-label">
                                    {context.langdata['Living space']}
                                    </label>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <input type="text" placeholder="0.0 м2" className="form-control" name="livingSpace" id="livingSpace" value={newad?.livingspace ?? 0} onChange={(e)=>{ SetNewAd({livingspace: parseFloat(e.target.value)}) }} />
                                    </div>
                                </div>
                            </div>
                
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Ceiling height']}
                                    </label>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <input type="text" placeholder="0.0 м2" className="form-control" name="Ceilingheight" id="Ceilingheight" value={newad?.сeilingheight ?? 0} onChange={(e)=>{ SetNewAd({сeilingheight: parseFloat(e.target.value)}) }} />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Bathroom']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Combined" name="Bathroom" value="COMBINED" checked={newad?.bathroom == "COMBINED"} onChange={(e)=>{ SetNewAd({bathroom: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="Combined">
                                            {context.langdata['Combined']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Separated" name="Bathroom" value="SEPARATED" checked={newad?.bathroom == "SEPARATED"} onChange={(e)=>{ SetNewAd({bathroom: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="Separated">
                                            {context.langdata['Separated']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Windows']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="yard" name="WindowToYard" checked={newad?.windowstothecourtyard ?? false} onChange={(e)=>{ SetNewAd({windowstothecourtyard: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="yard">
                                            {context.langdata['To the yard']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="street" name="WindowToStreet" checked={newad?.windowstothestreet ?? false} onChange={(e)=>{ SetNewAd({windowstothestreet: e.target.checked}) }}  />
                                            <label className="form-check-label" htmlFor="street">
                                            {context.langdata['To the street']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="sunnyside" name="WindowsToSunny" checked={newad?.windowsonthesunnyside ?? false} onChange={(e)=>{ SetNewAd({windowsonthesunnyside: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="sunnyside">
                                            {context.langdata['To the sunny side']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Furniture']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Furniture1" name="Kitchen" checked={newad?.kitchen ?? false} onChange={(e)=>{ SetNewAd({kitchen: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Furniture1">
                                            {context.langdata['Kitchen']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Furniture2" name="Clothesstorage" checked={newad?.clothesstorage ?? false} onChange={(e)=>{ SetNewAd({clothesstorage: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Furniture2">
                                            {context.langdata['Clothes storage']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Furniture3" name="Sleepingplaces" checked={newad?.sleepingplaces ?? false}  onChange={(e)=>{ SetNewAd({sleepingplaces: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Furniture3">
                                            {context.langdata['Sleeping places']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Technics']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Technics1" name="Airconditioning" checked={newad?.airconditioner ?? false} onChange={(e)=>{ SetNewAd({airconditioner: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Technics1">
                                            {context.langdata['Air conditioning']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Technics2" name="Fridge" checked={newad?.fridge ?? false} onChange={(e)=>{ SetNewAd({fridge: e.target.checked}) }}  />
                                            <label className="form-check-label" htmlFor="Technics2">
                                            {context.langdata['Fridge']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Technics3" name="Washingmachine" checked={newad?.washingmachine ?? false} onChange={(e)=>{ SetNewAd({washingmachine: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Technics3">
                                            {context.langdata['Washing machine']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Technics4" name="Dishwasher" checked={newad?.dishwasher ?? false} onChange={(e)=>{ SetNewAd({dishwasher: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Technics4">
                                            {context.langdata['Dishwasher']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Technics5" name="Waterheater" checked={newad?.waterheater ?? false} onChange={(e)=>{ SetNewAd({waterheater: e.target.checked}) }}  />
                                            <label className="form-check-label" htmlFor="Technics5">
                                            {context.langdata['Water heater']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Repair']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="RepairNeed" name="Repair" value="REQUIRED" checked={newad?.repair == "REQUIRED"} onChange={(e)=>{ SetNewAd({repair: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="RepairNeed">
                                            {context.langdata['Need']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="RepairCosmetic" name="Repair" value="COSMETIC" checked={newad?.repair == "COSMETIC"} onChange={(e)=>{ SetNewAd({repair: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="RepairCosmetic">
                                            {context.langdata['Cosmetic']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="RepairEuro" name="Repair" value="EURO" checked={newad?.repair == "EURO"} onChange={(e)=>{ SetNewAd({repair: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="RepairEuro">
                                            {context.langdata['Euro']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="RepairDesigner" name="Repair" value="DESIGNER" checked={newad?.repair == "DESIGNER"} onChange={(e)=>{ SetNewAd({repair: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="RepairDesigner">
                                            {context.langdata['Designer']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="post-ad-title">
                                <i className="fa fa-home" aria-hidden="true"></i>
                                <h3 className="item-title">{context.langdata["About home"]}</h3>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['House type']}
                                    </label>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({homeType: e.target.value}) }}>
                                            <option selected={newad?.homeType == "BRICK"} value="BRICK">{context.langdata['Brick']}</option>    
                                            <option selected={newad?.homeType == "PANEL"} value="PANEL">{context.langdata['Panel']}</option>    
                                            <option selected={newad?.homeType == "BLOCK"} value="BLOCK">{context.langdata['Blocky']}</option>    
                                            <option selected={newad?.homeType == "MONOLITHIC"} value="MONOLITHIC">{context.langdata['Monolithic']}</option>    
                                            <option selected={newad?.homeType == "MONOLITHIC-BRICK"} value="MONOLITHIC-BRICK">{context.langdata['Monolithic - brick']}</option>    
                                            <option selected={newad?.homeType == "WOOD"} value="WOOD">{context.langdata['Wooden']}</option>    
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Concierge" name="Concierge" checked={newad?.concierge ?? false}  onChange={(e)=>{ SetNewAd({concierge: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Concierge">
                                            {context.langdata['Concierge']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Garbagechute" name="Garbagechute" checked={newad?.garbagechute ?? false}  onChange={(e)=>{ SetNewAd({garbagechute: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Garbagechute">
                                            {context.langdata['Garbage chute']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Gas" name="Gas" checked={newad?.gas ?? false} onChange={(e)=>{ SetNewAd({gas: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Gas">
                                            {context.langdata['Gas']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Year of construction']}
                                    </label>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <input type="text" placeholder="" className="form-control" name="Yearofconstruction" value={newad?.makeYear ?? ""} onChange={(e)=>{ SetNewAd({makeYear: parseInt(e.target.value)}) }} />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Floors in the house']}
                                    </label>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <input type="text" placeholder="" className="form-control" name="Floorsinthehouse" value={newad?.levelsInHome ?? ""} onChange={(e)=>{ SetNewAd({levelsInHome: parseInt(e.target.value)}) }} />
                                    </div>
                                </div>
                            </div>
                            

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Passenger lift']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Passengerlift1" name="Passengerlift" value="0" checked={newad?.passengerelevator == 0} onChange={(e)=>{ SetNewAd({passengerelevator: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Passengerlift1">
                                            {context.langdata['None']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Passengerlift2" name="Passengerlift" value="1" checked={newad?.passengerelevator == 1} onChange={(e)=>{ SetNewAd({passengerelevator: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Passengerlift2">
                                            1
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Passengerlift3" name="Passengerlift" value="2" checked={newad?.passengerelevator == 2} onChange={(e)=>{ SetNewAd({passengerelevator: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Passengerlift3">
                                            2
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Passengerlift4" name="Passengerlift" value="3" checked={newad?.passengerelevator == 3} onChange={(e)=>{ SetNewAd({passengerelevator: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Passengerlift4">
                                            3
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Passengerlift5" name="Passengerlift" value="4" checked={newad?.passengerelevator == 4} onChange={(e)=>{ SetNewAd({passengerelevator: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Passengerlift5">
                                            4
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Cargo lift']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Cargolift1" name="Cargolift" value="0" checked={newad?.servicelift == 0} onChange={(e)=>{ SetNewAd({servicelift: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Cargolift1">
                                            {context.langdata['None']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Cargolift2" name="Cargolift" value="1" checked={newad?.servicelift == 1} onChange={(e)=>{ SetNewAd({servicelift: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Cargolift2">
                                            1
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Cargolift3" name="Cargolift" value="2" checked={newad?.servicelift == 2} onChange={(e)=>{ SetNewAd({servicelift: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Cargolift3">
                                            2
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Cargolift4" name="Cargolift" value="3" checked={newad?.servicelift == 3} onChange={(e)=>{ SetNewAd({servicelift: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Cargolift4">
                                            3
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="radio" id="Cargolift5" name="Cargolift" value="4" checked={newad?.servicelift == 4} onChange={(e)=>{ SetNewAd({servicelift: parseInt(e.target.value)}) }} />
                                            <label className="form-check-label" htmlFor="Cargolift5">
                                            4
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Yard']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Closedarea" name="Closedarea" checked={newad?.yardclosedarea ?? false} onChange={(e)=>{ SetNewAd({yardclosedarea: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Closedarea">
                                            {context.langdata['Closed area']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Playground" name="Playground" checked={newad?.yardplayground ?? false} onChange={(e)=>{ SetNewAd({yardplayground: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Playground">
                                            {context.langdata['Playground']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Sportsground" name="Sportsground" checked={newad?.yardsportsground ?? false} onChange={(e)=>{ SetNewAd({yardsportsground: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Sportsground">
                                            {context.langdata['Sports ground']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Parking']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-inline form-group">
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Underground" name="Underground" checked={newad?.parkingunderground ?? false} onChange={(e)=>{ SetNewAd({parkingunderground: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Underground">
                                            {context.langdata['Underground']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Groundmultilevel" name="Groundmultilevel" checked={newad?.parkinggroundmultilevel ?? false}  onChange={(e)=>{ SetNewAd({parkinggroundmultilevel: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Groundmultilevel">
                                            {context.langdata['Ground multilevel']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Openintheyard" name="Openintheyard" checked={newad?.parkingopenintheyard ?? false} onChange={(e)=>{ SetNewAd({parkingopenintheyard: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Openintheyard">
                                            {context.langdata['Open in the yard']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mr-4">
                                            <input className="form-check-input" type="checkbox" id="Behindthegateintheyard" name="Behindthegateintheyard"  checked={newad?.parkingbehindthegateintheyard ?? false} onChange={(e)=>{ SetNewAd({parkingbehindthegateintheyard: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="Behindthegateintheyard">
                                            {context.langdata['Behind the gate in the yard']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div className="form-box w-100">
                            <div className="form-group d-flex align-items-end">
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(1)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Prev step"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Save and exit"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(3)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Next step"]}
                                    </div>
                                </button>
                            </div>
                            </div>
                        </div>
                        ) : <></>}


                        <div className="post-section post-information">
                            {step == 3 ? (<>
                            <div className="post-ad-title">
                                <i className="fas fa-folder-open"></i>
                                <h3 className="item-title">{context.langdata["Product Information"]}</h3>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">

                                </div>
                                <div className="col-sm-9">
                                    <div className="d-flex justify-content-between">
                                        <a href="javascript:void(0)" className={titleLang == "ru" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setTitleLang("ru")}>
                                            <label className="control-label">
                                                {context.langdata['Title']}
                                                <span className="ml-2">ru</span>
                                            </label>
                                        </a>
                                        <a href="javascript:void(0)" className={titleLang == "eng" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setTitleLang("eng")}>
                                        <label className="control-label">
                                            {context.langdata['Title']}
                                            <span className="ml-2">eng</span>
                                        </label>
                                        </a>
                                        <a href="javascript:void(0)" className={titleLang == "tur" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setTitleLang("tur")}>
                                        <label className="control-label">
                                            {context.langdata['Title']}
                                            <span className="ml-2">tur</span>
                                        </label>
                                        </a>
                                        <a href="javascript:void(0)"  className={titleLang == "greek" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setTitleLang("greek")}>
                                        <label className="control-label">
                                            {context.langdata['Title']}
                                            <span className="ml-2">greek</span>
                                        </label>
                                        </a>
                                        <a href="javascript:void(0)" className={titleLang == "deutsch" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setTitleLang("deutsch")}>
                                        <label className="control-label">
                                            {context.langdata['Title']}
                                            <span className="ml-2">deutsch</span>
                                        </label>
                                        </a>
                                    </div>

                                    <div className="form-group">
                                        {titleLang == "ru" ? <input type="text" className="form-control" name="title" id="post-title" value={newad?.title_ru ?? ""} onChange={(e)=>{ SetNewAd({title_ru: e.target.value}) }} /> : <></>}
                                        {titleLang == "eng" ? <input type="text" className="form-control" name="title" id="post-title" value={newad?.title_eng ?? ""} onChange={(e)=>{ SetNewAd({title_eng: e.target.value}) }} /> : <></>}
                                        {titleLang == "tur" ? <input type="text" className="form-control" name="title" id="post-title" value={newad?.title_tur ?? ""} onChange={(e)=>{ SetNewAd({title_tur: e.target.value}) }} /> : <></>}
                                        {titleLang == "greek" ? <input type="text" className="form-control" name="title" id="post-title" value={newad?.title_greek ?? ""} onChange={(e)=>{ SetNewAd({title_greek: e.target.value}) }} /> : <></>}
                                        {titleLang == "deutsch" ? <input type="text" className="form-control" name="title" id="post-title" value={newad?.title_deutsch ?? ""} onChange={(e)=>{ SetNewAd({title_deutsch: e.target.value}) }} /> : <></>}
                                    </div>
                                </div>
                            </div>
                           

                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Price Type']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <DisplayError title={context.langdata["Price Type not set"]} show={!newad.costtype || newad.costtype == undefined || newad.costtype == ""}></DisplayError>
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({costtype: e.target.value}) }}>
                                            <option selected={!newad?.costtype || newad?.costtype == ""} value="">{context.langdata['notset']}</option>
                                            <option selected={newad?.costtype == "FIXED"} value="FIXED">{context.langdata['Fixed']}</option>
                                            <option selected={newad?.costtype == "NEGOTIABLE"} value="NEGOTIABLE">{context.langdata['Negotiable']}</option>
                                            <option selected={newad?.costtype == "CALLFORPRICE"} value="CALLFORPRICE">{context.langdata['On Call']}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Currency']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <DisplayError title={context.langdata["Currency not set"]} show={!newad.currency || newad.currency == undefined || newad.currency == ""}></DisplayError>
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({currency: e.target.value}) }}>
                                            <option selected={!newad?.currency || newad?.currency == undefined} value="">{context.langdata['notset']}</option>
                                            <option selected={newad?.currency == "EURO"} value="EURO">{context.langdata['Euro']}</option>
                                            <option selected={newad?.currency == "RUBLE"} value="RUBLE">{context.langdata['Ruble']}</option>
                                            <option selected={newad?.currency == "POUND"} value="POUND">{context.langdata['Pound']}</option>
                                            <option selected={newad?.currency == "LIRA"} value="LIRA">{context.langdata['Lira']}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Price']} [€]
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <DisplayError title={context.langdata["Cost not set"]} show={!newad.cost || newad.cost == undefined || newad.cost<=0}></DisplayError>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="price" id="post-price" value={newad?.cost ?? 0} onChange={(e)=>{ SetNewAd({cost: parseInt(e.target.value)}) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                    {context.langdata['Condition']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="AdCondition1" name="AdCondition" value="NEW" checked={newad?.state == "NEW"} onChange={(e)=>{ SetNewAd({state: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="AdCondition1">
                                            {context.langdata['New']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="AdCondition2" name="AdCondition" value="INUSE" checked={newad?.state == "INUSE"} onChange={(e)=>{ SetNewAd({state: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="AdCondition2">
                                            {context.langdata['Used']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Brand']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({brand: e.target.value}) }}>
                                            <option selected={newad?.brand == ""} value="">- Select an Option -</option>
                                            {
                                                brands?.map((item, index)=>
                                                {
                                                    return <option  selected={newad?.brand == item} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="form-box w-100">
                            <div className="form-group d-flex align-items-end">
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(autoad || propertyad ? 2 : 1)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Prev step"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Save and exit"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(4)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Next step"]}
                                    </div>
                                </button>
                            </div>
                            </div>
                            </>) : (<></>)}

                            {step == 4 ? (
                                <>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Authenticity']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="form-check form-radio-btn">
                                            <input className="form-check-input" type="radio" id="Authenticity1" name="Authenticity" value="ORIGINAL" checked={newad?.authenticity == "ORIGINAL"} onChange={(e)=>{ SetNewAd({authenticity: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="Authenticity1">
                                                {context.langdata['Original']}
                                            </label>
                                        </div>
                                        <div className="form-check form-radio-btn mx-3">
                                            <input className="form-check-input" type="radio" id="Authenticity2" name="Authenticity" value="COPY" checked={newad?.authenticity == "COPY"}  onChange={(e)=>{ SetNewAd({authenticity: e.target.value}) }} />
                                            <label className="form-check-label" htmlFor="Authenticity2">
                                                {context.langdata['Copy']}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">

                                    <a href="javascript:void(0)" className={descriptionLang == "ru" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setDescriptionLang("ru")}>
                                        <label className="control-label">
                                            {context.langdata['Description']}
                                            <span className="ml-2">ru</span>
                                        </label>
                                    </a>
                                    <a href="javascript:void(0)" className={descriptionLang == "eng" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setDescriptionLang("eng")}>
                                    <label className="control-label">
                                        {context.langdata['Description']}
                                        <span className="ml-2">eng</span>
                                    </label>
                                    </a>
                                    <a href="javascript:void(0)" className={descriptionLang == "tur" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setDescriptionLang("tur")}>
                                    <label className="control-label">
                                        {context.langdata['Description']}
                                        <span className="ml-2">tur</span>
                                    </label>
                                    </a>
                                    <a href="javascript:void(0)"  className={descriptionLang == "greek" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setDescriptionLang("greek")}>
                                    <label className="control-label">
                                        {context.langdata['Description']}
                                        <span className="ml-2">greek</span>
                                    </label>
                                    </a>
                                    <a href="javascript:void(0)" className={descriptionLang == "deutsch" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setDescriptionLang("deutsch")}>
                                    <label className="control-label">
                                        {context.langdata['Description']}
                                        <span className="ml-2">deutsch</span>
                                    </label>
                                    </a>

                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                       {descriptionLang == "ru" ? <textarea name="description" className="form-control textarea" id="description"  cols={30} rows={8} value={newad?.description_ru ?? ""} onChange={(e)=>{ SetNewAd({description_ru: e.target.value}) }}></textarea> : <></> }
                                       {descriptionLang == "eng" ? <textarea name="description" className="form-control textarea" id="description"  cols={30} rows={8} value={newad?.description_eng ?? ""} onChange={(e)=>{ SetNewAd({description_eng: e.target.value}) }}></textarea> : <></> }
                                       {descriptionLang == "tur" ? <textarea name="description" className="form-control textarea" id="description"  cols={30} rows={8} value={newad?.description_tur ?? ""} onChange={(e)=>{ SetNewAd({description_tur: e.target.value}) }}></textarea> : <></> }
                                       {descriptionLang == "greek" ? <textarea name="description" className="form-control textarea" id="description"  cols={30} rows={8} value={newad?.description_greek ?? ""} onChange={(e)=>{ SetNewAd({description_greek: e.target.value}) }}></textarea> : <></> }
                                       {descriptionLang == "deutsch" ? <textarea name="description" className="form-control textarea" id="description"  cols={30} rows={8} value={newad?.description_deutsch ?? ""} onChange={(e)=>{ SetNewAd({description_deutsch: e.target.value}) }}></textarea> : <></> }
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="form-group d-flex align-items-end">
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(3)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Prev step"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Save and exit"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(5)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Next step"]}
                                    </div>
                                </button>
                            </div>
                            </>
                            ) : (<></>)}
                        </div>

                        {step == 5 ? (
                        <div className="post-section post-features">
                            <div className="post-ad-title">
                                <i className="fas fa-list-ul"></i>
                                <h3 className="item-title">{context.langdata['Features']}</h3>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <a href="javascript:void(0)" className={featureLang == "ru" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setFeatureLang("ru")}>
                                        <label className="control-label">
                                            {context.langdata['Features List']}
                                            <span className="ml-2">ru</span>
                                        </label>
                                    </a>
                                    <a href="javascript:void(0)" className={featureLang == "eng" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setFeatureLang("eng")}>
                                    <label className="control-label">
                                        {context.langdata['Features List']}
                                        <span className="ml-2">eng</span>
                                    </label>
                                    </a>
                                    <a href="javascript:void(0)" className={featureLang == "tur" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setFeatureLang("tur")}>
                                    <label className="control-label">
                                        {context.langdata['Features List']}
                                        <span className="ml-2">tur</span>
                                    </label>
                                    </a>
                                    <a href="javascript:void(0)"  className={featureLang == "greek" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setFeatureLang("greek")}>
                                    <label className="control-label">
                                        {context.langdata['Features List']}
                                        <span className="ml-2">greek</span>
                                    </label>
                                    </a>
                                    <a href="javascript:void(0)" className={featureLang == "deutsch" ? "text-underline mouse-underline text-underline" :"mouse-underline"} onClick={()=>setFeatureLang("deutsch")}>
                                    <label className="control-label">
                                        {context.langdata['Features List']}
                                        <span className="ml-2">deutsch</span>
                                    </label>
                                    </a>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        {featureLang == "ru" ? <textarea name="description" className="form-control textarea" id="description2" cols={30} rows={5} value={newad?.features_ru ?? ""} onChange={(e)=>{ SetNewAd({features_ru: e.target.value}) }}></textarea> : <></>}
                                        {featureLang == "eng" ? <textarea name="description" className="form-control textarea" id="description2" cols={30} rows={5} value={newad?.features_eng ?? ""} onChange={(e)=>{ SetNewAd({features_eng: e.target.value}) }}></textarea> : <></>}
                                        {featureLang == "tur" ? <textarea name="description" className="form-control textarea" id="description2" cols={30} rows={5} value={newad?.features_tur ?? ""} onChange={(e)=>{ SetNewAd({features_tur: e.target.value}) }}></textarea> : <></>}
                                        {featureLang == "greek" ? <textarea name="description" className="form-control textarea" id="description2" cols={30} rows={5} value={newad?.features_greek ?? ""} onChange={(e)=>{ SetNewAd({features_greek: e.target.value}) }}></textarea> : <></>}
                                        {featureLang == "deutsch" ? <textarea name="description" className="form-control textarea" id="description2" cols={30} rows={5} value={newad?.features_deutsch ?? ""} onChange={(e)=>{ SetNewAd({features_deutsch: e.target.value}) }}></textarea> : <></>}
                                        <div className="help-text">
                                            <span>{context.langdata['Write a feature in each line eg.']}</span>
                                            <span>{context.langdata['Feature']} 1</span>
                                            <span>{context.langdata['Feature']} 2</span>
                                            <span>....</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group d-flex align-items-end">
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(4)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Prev step"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Save and exit"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(6)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Next step"]}
                                    </div>
                                </button>
                            </div>
                        </div>) : (<></>)}

                        {step == 6 ? (
                        <div className="post-section post-img">
                            <div className="post-ad-title">
                                <i className="far fa-image"></i>
                                <h3 className="item-title">{context.langdata['Images']}</h3>
                            </div>
                            <div className="form-group">
                                <div className="img-gallery">
                                    {tempfiles?.map((file:String, index:number) => {
                                        return <div style={{display:"inline-block", position:"relative", marginRight:10, marginBottom:10}} key={"photo_"+index}>
                                            <img src={`media/temp/${userProfile.id}/${file.toString()}`} style={{width:"auto", height:"200px"}} alt={file.toString()} />
                                            <a href="javascript:void(0)" style={{position:"absolute", right: 15, top:15, width:30, height:30, borderRadius:15, backgroundColor:"white", color:"black", textAlign:"center"}} onClick={()=>RemoveTempFile(file.toString())}>
                                                <i className="far fa-trash-alt" style={{verticalAlign:"center"}}></i>
                                            </a>
                                        </div>
                                    })}
                                    
                                    <div className="img-upload">
                                        <input type="file" ref={fileInput} multiple={true} style={{display:"none"}} onChange={handleFilesChange}  />
                                        <div className="upload-title">{context.langdata['Drop files here to add them.']}</div>
                                        <a href="javascript:void(0)" onClick={()=>SelectFiles()} className="item-btn">{context.langdata['Browse files']} ...</a>
                                    </div>
                                       
                                    <div className="img-upload-instruction alert alert-danger">
                                        <p>{context.langdata['Recommended image size to']} (1920x1080) px</p>
                                        <p>{context.langdata['Image maximum size']} 3 MB.</p>
                                        <p>{context.langdata['Allowed image type']} (png, jpg, jpeg).</p>
                                        <p>{context.langdata['You can upload up to 10 images.']}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group d-flex align-items-end">
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(5)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Prev step"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Save and exit"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(7)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Next step"]}
                                    </div>
                                </button>
                            </div>
                        </div> ) : (<></>)}

                        {step == 7 ? (
                        <div className="post-section post-contact">
                            <div className="post-ad-title">
                                <i className="fas fa-user"></i>
                                <h3 className="item-title">{context.langdata['Contact Details']}</h3>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Country']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <DisplayError title={context.langdata["Country not selected"]} show={selectedCountry=="0"}></DisplayError>
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({country: e.target.value});  setSelectedCountry(e.target.value); setSelectedRegion("0"); SetNewAd({region: undefined}); SetNewAd({city: undefined}) }}>
                                            <option value="0" selected={newad.country == "" || newad.country == undefined}>{context.langdata['Select Country']}</option>
                                            {countries.map((country:CountryItem, index:number) => {
                                                return <option key={"country_"+index} selected={newad.country == country.id} value={country.id}>{country.Country_ru}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['State']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <DisplayError title={context.langdata["Region/State not selected"]} show={selectedRegion == "0"}></DisplayError>
                                        <select className="form-control select-box" onChange={(e)=>{ SetNewAd({region: e.target.value}); setSelectedRegion(e.target.value); SetNewAd({city: undefined})  }}>
                                            <option value="0">{context.langdata['Select State/Region']}</option>
                                            {regions.map((region:RegionItem, index:number) => {
                                                return (selectedCountry == region.Country.id) ? <option key={"region_"+index} selected={newad.region == region.id} value={region.id}>{region.Region_ru}</option> : <></>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['City']}
                                        <span>*</span>
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <DisplayError title={context.langdata["City not selected"]} show={!newad.city || newad.city == undefined || newad.city == "0"}></DisplayError>
                                        <select className="form-control select-box"  onChange={(e)=>{ SetNewAd({city: e.target.value}) }}>
                                            <option value="0">{context.langdata['Select City']}</option>
                                            {cities.map((city:CityItem, index:number) => {
                                                return (selectedRegion == city.Region.id) ? <option key={"city_"+index} selected={newad.city == city.id} value={city.id}>{city.City_ru}</option> : <></>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Zip Code']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="zipcode" id="post-zip" value={newad?.postalcode ?? ""}  onChange={(e)=>{ SetNewAd({postalcode: e.target.value}) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Address']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <textarea name="address" className="form-control textarea" id="address" cols={30} rows={2} value={newad?.contactaddress_ru ?? ""}  onChange={(e)=>{ SetNewAd({contactaddress_ru: e.target.value}) }} ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Phone']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <InputMask mask="+9 (999) 999 99 99" maskChar="_" value={newad?.phones ?? ""} onChange={(e)=>{ SetNewAd({phones: e.target.value}) }} >
                                            {(inputProps) => <input name="phone" type="text" {...inputProps} className="form-control"   /> }
                                        </InputMask>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Whatsapp Number']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <input type="text"  className="form-control" name="whatsapp" id="whatsapp" value={newad?.whatsapp ?? ""}   onChange={(e)=>{ SetNewAd({whatsapp: e.target.value}) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Email']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <input type="email" className="form-control" name="email" id="email" value={newad?.email ?? ""}  onChange={(e)=>{ SetNewAd({email: e.target.value}) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="control-label">
                                        {context.langdata['Map']}
                                    </label>
                                </div>
                                <div className="col-sm-9">
                                    <div className="form-group">
                                        <div style={{display: newad?.dontshowmap ? "none" : "block"}}>
                                            <span style={{marginRight:20}}>{context.langdata['Latitude']}: {lat}</span> 
                                            <span>{context.langdata['Longitude']}: {lng}</span>
                                        </div>
                                        <div ref={mapContainer} className="map-container" style={{width: "100%", marginBottom: 20, height: "400px", display: newad?.dontshowmap ? "none" : "block"}}></div>
                                        <div className="form-check form-check-box">
                                            <input className="form-check-input" type="checkbox" id="map"  checked={newad?.dontshowmap} onChange={(e)=>{ SetNewAd({dontshowmap: e.target.checked}) }} />
                                            <label className="form-check-label" htmlFor="map">{context.langdata["Don't show the Map"]}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                           
                            <div className="form-group d-flex align-items-end">
                                <button className="submit-btn ml-auto" onClick={(e)=>{setStep(6)}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Prev step"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                    {context.langdata["Save and exit"]}
                                    </div>
                                </button>
                                <button className="submit-btn ml-auto" onClick={(e)=>{ TrySendAdToServer(e) }}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {context.langdata["Send for verification"]}
                                    </div>
                                </button>
                            </div>

                          
                        </div>) : (<></>)}
                    </form>

                    }
                </div>
            </div>
        </div>
    </section>
    
}

export default NewAdForm