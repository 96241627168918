import { AdItem } from '../../models/AdItem'
import { AdFavorites, User } from '../../models/User'
import {AdsCommands} from './ads'
import { ReducerAction } from './reducers'
import {CategoriesCommands} from './categories'
import {RegionsCommands} from './regions'
import {CarsCommands} from './cars'
import {NewsCommands} from './news'
import {LocationsCommands} from './places'
import {CurrencyCommands} from './currency'
import {UsersCommands} from './users'
import { FavoritesCommands } from './favorites'

const ReduxActions = {
    ads:{
        getAds(params:any):ReducerAction{
            return {
                type: AdsCommands.GET_ADS,
                params: params
            }
        },
        addAd(ad:AdItem):ReducerAction{
            return {
                type: AdsCommands.ADD_AD,
                params: ad
            }
        },
        removeAd(adid:string):ReducerAction{
            return {
                type: AdsCommands.REMOVE_AD,
                params: {id: adid}
            }
        },
        setAds(ads:AdItem[]):ReducerAction{
            return {
                type: AdsCommands.SET_ADS,
                params: ads
            }
        },
        setAdPublished(adid:string, publish:boolean):ReducerAction{
            return {
                type: AdsCommands.SET_AD_PUBLISHED,
                params: {id: adid, publish: publish}
            }
        },

    },
    favorites:{
        setUserFovourites: (favourites: AdFavorites[]) => {
            return {
                type: FavoritesCommands.SET_USER_FAVOURITES,
                params: favourites
            }
        },
        ToggleFavoriteCountry(id: string, state: boolean): ReducerAction {
            return {
                type: FavoritesCommands.TOGGLE_FAVORITE_COUNTRY,
                params: { id: id, newstate: state }
            }
        }
    },
    users:{ 
        removeUserBanner():ReducerAction{
            return {
                type: UsersCommands.REMOVE_USER_BANNER,
                params: null
            }
        },
        removeUserLogo():ReducerAction{
            return {
                type: UsersCommands.REMOVE_USER_LOGO,
                params: null
            }
        },
        removeUserAvatar():ReducerAction{
            return {
                type: UsersCommands.REMOVE_USER_AVATAR,
                params: null
            }
        },
        setUserBanner(file:string):ReducerAction{
            return {
                type: UsersCommands.SET_USER_BANNER,
                params: file
            }
        },
        setUserLogo(file:string):ReducerAction{
            return {
                type: UsersCommands.SET_USER_LOGO,
                params: file
            }
        },
        setUserAvatar(file:string):ReducerAction{
            return {
                type: UsersCommands.SET_USER_AVATAR,
                params: file
            }
        },
        setUser(user:User | null):ReducerAction{
            return {
                type: UsersCommands.SET_USER,
                params: user
            }
        }
    },
    regions: {
        setRegions(regions:any):ReducerAction{
            return {
                type: RegionsCommands.REGIONS_LOADED,
                params: regions
            }
        },
        setCountries(countries:any):ReducerAction{
            return {
                type: RegionsCommands.COUNTIRES_LOADED,
                params: countries
            }
        },
        setCities(cities:any):ReducerAction{
            return {
                type: RegionsCommands.CITIES_LOADED,
                params: cities
            }
        },
        setActiveCountry(country:string):ReducerAction{
            return {
                type: RegionsCommands.ACTIVE_COUNTRY_SET,
                params: country
            }
        },
        setActiveLocation(location:any):ReducerAction{
            return {
                type: RegionsCommands.ACTIVE_LOCATION_SET,
                params: location
            }
        }

    },
    categories: {
        setCategories(categories:any):ReducerAction{
            return {
                type: CategoriesCommands.CATEGORIES_LOADED,
                params: categories
            }
        }
    },
    currency: {
        setCurrency(currency:string, coefficient:number):ReducerAction{    
            return {
                type: CurrencyCommands.SET_CURRENCY,
                params: {currency: currency, coefficient: coefficient}
            }
        }
    },
    cars: {
        setMarks(items:any):ReducerAction{
            return {
                type: CarsCommands.MARKS_LOADED,
                params: items
            }
        },
        setModels(items:any):ReducerAction{
            return {
                type: CarsCommands.MODELS_LOADED,
                params: items
            }
        },
        setGenerations(items:any):ReducerAction{
            return {
                type: CarsCommands.GENERATIONS_LOADED,
                params: items
            }
        },
        setEdits(items:any):ReducerAction{
            return {
                type: CarsCommands.CAREDITS_LOADED,
                params: items
            }
        },
    },
    news: {
        setNews(items:any):ReducerAction{
            return {
                type: NewsCommands.NEWS_LOADED,
                params: items
            }
        }
    },
    locations: {
        setLocations(items:any):ReducerAction{
            return {
                type: LocationsCommands.LOCATIONS_LOADED,
                params: items
            }
        }
    }
}

export default ReduxActions