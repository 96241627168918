const ru = {
    "Russian":"Rusça",
    "English":"Ingilizce",
    "Turkish":"Türk",
    "Greek": "Yunan",
    "Deutsch": "Almanca",
    "RussianCountry":"Russia",
    "EnglishCountry":"USA",
    "TurkishCountry":"Turkey",
    "GreekCountry": "Greece",
    "DeutschCountry": "Germany",
    "Post Your Ad":"Reklam",
    "Contact": "Kişiler",
    "Language": "Dil",
    "Home": "ev",
    "MainSlogan": "Tek bir tıklamayla satın alın, satın, kiralayın ve değiştirin",
    "MainSloganSubtitle": "29'dan fazla kategoride 2000'den fazla aktif listede ücretsiz arama",
    "Select location": "Konum",
    "Select Category": "Kategori seçimi",
    "Enter Keyword here": "Arama...",
    "Search": "Arama",
    "Last news": "Son haberler",
    "The best places": "En İyi Yerler",
    "Recent announcements": "Son duyurular",
    "Open the ad catalog": "Reklam dizinini aç",
    "All": "Herşey",
    "Recommended": "Önerilen",
    "New advs": "Yeni",
    "Popular advs": "Popüler",
    "Ad Categories": "Reklam kategorileri",
    "Login/Register": "Giriş Kayıt",
    "Auto": "Oto",
    "Realty": "Emlak",
    "Work": "İş",
    "Services": "Hizmetler",

    "Filter": "Filtre",
    "Type": "Bir çeşit",
    "Category": "Başlık",
    "Location": "Bölge",
    "Price Range": "Fiyat aralığı",
    "Apply Filters": "Filtre uygula",
    "Showing": "Görüntüleniyor",
    "of": "itibaren",
    "results": "Sonuçlar",

    "Sort By": "Çeşit",
    "A to Z (title)":"A -> Z (başlık)",
    "Z to A (title)":"Z -> A (başlık)",
    "Data Added (oldest)":"Ekleme Tarihi",
    "Most Viewed":"Sık görüntülemeler",
    "Less Viewed":"Nadir Görüntülemeler",
    "Price (low to high)":"Fiyat (düşükten yükseğe)",
    "Price (high to low)":"Fiyat pahalıdan ucuza)",
    "Next": "Sonraki",
    "Previous": "Öncesi",
    "New": "Yeni",
    "Show Phone No": "Telefon numarası",
    "Add to Favourites": "Yer imlerine ekle",
    "Sell": "Satış",
    "views": "görüntüleme",
    "Details": "Detaylar",
    "Features": "özellikler",
    "Report Abuse": "Kötüye Kullanımı Bildir",
    "Share": "Paylaşmak",
    "Related Ads": "benzer ilanlar",
    "Leave a Review": "Geribildirim bırak",
    "Your email address will not be published. Required fields are marked": "E-posta hesabınız yayımlanmayacak. Gerekli alanlar işaretlendi",
    "Your Name": "İsim",
    "Your Email": "E-posta",
    "Review Title": "Başlığı gözden geçir",
    "Your Review": "Gözden geçirmek",
    "SUBMIT": "GÖNDERMEK",
    "LOAD MORE": "LOAD MORE",
    "Seller Information": "Satış elemanı",
    "Click to reveal phone number": "Telefon numarasını göstermek için tıklayın",
    "Email to Seller": "Satıcıya Yazın",
    "Safety Tips for Buyers": "Alıcılar için Güvenlik İpuçları",
    "Meet seller at a public place": "Satıcıyla halka açık bir yerde tanışın",
    "Check The item before you buy": "Ürünü satın almadan önce kontrol edin",
    "Pay only after collecting The item": "Sadece malları aldıktan sonra ödeme yapın",

    "Dollar": "Dolar",
    "Euro": "Euro",
    "Ruble": "Ruble",
    "Pound": "Pound",
    "Lira": "Lira",

    "Dashboard": "Analitik",
    "My Listings": "duyurularım",
    "Store": "Puan",
    "Favourites": "Ertelenmiş",
    "Payments": "ödemeler",
    "Account details": "Profil",
    "Logout": "çıkış",

    "Login": "İçeri gel",
    "Username or E-mail": "Kullanıcı adı ya da email",
    "Password": "Şifre",
    "Remember Me": "beni hatırlamak için",
    "Forgot your password": "Parolanızı mı unuttunuz",
    "Register": "kayıt",
    "Username cannot be changed": "Kullanıcı adı profilden değiştirilebilir",
    "Email address": "e-posta adresi",
    "Username": "Kullanıcı adı",
    "Phone": "Telefon",
    "Repeat password": "Şifre tekrarlama",

    "Sub Category" : "alt başlık",
    "Next step": "Daha öte",
    "Send for verification": "İnceleme için gönder",
    "Product Information": "Genel ürün bilgileri",
    "Title": "başlık",
    "Price Type" : "Maliyet türü",
    "Fixed": "Sabit",
    "Negotiable": "pazarlık edilebilir",
    "On Call": "Telefonda",
    "Price": "Fiyat",
    "Condition": "Ürün durumu",
    "Used": "yuh",
    "Brand": "Marka",
    "Authenticity": "özgünlük",
    "Original": "orijinal",
    "Copy": "kopyala",
    "Description": "Tanım",
    "Features List": "Özellik listesi",
    "Images": "Görüntüler",
    "Drop files here to add them.": "Dosyaları eklemek için buraya sürükleyin.",
    "Browse files": "Dosyaları bul",
    "Recommended image size to": "Önerilen görüntü çözünürlüğü",
    "Image maximum size": "Maksimum dosya boyutu",
    "Allowed image type": "Görüntü biçimleri",
    "You can upload up to 10 images": "En fazla 10 resim yükleyebilirsiniz.",
    "Contact Details": "Kişiler",
    "City": "Şehir",
    "Zip Code": "Posta Kodu",
    "Address": "Adres",
    "Whatsapp Number": "whatsapp numarası",
    "Email" : "E-posta",
    "Map": "Harita",
    "Don't show the Map": "Haritayı gösterme",
    "Ad Type": "Reklam Türü",
    "Personal car": "Kişisel araba",
    "Car bought for sale": "Satılık satın alınan araç",
    "Color": "Renk",
    "Video": "Video",
    "Mileage": "kilometre",
    "Сar condition": "Araba durumu",
    "Not broken": "dövülmemiş",
    "Broken": "dövülmüş",
    "VIN or body number": "VIN veya vücut numarası",
    "Government number": "araba numarası",
    "Marka": "marka",
    "Model": "modeli",
    "Year of issue": "yayın yılı",
    "Body": "Gövde",
    "Generation": "Nesil",
    "Engine's type": "motor tipi",
    "CarDrive": "Tahrik ünitesi",
    "Transmission": "Bulaşma",
    "Rudder position": "dümen pozisyonu",
    "Prev step": "Geri",
    "Save and exit": "Kaydet ve çık",

    "Basic Information": "temel bilgiler",
    "Account": "Kullanıcı adı",
    "First Name": "İsim",
    "Last Name": "Soyadı",
    "Change Password": "Şifre değiştir",
    "WhatsApp Phone": "whatsapp telefon",
    "Website": "İnternet sitesi",
    "State": "Bölge/Eyalet",
    "Country": "Ülke",
    "Update Account": "verileri güncelle",
    "Store Images": "Mağaza için görseller",
    "Store Banner": "afiş",
    "Store Logo": "Logo",
    "Store Schedule": "Takvim",
    "Opening Hours": "Açılış saatleri",
    "Always open": "7/24 açık",
    "Select Opening Hours": "Çalışma saatlerini belirtin",
    "Store Information": "Bilgi depola",
    "Name": "İsim",
    "Slogan": "slogan",
    "Socials": "Sosyal ağlar",
    "Update Store": "Mağazayı güncelle",
    "Maximum file size": "Maksimum dosya boyutu",
    "My goods": "Benim eşyalarım",
    "Membership Report": "hesap durumu",
    "Status": "Durum",
    "Active": "Aktif",
    "Blocked": "engellendi",
    "Created": "oluşturuldu",
    "Ads created": "Reklamlar oluşturuldu",
    "Finished transactions": "Tamamlanan anlaşmalar",
    "Items in the store": "Mağazadaki ürünler",
    "Goods sold": "satılan mal",
    "Property": "Emlak",
    "Places an ad": "Bir reklam yerleştirir",
    "Owner": "Sahip",
    "Mediator": "Arabulucu",
    "Developer": "Geliştirici",
    "Housing type": "Emlak Tipi",
    "Flat": "Düz",
    "Apartments": "Daireler",
    "House": "Ev",
    "Property condition": "Mülkiyet durumu",
    "Level": "Zemin",
    "Rooms": "oda sayısı",
    "Studio": "Stüdyo",
    "10 rooms and more": "10 oda veya daha fazla",
    "Free layout": "Ücretsiz düzen",
    "Balcony or loggia": "Balkon veya sundurma",
    "Balcony": "Balkon",
    "Loggia": "Sundurma",
    "Wardrobe": "Giysi dolabı",
    "Panoramic windows": "panoramik pencereler",
    "Square": "Meydan",
    "Total space": "Genel",
    "Living space": "yerleşim",
    "Ceiling height": "Tavan yüksekliği",
    "Bathroom": "banyo",
    "Combined": "kombine",
    "Separated": "ayrılmış",
    "Need": "Gereklis",
    "Cosmetic": "Kozmetik",
    "Designer": "tasarımcı",
    "Repair": "Onarım",
    "Windows": "pencere",
    "To the yard": "Avluya",
    "To the street": "Dışarıda",
    "To the sunny side": "güneşli tarafa",
    "Furniture": "Mobilya",
    "Kitchen": "Mutfak",
    "Clothes storage": "Giysi saklama",
    "Sleeping places": "Uyuyan yerler",
    "Technics": "teknik",
    "Air conditioning": "Klima",
    "Fridge": "Buzdolabı",
    "Washing machine": "Çamaşır makinesi",
    "Dishwasher": "Bulaşık makinesi",
    "Water heater": "su ısıtıcı",
    "About home": "Ev hakkında",
    "House type": "Ev tipi",
    "Brick": "Tuğla",
    "Panel": "Panel",
    "Blocky": "bloklu",
    "Monolithic": "monolitik",
    "Monolithic - brick": "Monolitik - tuğla",
    "Wooden": "Odun",
    "Concierge": "Kapıcı",
    "Garbage chute": "Çöp bacası",
    "Gas": "Gaz",
    "Year of construction": "yapım yılı",
    "Floors in the house": "Evdeki zeminler",
    "Passenger lift": "yolcu asansörü",
    "Cargo lift": "Servis asansörü",
    "None": "Değil",
    "Yard": "avlu",
    "Parking": "otopark",
    "Closed area": "kapalı alan",
    "Playground": "Oyun alanı",
    "Sports ground": "Oyun alanı",
    "Underground": "yeraltı",
    "Ground multilevel": "Zemin çok seviyeli",
    "Open in the yard": "bahçede aç",
    "Behind the gate in the yard": "Bahçedeki kapının arkasında",
    "Select a category": "Bir kategori belirtin",
    "Select a subcategory": "Bir alt kategori belirtin",
    "slogan": "Altbilgideki slogan",
    "HeaderLinks1Title": "nasıl hızlı satılır",
    "HeaderLinks2Title": "Bilgi",
    "HeaderLinks3Title": "Yardım ve Destek",
    "cantcreateads": "Kayıt olana kadar reklam oluşturamazsınız!",
    "cantcreateads_blocked": "Hesabınız kilitliyken reklam oluşturamazsınız!",
    "RegisterOrLogin": "Giriş yapmak",
    "notset": "ayarlanmadı",

    "mainpage_title": "AVGU - ilan tahtası",
    "new Ad": "Yeni duyuru",
    "Main Page": "Ana Sayfa",
    "Personal cabinet": "Kişisel kabinet",
    "No data": "Veri yok",
    "wheelLeft": "Steering wheel on the left",
    "wheelRight": "Steering wheel on the right",
    "Currency": "Currency",
    "NewAd": "New Ad",
    "NewAdMessage": "Your ad has been sent for moderation. After verification, it will be published on the site.",
    "Write a feature in each line eg": "Write a feature in each line eg",
    "Feature": "Feature",
    "My Account": "My Account",
    "Published": "Published",
    "Hidden": "Hidden",
    "Remove from publish": "Remove from publish",
    "Publish": "Publish",
    "Delete": "Delete",
    "Reviews list": "Reviews list",
    "In start": "In start",
    "All category": "All category",
}

export default ru