import React, { useContext } from "react";
import { Routes, Route, Link, useParams } from "react-router-dom";

import {AppContext} from '../../App'

import BreadCroombsHeader from './../breadcroombsheader'
import NewAdForm from './../newad/newad'

const NewAdPage = ({editad}:{editad:boolean}) => {
    let { adid } = useParams();
    var context = useContext(AppContext)

    return <div id="wrapper" className="wrapper">
        <BreadCroombsHeader title={context.langdata["new Ad"]}></BreadCroombsHeader>
        <NewAdForm id={editad ? (adid ?? "") : ""}></NewAdForm>
    </div>
}

export default NewAdPage