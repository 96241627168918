import React, {useContext, useState, useEffect} from "react"
import { AppContext } from "../App"
import { Route, Link }  from "react-router-dom"
import { appendFile } from "fs"
import { FiDollarSign } from 'react-icons/fi';
import {BsCurrencyEuro} from 'react-icons/bs'
import {BiRuble} from 'react-icons/bi'
import {BiPound} from 'react-icons/bi'
import {BiLira} from 'react-icons/bi'
import { CountryItem } from "../models/Region";
import { GetByLanguage } from "./langs/languages";

interface IHeader
{
    OnLangChange:(lang:string)=>void
    OnCurrencyChange:(currency:string)=>void
    OnCountryChange:(country:string)=>void
}

var lastCountries:any = null
const Header = (props:IHeader) => {

    var context = useContext(AppContext)
    var state = context.dataStore?.getState()
    var [countries, setCountries] = useState<[CountryItem]>(state?.regions?.Countries ?? [])

    const StoreUpdate = () =>
    {
        var state = context.dataStore?.getState()
        var currentCountries = state?.regions?.Countries
        if (lastCountries!=currentCountries)
        {
            setCountries(state?.regions?.Countries);
            lastCountries = currentCountries
        }
    }

    useEffect(()=>
    {
        context.dataStore?.subscribe(StoreUpdate)
    }, []);

    
    var [logged, setLogged] = useState(context.logged)
    
    const SetActiveCurrency = (currency:string) =>
    {
        localStorage.setItem("currency", currency)
        props.OnCurrencyChange(currency)
    }

    const SetActiveLang = (lang:string) =>
    {
        localStorage.setItem("lang", lang)
        props.OnLangChange(lang)
    }

    const SetActiveCountry = (country:string) =>
    {
        localStorage.setItem("country", country)
        props.OnCountryChange(country)
    }

    return <header className="header">
        <div id="rt-sticky-placeholder"></div>
        <div id="header-menu" className="header-menu menu-layout2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 d-flex align-items-center justift-content-between w-100">

                        <div className="logo-area">
                            <Link to="/" className="temp-logo">
                                <h2 className="font-weight-bold text-uppercase p-0 m-0">
                                    <span style={{color:"red"}}>a</span>
                                    <span style={{color:"green"}}>v</span>
                                    <span style={{color:"blue"}}>g</span>
                                    <span style={{color:"orange"}}>u</span>
                                </h2>
                            </Link>
                        </div>
                    
                    
                        <nav id="dropdown" className="template-main-menu d-flex justify-cintent-between w-100">
                            
                            <ul>
                               <li>
                                    <Link to="/ads/cars">{context.langdata["Auto"]}</Link>
                                </li>
                                <li>
                                    <Link to="/ads/realty">{context.langdata["Realty"]}</Link>
                                </li>
                                <li>
                                    <Link to="/ads/work">{context.langdata["Work"]}</Link>
                                </li>
                                <li>
                                    <Link to="/ads/services">{context.langdata["Services"]}</Link>
                                </li>
                            </ul>   
                  
                        </nav>

                        <nav id="dropdown2" className="template-main-menu d-flex justify-cintent-between">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)" className="has-dropdown" style={{fontSize:12}}>
                                        {countries.map((country:CountryItem)=>{
                                            if (country.id==context.country)
                                            {
                                                return <>{GetByLanguage(country, context.lang)}</>
                                            }
                                        })}
                                    </a>

                                    <ul className="sub-menu">
                                        {countries.map((country:CountryItem)=>{
                                            return <li>
                                                <a style={{fontSize:12}} href="javascript:void(0)" className={context.country == country.id ? "font-weight-bold" : ""} onClick={()=>SetActiveCountry(country.id)}>{GetByLanguage(country, context.lang)}</a>
                                            </li>
                                        })}
                                    </ul>
                                </li>

                                <li>
                                    <a href="javascript:void(0)" className="has-dropdown"  style={{fontSize:12}}>{context.langdata["Language"]}</a>
                                    <ul className="sub-menu">
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.lang == "ru" ? "font-weight-bold" : ""} onClick={()=>SetActiveLang("ru")}> {context.langdata["Russian"]}</a>
                                        </li>
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.lang == "en" ? "font-weight-bold" : ""} onClick={()=>SetActiveLang("en")}> {context.langdata["English"]}</a>
                                        </li>
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.lang == "tur" ? "font-weight-bold" : ""} onClick={()=>SetActiveLang("tur")}> {context.langdata["Turkish"]}</a>
                                        </li>
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.lang == "greek" ? "font-weight-bold" : ""} onClick={()=>SetActiveLang("greek")}> {context.langdata["Greek"]}</a>
                                        </li>
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.lang == "deutsch" ? "font-weight-bold" : ""} onClick={()=>SetActiveLang("deutsch")}> {context.langdata["Deutsch"]}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" className="has-dropdown" style={{fontSize:12}}>
                                        { context.currency == "Dollar" ? <FiDollarSign></FiDollarSign> : <></> }
                                        { context.currency == "Euro" ? <BsCurrencyEuro></BsCurrencyEuro> : <></> }
                                        { context.currency == "Ruble" ? <BiRuble></BiRuble> : <></> }
                                        { context.currency == "Pound" ? <BiPound></BiPound> : <></> }
                                        { context.currency == "Lira" ? <BiLira></BiLira> : <></> }
                                    </a>
                                    <ul className="sub-menu">
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.currency == "Dollar" ? "font-weight-bold" : ""} onClick={()=>SetActiveCurrency("Dollar")}><FiDollarSign /> {context.langdata["Dollar"]}</a>
                                        </li>
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.currency == "Euro" ? "font-weight-bold" : ""} onClick={()=>SetActiveCurrency("Euro")}><BsCurrencyEuro /> {context.langdata["Euro"]}</a>
                                        </li>
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.currency == "Ruble" ? "font-weight-bold" : ""} onClick={()=>SetActiveCurrency("Ruble")}><BiRuble /> {context.langdata["Ruble"]}</a>
                                        </li>
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.currency == "Pound" ? "font-weight-bold" : ""} onClick={()=>SetActiveCurrency("Pound")}><BiPound /> {context.langdata["Pound"]}</a>
                                        </li>
                                        <li>
                                            <a style={{fontSize:12}} href="javascript:void(0)" className={context.currency == "Lira" ? "font-weight-bold" : ""} onClick={()=>SetActiveCurrency("Lira")}><BiLira /> {context.langdata["Lira"]}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    
                    
                        <div className="header-action-layout1">
                            <ul>
                                <li className="header-login-icon">
                                    <Link to="/account" className="color-primary" data-toggle="tooltip" data-placement="top" title={context.langdata["Login/Register"]}>
                                        <i className="far fa-user"></i>
                                    </Link>
                                </li>
                                <li className="header-btn">
                                    <Link to="/newad" className="item-btn"><i className="fas fa-plus"></i>{context.langdata["Post Your Ad"]}</Link> 
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </header>
}

export default Header