import { ReducerAction } from "./reducers"

const AdsCommands = {
    SET_ADS: "SET_ADS",
    GET_ADS: 'GET_ADS',
    ADD_AD: 'ADD_AD',
    REMOVE_AD: 'REMOVE_AD',
    SET_AD_PUBLISHED: 'SET_AD_PUBLISHED'
}

export const ads = (state = [], action:ReducerAction) => {
    switch (action.type) {
        case AdsCommands.SET_ADS:
            return action.params ?? state
        case AdsCommands.SET_AD_PUBLISHED:
            var newads = [...state]
            return newads
        case AdsCommands.GET_ADS:
            return action.params
        case AdsCommands.ADD_AD:    
            return action.params
        case AdsCommands.REMOVE_AD:
            return state.filter((ad:any) => ad.id !== action.params.id)
        default:
            return state
    }
}

export {AdsCommands}