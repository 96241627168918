import {FC, useContext} from "react";
import { configureStore } from "@reduxjs/toolkit";
import ReduxActions from "./reducers/actions";
import { ads } from "./reducers/ads";
import { user } from "./reducers/users";
import { news } from "./reducers/news";
import { places } from "./reducers/places";
import { regions } from "./reducers/regions";
import { categories } from "./reducers/categories";
import { cars } from "./reducers/cars";
import { currency } from "./reducers/currency";
import { userfavorites } from "./reducers/favorites";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { AppContext } from "../App"

import { AdItem, PhotoAlbum, PhotoItem } from "../models/AdItem";
import { NewItem } from "../models/NewItem";
import { PlaceItem } from "../models/PlaceItem";
import { AdFavorites, User } from "../models/User";
import { RegionItem } from "../models/Region";
import { CategoryItem } from "../models/Category";
import {Marka, Model, Generation} from '../models/CarMarka'
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import graphql from "../gql/gqlqueries";
import { useEffect } from "react";

var initialState = {
  ads: Array<AdItem>(),
  news: Array<NewItem>(),
  places: Array<PlaceItem>(),
  regions: Array<RegionItem>(),
  categories: Array<CategoryItem>(),
  locations: Array<PlaceItem>(),
  userfavorites: Array<AdFavorites>(),
  cars:{},
  user: null,
  currency:{USD: 1, EUR: 1, RUB: 1, GBP: 1, TRY: 1}
};

function logger({ getState }: any) {
  return (next: any) => (action: any) => {
    console.log("will dispatch", action);

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    console.log("state after dispatch", getState());

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
}

const store = createStore(
  combineReducers({ ads, news, places, user, regions, categories, userfavorites, cars, currency }),
  localStorage["redux-store"] == null
    ? initialState
    : JSON.parse(localStorage["redux-store"]),
  applyMiddleware(logger)
);

var uploadDone = 0;

interface ILoaderProps
{
  done?:()=>void
  UserRestore?:(user:User)=>void
  UserUnlogged?:()=>void
}

const DataLoader:FC<ILoaderProps> = ({done, UserRestore, UserUnlogged}) => {
  
  var context = useContext(AppContext)

  const CalculateChilds = (data: any, categoryId: string) =>
  {
     var summ = 0
     var childs = data.filter((c:CategoryItem)=>c.parent == categoryId)

     childs?.map((c:CategoryItem)=>
     {
        summ += (c.itemsCount + CalculateChilds(data, c.id))
     })

     return summ
  }

  const CalculateCounters = (data: any) =>
  {
    //var roots = data//.filter((item:CategoryItem)=>item.parent == null)
    data.map((cat:CategoryItem)=>
    {
       cat.inChildItemsCount = CalculateChilds(data, cat.id)
    })
    return [...data]
  }

  const [RefreshCurrencies] = useMutation(graphql.querues.CURRENCIES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      var currensies = JSON.parse(data.getcurrencies.value)
      store.dispatch(ReduxActions.currency.setCurrency("EUR",currensies.EUR));
      store.dispatch(ReduxActions.currency.setCurrency("RUB", currensies.RUB));
      store.dispatch(ReduxActions.currency.setCurrency("GBP",currensies.GBP));
      store.dispatch(ReduxActions.currency.setCurrency("TRY", currensies.TRY));
      UploadDone()
    },
  });

  const [RefreshFavourites] = useLazyQuery(graphql.querues.FAVORITES_ADS_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      store.dispatch(ReduxActions.favorites.setUserFovourites(data.favorites));
      UploadDone()
    },
  });

  const [RefreshCountries] = useLazyQuery(graphql.querues.COUNTRIES_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      var activedCountries = data.countries.filter((r:any)=>r.Actived == true);
      store.dispatch(ReduxActions.regions.setCountries(activedCountries));
      UploadDone()
    },
  });

  const [RefreshRegions] = useLazyQuery(graphql.querues.REGIONS_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      var activedRegions = data.regions.filter((r:any)=>r.Actived == true);
      store.dispatch(ReduxActions.regions.setRegions(activedRegions));
      UploadDone()
    },
  });
  
  const [RefreshCities] = useLazyQuery(graphql.querues.CITIES_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      var activedCities = data.cities.filter((r:any)=>r.Actived == true);
      store.dispatch(ReduxActions.regions.setCities(activedCities));
      UploadDone()
    },
  });

  const [RefreshCategories] = useLazyQuery(graphql.querues.CATEGORIES_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      var precalculatedData = CalculateCounters(data.categories)
      store.dispatch(ReduxActions.categories.setCategories(precalculatedData));
      UploadDone()
    },
  });


  const [RefreshMarks] = useLazyQuery(graphql.querues.CAR_MARKS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      store.dispatch(ReduxActions.cars.setMarks(data.marks));
      UploadDone()
    },
  });

  const [RefreshModels] = useLazyQuery(graphql.querues.CAR_MODELS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      store.dispatch(ReduxActions.cars.setModels(data.models));
      UploadDone()
    },
  });

  const [RefreshGenerations] = useLazyQuery(graphql.querues.CAR_GENERATIONS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      store.dispatch(ReduxActions.cars.setGenerations(data.carcategories));
      UploadDone()
    },
  });

  const [RefreshCarEdits] = useLazyQuery(graphql.querues.CAR_EDITS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      store.dispatch(ReduxActions.cars.setEdits(data.caredits));
      UploadDone()
    },
  });

  const [RefreshNews] = useLazyQuery(graphql.querues.NEWS_QUERY, {
    variables:{Actived:true, startKey:"2022-08-02", itemsCount:10},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      store.dispatch(ReduxActions.news.setNews(data.news));
      UploadDone()
    },
  });

  const [RefreshLocations] = useLazyQuery(graphql.querues.LOCATIONS_QUERY, {
    variables:{Actived:true, startKey:"2022-08-02", itemsCount:10},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      store.dispatch(ReduxActions.locations.setLocations(data.locations));
      UploadDone()
    },
  });

  const [RefreshAds] = useLazyQuery(graphql.querues.ADS_QUERY, {
    variables:{Actived:true, startKey:"2022-08-02", itemsCount:30},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      store.dispatch(ReduxActions.ads.setAds(data.adslist.items));
      UploadDone()
    },
  });

  const [LoadUserAccount] = useLazyQuery(graphql.querues.USER_QUERY,
  {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted:(data)=>
      {
          if (data.userbytoken)
          {
              var userdata = data.userbytoken;
              store.dispatch(ReduxActions.users.setUser(userdata));
              if (UserRestore) UserRestore(new User(userdata));
          } else
          {
            // пользовавтель не авторизован, надо удалить все устарвшие данные
            if (UserUnlogged) UserUnlogged()
          }
      }
  });    
  
  const UploadDone = () => {
    uploadDone++;
    console.log("uploadDone", uploadDone);
    if (uploadDone == 13) {
      if (done) {
        done();
      }
    }
  }

  useEffect(() => {
    // пробую загрузить данные по пользователю, если он авторизован
    LoadUserAccount();

    RefreshCurrencies();
    RefreshAds();
    RefreshNews();
    RefreshLocations();
    RefreshCountries();
    RefreshRegions();
    RefreshCities();
    RefreshCategories();
    RefreshMarks();
    RefreshModels();
    RefreshGenerations();
    RefreshCarEdits();
    RefreshFavourites();
  }, []);

  return <></>
};


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
export { DataLoader}
