import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector, useStore } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import { AppContext } from "../App";
import { CategoryItem } from "../models/Category";
import { GetByLanguage } from "./langs/languages";
import graphql from "../gql/gqlqueries";
//import { store } from "react-notifications-component"

interface CategoryCardInterface {
  title: string;
  categoryId: string;
  childs?: CategoryItem[];
  lang: string;
  icon: string[];
  itemsCount: number;
  onClick?: () => void;
}

const CategoryCard = (props: CategoryCardInterface) => {
  var link = "/ads/category/" + props.categoryId.toString();

  return (
    <div className="category-box-layout2">
      <Link to={link} className="item-heading-area">
        <h3 className="item-title text-truncate">{props.title}</h3>
        <div className="item-count">0 Ads</div>
      </Link>
      {props.childs != undefined ? (
        <ul className="item-sub-category">
          {props.childs.map((item, index) => {
            return (
              <li key={1000 + index}>
                <Link to={"/ads/category/" + item.id}>
                  {props.lang == "ru" ? item.title_ru : item.title_eng}
                </Link>
              </li>
            );
          })}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

const CategoryCard2 = (props: CategoryCardInterface) => {
  var context = useContext(AppContext);
  var link = "/ads/category/" + props.categoryId.toString();

  return (
    <div className="category-box-layout2" onClick={props?.onClick}>
      <Link
        to={link}
        className="item-heading-area d-flex justify-content-start align-items-center"
        style={{ padding: 5 }}
      >
        {props.icon ? (
          <img
            alt=""
            src={`https://avgu.com/media/category/${props.categoryId}/${props.icon[0]}`}
            style={{ width: 60, height: 60, display: "block" }}
          />
        ) : (
          <></>
        )}
        <div style={{ width: "100%", padding: 10 }}>
          <h3 className="item-title text-truncate" style={{ maxWidth: 180 }}>
            {props.title}
          </h3>
          <div className="item-count">
            {props.itemsCount} {context.langdata["Ads"]}
          </div>
        </div>
      </Link>
    </div>
  );
};

interface CategoriesInterface {
  OnCategoryChange?: (category: CategoryItem) => void;
}

var lastCategories: any = null;
const Categories = (props: CategoriesInterface) => {
  var context = useContext(AppContext);
  var categories = useSelector((state: any) => state.categories);

  var [categories1, setCatecories1] = useState<CategoryItem[]>();
  var [categories2, setCatecories2] = useState<CategoryItem[]>();
  var browserlocation = useLocation();

  useEffect(() => {
    var cats1: CategoryItem[] = [];
    var cats2: CategoryItem[] = [];

    setCatecories1(cats1);
    setCatecories2(cats2);

    console.log(categories);
    categories
      ?.filter((c: CategoryItem) => c.parent == null)
      .map((item: CategoryItem, index: number) => {
        if (index % 2 == 0) {
          cats1?.push(item);
        } else {
          cats2?.push(item);
        }
      });

    setCatecories1(cats1);
    setCatecories2(cats2);
  }, [categories, browserlocation.pathname]);

  useEffect(() => {
    if ((categories2?.length ?? 0) > 0) {
      var ev = new Event("update_carousels_cats", { bubbles: true });
      document.dispatchEvent(ev);
    }
  }, [categories2]);

  const OnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.OnCategoryChange && event.currentTarget) {
      //props.OnCategoryChange(category)
    }
  };

  return (
    <section className="section-padding-top-heading bg-accent">
      <div className="container">
        <div className="flex-heading-layout1">
          <div className="heading-layout2">
            <h2 className="heading-title">
              {context.langdata["Ad Categories"]}
            </h2>
          </div>
          <div id="owl-nav1" className="smart-nav-layout1 mg-t-5">
            <span className="rt-prev">
              <i className="fas fa-angle-left"></i>
            </span>
            <span className="rt-next">
              <i className="fas fa-angle-right"></i>
            </span>
          </div>
        </div>
        <div
          className="rc-carousel categories_carousels"
          data-loop="true"
          data-items="10"
          data-margin="5"
          data-custom-nav="#owl-nav1"
          data-autoplay="true"
          data-autoplay-timeout="3000"
          data-smart-speed="1000"
          data-dots="false"
          data-nav="false"
          data-nav-speed="false"
          data-r-x-small="1"
          data-r-x-small-nav="false"
          data-r-x-small-dots="false"
          data-r-x-medium="2"
          data-r-x-medium-nav="false"
          data-r-x-medium-dots="false"
          data-r-small="3"
          data-r-small-nav="false"
          data-r-small-dots="false"
          data-r-medium="3"
          data-r-medium-nav="false"
          data-r-medium-dots="false"
          data-r-large="4"
          data-r-large-nav="false"
          data-r-large-dots="false"
          data-r-extra-large="4"
          data-r-extra-large-nav="false"
          data-r-extra-large-dots="false"
        >
          {categories1?.map((item: CategoryItem, index: number) => {
            var childs = categories?.filter((i) => i.parent == item.id);
            return (
              <CategoryCard2
                key={"row1_" + index}
                itemsCount={item.itemsCount + item.inChildItemsCount}
                categoryId={item.id}
                lang={context.lang}
                icon={item.icon}
                childs={childs}
                title={GetByLanguage(item, context.lang) ?? ""}
              ></CategoryCard2>
            );
          })}
        </div>

        <div
          className="rc-carousel categories_carousels"
          style={{ marginTop: 5 }}
          data-loop="true"
          data-items="10"
          data-margin="5"
          data-custom-nav="#owl-nav2"
          data-autoplay="true"
          data-autoplay-timeout="3000"
          data-smart-speed="1000"
          data-dots="false"
          data-nav="false"
          data-nav-speed="false"
          data-r-x-small="1"
          data-r-x-small-nav="false"
          data-r-x-small-dots="false"
          data-r-x-medium="2"
          data-r-x-medium-nav="false"
          data-r-x-medium-dots="false"
          data-r-small="3"
          data-r-small-nav="false"
          data-r-small-dots="false"
          data-r-medium="3"
          data-r-medium-nav="false"
          data-r-medium-dots="false"
          data-r-large="4"
          data-r-large-nav="false"
          data-r-large-dots="false"
          data-r-extra-large="4"
          data-r-extra-large-nav="false"
          data-r-extra-large-dots="false"
        >
          {categories2?.map((item: CategoryItem, index: number) => {
            var childs = categories?.filter((i) => i.parent == item.id);
            return (
              <CategoryCard2
                key={"row2_" + index}
                itemsCount={item.itemsCount + item.inChildItemsCount}
                categoryId={item.id}
                lang={context.lang}
                childs={childs}
                icon={item.icon}
                title={GetByLanguage(item, context.lang) ?? ""}
              ></CategoryCard2>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Categories;
