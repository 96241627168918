import { ReducerAction } from "./reducers"

const LocationsCommands = {
    GET_NEWS: 'GET_NEWS',
    ADD_NEW: 'ADD_NEW',
    REMOVE_NEW: 'REMOVE_NEW',
    LOCATIONS_LOADED: 'LOCATIONS_LOADED'
}

export const places = (state = [], action:ReducerAction) => {
    switch (action.type) {
        case LocationsCommands.GET_NEWS:
            return action.params
        case LocationsCommands.ADD_NEW:    
            return action.params
        case LocationsCommands.REMOVE_NEW:
            return state.filter((ad:any) => ad.id !== action.params.id)
        case LocationsCommands.LOCATIONS_LOADED:
            return action.params ?? state
        default:
            return state
    }
}

export {LocationsCommands}