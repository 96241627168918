

const MailBox = () => {

    return  <div className="modal fade author-mail-box" id="author-mail" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form action="#">
                    <div className="form-group">
                        <input type="text" className="form-control" name="name" id="name" placeholder="Name*" />
                    </div>
                    <div className="form-group">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Email*" />
                    </div>
                    <div className="form-group">
                        <textarea className="form-control textarea" placeholder="Message*" name="message" id="form-message"  style={{width:"100%"}} rows={5}></textarea>
                    </div>
                    <div className="form-group">
                        <button type="submit">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

}

export default MailBox