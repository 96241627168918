import { AdItem } from "./AdItem";
import { Store } from "./Store";

class User {
  id: string = "";
  f: string = "";
  i: string = "";
  o: string = "";
  email: string = "";
  phone: string = "";
  whatsapp: string = "";
  telegram: string = "";
  website: string = "";
  postalcode: string = "";
  private account: String = "";
  private avatarUrl: String = "";
  address: string = "";
  active: Boolean = false;
  adsCount: number = 0;
  transCount: number = 0;
  storeItemsCount: number = 0;
  soldGoodsCount: number = 0;
  mkdate: string = "";
  registerDateTime: string = "";
  lastVisitDateTime: string = "";
  avatar: string = "";
  shopbanner: string = "";
  shoplogo: string = "";
  store: Store | null = null;

  colDsCount: number = 0;
  colDealsCount: number = 0;
  goodsCount: number = 0;
  reviewsCount: number = 0;

  constructor(data?: any) {
    this.id = data?.id ?? "";
    this.account = data?.account ?? "";
    this.f = data?.UserF ?? "";
    this.i = data?.UserI ?? "";
    this.email = data?.email ?? "";
    this.phone = data?.phone ?? "";
    this.postalcode = data?.postalcode ?? "";
    this.address = data?.address ?? "";
    this.whatsapp = data?.whatsapp ?? "";
    this.telegram = data?.telegram ?? "";
    this.website = data?.website ?? "";
    this.active = data?.Actived ?? false;
    this.mkdate = data?.createdAt ?? "";
    this.avatar = data?.avatar ?? "";
    this.shopbanner = data?.shopbanner ?? "";
    this.shoplogo = data?.shoplogo ?? "";
    this.registerDateTime = data?.registerDateTime ?? "";
    this.lastVisitDateTime = data?.lastVisitDateTime ?? "";
    this.adsCount = data?.adsCount ?? 0;
    this.colDsCount = data?.colDsCount ?? 0;
    this.colDealsCount = data?.colDealsCount ?? 0;
    this.goodsCount = data?.goodsCount ?? 0;
    this.reviewsCount = data?.reviewsCount ?? 0;
  }

  Account(): string {
    return this.account?.toString() ?? "";
  }

  FullFIO(): String {
    return `${this.f} ${this.i} ${this.o}`;
  }

  HiddenPhone(): String {
    return this.phone.substring(0, 7) + "XXXXXXX";
  }

  public static TestUser(): User {
    let user = new User();
    user.f = "Кушнир";
    user.i = "Сергей";
    user.o = "Викторович";
    user.email = "mail@mail.com";
    user.phone = "+7 (922) 123-123-123";
    user.account = "TestUser";
    user.avatarUrl = "";
    user.address = "г. Москва, ул. Ворошилова, д. 123, кв. 56";
    return user;
  }
}

type AdFavorites = {
  id: string;
  ad: AdItem;
};

export { User };
export type { AdFavorites };
