import { useLazyQuery, useMutation, gql } from "@apollo/client";

const LOGIN_QUERY = gql`
  query Login($username: String!, $password: String!) {
    login(user: $username, password: $password) {
      tocken
      error
      status
      profile {
        id
        avatar
        shopbanner
        shoplogo
        account
        UserF
        UserI
        email
        whatsapp
        telegram
        website
        phone
        likes
        dislikes
        views
        address
        postalcode
        Actived
      }
    }
  }
`;

const STATS_QUERY = gql`
  query Stats {
    stat {
      usersCount
      usersVerified
      newusersCount
      shopsCount
      adsCount
      dealsCount
    }
  }
`;

const USERDATA_MUT = gql`
  mutation UpdateUserProfile(
    $id: String!
    $account: String!
    $phone: String!
    $email: String!
    $address: String
    $postalcode: String
    $UserI: String
    $UserF: String
    $whatsapp: String
    $telegram: String
    $website: String
  ) {
    updateUserProfile(
      id: $id
      account: $account
      email: $email
      phone: $phone
      address: $address
      postalcode: $postalcode
      firstname: $UserI
      lastname: $UserF
      whatsup: $whatsapp
      telegram: $telegram
      website: $website
    ) {
      id
      avatar
      account
      UserF
      UserI
      email
      whatsapp
      telegram
      website
      phone
      likes
      dislikes
      views
      address
      postalcode
    }
  }
`;

const SHOPDATA_MUT = gql`
  mutation UpdateUserStore(
    $id: String!
    $userId: String!
    $name: String!
    $email1: String
    $phone1: String
    $email2: String
    $phone2: String
    $slogan: String
    $description: String
    $address: String
    $facebook: String
    $vk: String
    $twitter: String
    $youtube: String
    $telegram: String
    $whatsup: String
    $website: String
    $workmode: String
  ) {
    updateUserStore(
      id: $id
      userId: $userId
      name: $name
      email1: $email1
      email2: $email2
      phone1: $phone1
      phone2: $phone2
      slogan: $slogan
      website: $website
      description: $description
      address: $address
      facebook: $facebook
      vk: $vk
      twitter: $twitter
      youtube: $youtube
      telegram: $telegram
      whatsup: $whatsup
      workmode: $workmode
    ) {
      id
      name
    }
  }
`;

/*
$id: String, $userId: String!, $category: String!, 
$title_ru: String, $title_eng: String, $title_tur: String, $title_greek: String, $title_deutsch: String, 
$costtype: String, $cost: Float, $state: String, $brand: String, 
$authenticity: String,
$description_ru: String, $description_eng: String, $description_tur: String, $description_greek: String, $description_deutsch: String, 
$features_ru: String, $features_eng: String, $features_tur: String, $features_greek: String, $features_deutsch: String, 
$address: String, $adowner: String, $proptype: String, $propstate: String, $level: Int, $roomsCount: Int, $balcony: Boolean, 
$loggia: Boolean, $wardrobe: Boolean, $panoramicwindows: Boolean, $totalarea: Float, $livingspace: Float, $ceilingheight: Float, 
$bathroom: String, $windowstothecourtyard: Boolean, $windowstothestreet: Boolean, $windowsonthesunnyside: Boolean, $kitchen: Boolean,
$clothesstorage: Boolean, $sleepingplaces: Boolean, $airconditioner: Boolean, $fridge: Boolean, $washingmachine: Boolean, $dishwasher: Boolean, $waterheater: Boolean,
$repair: String, $homeType: String, $concierge: Boolean, $garbagechute: Boolean, $gas: Boolean, $makeYear: Int, $levelsInHome: Int, $passengerelevator: Int, $servicelift: Int,
$yardclosedarea: Boolean, $yardplayground: Boolean, $yardsportsground: Boolean, $parkingunderground: Boolean, $parkinggroundmultilevel: Boolean, $parkingopenintheyard: Boolean,
$parkingbehindthegateintheyard: Boolean,
$caradtype: String, $color: String, $video: String, $mileage: Float, $carstate: String, $vin: String, $carnumber: String, $marka: String, $model: String, $generation: String,
$yearofissue: Int, $cusov: String, $engine: String, $driveunit: String, $transmission: String, $rudderposition: String, 
$country: String, $region: String, $city: String, $postalcode: String, $contactaddress: String, $phones: String, $whatsapp: String, $email: String, 
$location: String, $locationLong: Float, $locationAlt: Float, $dontshowmap: Boolean,
$currency: String


addata:{id: $id, userId: $userId, category: $category, 
      country: $country, region: $region, city: $city, postalcode: $postalcode, contactaddress: $contactaddress, phones: $phones, whatsapp: $whatsapp, email: $email, 
      location: $location, locationLong: $locationLong, locationAlt: $locationAlt, dontshowmap: $dontshowmap, currency: $currency,
      title_ru: $title_ru, title_eng: $title_eng, title_tur: $title_tur, title_greek: $title_greek, title_deutsch: $title_deutsch, 
      costtype: $costtype, cost: $cost, state: $state, brand: $brand, authenticity: $authenticity,
      description_ru: $description_ru, description_eng: $description_eng, description_tur: $description_tur, description_greek: $description_greek, description_deutsch: $description_deutsch, 
      features_ru: $features_ru, features_eng: $features_eng, features_tur: $features_tur, features_greek: $features_greek, features_deutsch: $features_deutsch, 
      address: $address, adowner: $adowner}, 
      propdata: {proptype: $proptype, propstate: $propstate, level: $level, roomsCount: $roomsCount, balcony: $balcony, 
      loggia: $loggia, wardrobe: $wardrobe, panoramicwindows: $panoramicwindows, totalarea: $totalarea, livingspace: $livingspace, ceilingheight: $ceilingheight, 
      bathroom: $bathroom, windowstothecourtyard: $windowstothecourtyard, windowstothestreet: $windowstothestreet, windowsonthesunnyside: $windowsonthesunnyside, kitchen: $kitchen,
      clothesstorage: $clothesstorage, sleepingplaces: $sleepingplaces, airconditioner: $airconditioner, fridge: $fridge, washingmachine: $washingmachine, dishwasher: $dishwasher, waterheater: $waterheater,
      repair: $repair, homeType: $homeType, concierge: $concierge, garbagechute: $garbagechute, gas: $gas, makeYear: $makeYear, levelsInHome: $levelsInHome, passengerelevator: $passengerelevator, servicelift: $servicelift,
      yardclosedarea: $yardclosedarea, yardplayground: $yardplayground, yardsportsground: $yardsportsground, parkingunderground: $parkingunderground, parkinggroundmultilevel: $parkinggroundmultilevel, parkingopenintheyard: $parkingopenintheyard,
      parkingbehindthegateintheyard: $parkingbehindthegateintheyard},
      cardata: {caradtype: $caradtype, color: $color, video: $video, mileage: $mileage, carstate: $carstate, vin: $vin, carnumber: $carnumber, marka: $marka, model: $model, generation: $generation,
      yearofissue: $yearofissue, cusov: $cusov, engine: $engine, driveunit: $driveunit, transmission: $transmission, rudderposition: $rudderposition}
*/
const AD_CREATE = gql`
  mutation CreateAd(
    $addata: AdInput
    $propdata: PropertyDetailInput
    $cardata: CarDetailInput
    $draft: Boolean
  ) {
    createOrUpdateAd(
      addata: $addata
      propdata: $propdata
      cardata: $cardata
      draft: $draft
    ) {
      id
    }
  }
`;

const DROP_AD = gql`
  mutation DropAd($id: String!) {
    dropAd(id: $id)
  }
`;

const PUBLISH_AD = gql`
  mutation PublishAd($id: String!, $publish: Boolean!) {
    publishAd(id: $id, publish: $publish)
  }
`;

const AD_SUBSCRIBE = gql`
  mutation SubscribeAd($id: String!, $newState: Boolean!) {
    subscribeAd(id: $id, newState: $newState)
  }
`;

const UPLOAD_TEMP_FILES = gql`
  mutation UploadTempFiles($id: String!, $files: [Upload]!) {
    uploadFiles(id: $id, files: $files, temp: true) {
      path
      filename
      mimetype
      encoding
      error
    }
  }
`;

const UPLOAD_USER_FILE = gql`
  mutation UploadUserFile(
    $file: Upload!
    $savefilename: String
    $width: Float
    $height: Float
  ) {
    uploadUserFile(
      file: $file
      savefilename: $savefilename
      width: $width
      height: $height
    ) {
      path
      filename
      mimetype
      encoding
      error
    }
  }
`;

const REMOVE_FILE = gql`
  mutation RemoveFile($filename: String!) {
    removeFile(filename: $filename)
  }
`;

const CURRENCIES_QUERY = gql`
  mutation Currencies {
    getcurrencies {
      name
      value
      registerDateTime
    }
  }
`;

const TEMP_FILES = gql`
  query TempFiles {
    tempFiles
  }
`;

const AD_VIEW_ADD = gql`
  mutation AddAdView($id: String!) {
    addAdView(id: $id)
  }
`;

const REGISTER_QUERY = gql`
  mutation TryRegister(
    $username: String!
    $phone: String!
    $email: String!
    $password: String!
  ) {
    registerNewUserProfile(
      UserI: $username
      email: $email
      phone: $phone
      password: $password
    ) {
      id
    }
  }
`;

const ADD_REVIEW = gql`
  mutation AddReiew(
    $id: String!
    $caption: String!
    $review: String!
    $raiting: Int!
    $lang: String!
    $completed: Boolean!
  ) {
    addReview(
      id: $id
      caption: $caption
      review: $review
      raiting: $raiting
      lang: $lang
      completed: $completed
    )
  }
`;

const TOGGLE_FAVORITE = gql`
  mutation ToggleFavorite($id: String!) {
    toggleFavorite(id: $id)
  }
`;

const SHOP_QUERY = gql`
  query UserShop($userId: String!) {
    shop(userId: $userId) {
      id
      name
      banner
      likes
      dislikes
      views
      commentsCount
      logo
      website
      address
      description
      facebook
      vk
      whatsup
      telegram
      twitter
      youtube
      houers
      email1
      email2
      phone1
      phone2
      slogan
    }
  }
`;

const USER_QUERY = gql`
  query UserByToken {
    userbytoken {
      id
      avatar
      shopbanner
      shoplogo
      account
      UserF
      UserI
      email
      whatsapp
      telegram
      website
      phone
      likes
      dislikes
      views
      address
      postalcode
      registerDateTime
      lastVisitDateTime
      Actived
    }
  }
`;

const USER_QUERY_STAT = gql`
  query UserByToken {
    userbytoken {
      id
      avatar
      shopbanner
      shoplogo
      account
      UserF
      UserI
      email
      whatsapp
      telegram
      website
      phone
      likes
      dislikes
      views
      address
      postalcode
      adsCount
      colDsCount
      colDealsCount
      goodsCount
      reviewsCount
      registerDateTime
      lastVisitDateTime
      Actived
    }
  }
`;

const USER_DETAILS_QUERY = gql`
  query UserDetail($id: String!) {
    user(id: $id) {
      id
      avatar
      shopbanner
      shoplogo
      account
      UserF
      UserI
      email
      whatsapp
      telegram
      website
      phone
      likes
      dislikes
      views
      address
      postalcode
      Actived
      store {
        id
        banner
        logo
        openalways
        houers
        name
        email1
        email2
        website
        address
        slogan
        description
        vk
        gmail
        whatsup
        telegram
        twitter
        youtube
        facebook
        phone1
        phone2
        likes
        dislikes
        views
        commentsCount
      }
    }
  }
`;

const ADS_QUERY = gql`
  query AdsList(
    $startKey: DateTime
    $itemsCount: Int
    $adstype: String
    $userid: String
    $actived: Boolean
    $search: String
    $sort: String
    $owner: String
    $category: String
    $minCost: Float
    $maxCost: Float
    $country: String
    $region: String
  ) {
    adslist(
      startKey: $startKey
      itemsCount: $itemsCount
      adstype: $adstype
      userid: $userid
      actived: $actived
      search: $search
      sort: $sort
      owner: $owner
      category: $category
      minCost: $minCost
      maxCost: $maxCost
      country: $country
      region: $region
    ) {
      total
      hasNext
      lastKey
      items {
        id
        address_ru
        address_eng
        address_tur
        address_greek
        address_deutsch
        category {
          id
          parent
          title_ru
          title_eng
          title_tur
          title_greek
          title_deutsch
          weight
          autocar
          property
          form
        }
        cost
        title_ru
        title_eng
        title_tur
        title_greek
        views
        currency
        createdAt
        updatedAt
        photos
        Actived
        draft
        country {
          id
          Country_ru
          Country_uk
          Country_eng
          Country_tur
          Country_greek
          Country_deutsch
          Country_code
        }
        region {
          id
          Region_ru
          Region_uk
          Region_eng
          Region_tur
          Region_greek
          Region_deutsch
        }
        city {
          id
          City_ru
          City_uk
          City_eng
          City_tur
          City_greek
          City_deutsch
        }
        user {
          id
          avatar
          account
          UserF
          UserI
          UserO
          phone
        }
      }
    }
  }
`;

const FAVORITES_ADS_QUERY = gql`
  query MyFavorites {
    favorites {
      id
      ad {
        id
        cost
        title_ru
        title_eng
        title_tur
        title_greek
        views
        currency
        createdAt
        updatedAt
        photos
        Actived
        draft
      }
    }
  }
`;

const AD_QUERY = gql`
  query AdItem($id: String!) {
    ad(id: $id) {
      id
      currency
      costtype
      cost
      state
      brand
      authenticity
      adowner
      title_ru
      title_eng
      title_tur
      title_greek
      title_deutsch
      description_ru
      description_eng
      description_tur
      description_greek
      description_deutsch
      category {
        id
        parent
        title_ru
        title_eng
        title_tur
        title_greek
        title_deutsch
        weight
        autocar
        property
        form
      }
      user {
        id
        avatar
        account
        UserF
        UserI
        UserO
        shopbanner
        shoplogo
      }
      category {
        id
      }
      features_ru
      features_eng
      features_tur
      features_greek
      features_deutsch
      proptype
      propstate
      level
      roomsCount
      totalarea
      livingspace
      ceilingheight
      balcony
      loggia
      wardrobe
      panoramicwindows
      bathroom
      windowstothecourtyard
      windowstothestreet
      windowsonthesunnyside
      kitchen
      clothesstorage
      sleepingplaces
      airconditioner
      fridge
      washingmachine
      dishwasher
      waterheater
      repair
      homeType
      concierge
      garbagechute
      gas
      makeYear
      levelsInHome
      passengerelevator
      servicelift
      yardclosedarea
      yardplayground
      yardsportsground
      parkingunderground
      parkinggroundmultilevel
      parkingopenintheyard
      parkingbehindthegateintheyard
      address_ru
      address_eng
      address_tur
      address_greek
      address_deutsch
      marka {
        id
      }
      model {
        id
      }
      generation {
        id
      }
      caradtype
      video
      carstate
      color {
        id
      }
      cusov {
        id
      }
      engine {
        id
      }
      driveunit {
        id
      }
      transmission {
        id
      }
      rudderposition
      mileage
      vin
      carnumber
      yearofissue
      country {
        id
      }
      region {
        id
      }
      city {
        id
      }
      postalcode
      phones
      whatsapp
      email
      contactaddress_ru
      contactaddress_eng
      contactaddress_tur
      contactaddress_greek
      contactaddress_deutsch
      location
      locationLong
      locationAlt
      dontshowmap
      photos
      views
      createdAt
      updatedAt
      Actived
      recalculatedcost
      reviews {
        id
        owner {
          id
          account
          avatar
          UserF
          UserI
          UserO
        }
        review_ru
        review_eng
        review_tur
        review_greek
        review_deutsch
        createdAt
        updatedAt
        healthy
        dealCompleted
        completedbySeller
      }
    }
  }
`;

const CATEGORIES_QUERY = gql`
  query Categories($parentId: String) {
    categories(parentId: $parentId) {
      id
      title_ru
      title_eng
      title_tur
      weight
      parent
      autocar
      property
      form
      Actived
      icon
      itemsCount
    }
  }
`;

const REGIONS_QUERY = gql`
  query Regions {
    regions {
      id
      Country {
        id
      }
      Region_ru
      Region_eng
      Region_tur
      Region_greek
      Region_deutsch
      Actived
    }
  }
`;

const COUNTRIES_QUERY = gql`
  query Countries {
    countries {
      id
      Country_ru
      Country_eng
      Country_tur
      Country_greek
      Country_deutsch
      Country_code
      Actived
    }
  }
`;

const CITIES_QUERY = gql`
  query Cities {
    cities {
      id
      Country {
        id
      }
      Region {
        id
      }
      City_ru
      City_eng
      City_tur
      City_greek
      City_deutsch
      Actived
    }
  }
`;

const REVIEWS_QUERY = gql`
  query News($startKey: DateTime, $itemsCount: Int, $Actived: Boolean) {
    news(startKey: $startKey, itemsCount: $itemsCount, Actived: $Actived) {
      id
      title_ru
      title_eng
      title_tur
      title_greek
      title_deutsch
      content_ru
      content_eng
      content_tur
      content_greek
      content_deutsch
      Actived
      createdAt
      updatedAt
      views
      category {
        id
        title_ru
        title_eng
        title_tur
        title_greek
        title_deutsch
        Actived
      }
    }
  }
`;

const NEWS_QUERY = gql`
  query News($startKey: DateTime, $itemsCount: Int, $Actived: Boolean) {
    news(startKey: $startKey, itemsCount: $itemsCount, Actived: $Actived) {
      id
      title_ru
      title_eng
      title_tur
      title_greek
      title_deutsch
      content_ru
      content_eng
      content_tur
      content_greek
      content_deutsch
      Actived
      createdAt
      updatedAt
      views
      category {
        id
        title_ru
        title_eng
        title_tur
        title_greek
        title_deutsch
        Actived
      }
    }
  }
`;

const LOCATIONS_QUERY = gql`
  query Locations($startKey: DateTime, $itemsCount: Int, $Actived: Boolean) {
    locations(startKey: $startKey, itemsCount: $itemsCount, Actived: $Actived) {
      id
      title_ru
      title_eng
      title_tur
      title_greek
      title_deutsch
      content_ru
      content_eng
      content_tur
      content_greek
      content_deutsch
      latitude
      longitude
      Actived
      createdAt
      updatedAt
      views
      category {
        id
        title_ru
        title_eng
        title_tur
        title_greek
        title_deutsch
        Actived
      }
    }
  }
`;

const CAR_MARKS = gql`
  query Marks {
    marks {
      id
      Marka_ru
      Marka_eng
      Marka_tur
      Marka_greek
      Marka_deutsch
      Actived
    }
  }
`;

const CAR_MODELS = gql`
  query Models($markaId: String) {
    models(markaId: $markaId) {
      id
      markaId
      Model_ru
      Model_eng
      Model_tur
      Model_greek
      Model_deutsch
      Actived
    }
  }
`;

const CAR_GENERATIONS = gql`
  query Carcategories($markaId: String, $modelId: String) {
    carcategories(markaId: $markaId, modelId: $modelId) {
      id
      Icon
      markaId
      modelId
      Generation_ru
      Generation_eng
      Generation_tur
      Generation_greek
      Generation_deutsch
      Cartype
      Actived
    }
  }
`;

const CAR_EDITS = gql`
  query CarEdits {
    caredits {
      CarBody {
        id
        bodytype_ru
        bodytype_uk
        bodytype_eng
        bodytype_tur
        bodytype_greek
        bodytype_deutsch
      }
      CarEngine {
        id
        engine_ru
        engine_eng
        engine_tur
        engine_greek
        engine_deutsch
      }
      CarDrive {
        id
        name_ru
        name_eng
        name_tur
        name_greek
        name_deutsch
      }
      CarTransmission {
        id
        transmission_ru
        transmission_uk
        transmission_eng
        transmission_tur
        transmission_greek
        transmission_deutsch
      }
      CarColor {
        id
        colorName_ru
        colorName_uk
        colorName_eng
        colorName_tur
        colorName_greek
        colorName_deutsch
        hexColor
      }
    }
  }
`;

const AD_SUBSCRIBESTATE = gql`
  query AdSubscribeState($adId: String) {
    subscribeState(id: $adId)
  }
`;

const querues = {
  LOGIN_QUERY,
  SHOP_QUERY,
  USER_QUERY,
  USER_DETAILS_QUERY,
  ADS_QUERY,
  CATEGORIES_QUERY,
  REGIONS_QUERY,
  FAVORITES_ADS_QUERY,
  CAR_MARKS,
  CAR_MODELS,
  CAR_GENERATIONS,
  CAR_EDITS,
  CURRENCIES_QUERY,
  COUNTRIES_QUERY,
  CITIES_QUERY,
  NEWS_QUERY,
  LOCATIONS_QUERY,
  AD_QUERY,
  REVIEWS_QUERY,
  TEMP_FILES,
  AD_SUBSCRIBESTATE,
  STATS_QUERY,
  USER_QUERY_STAT,
};
const mutations = {
  REGISTER_QUERY,
  USERDATA_MUT,
  SHOPDATA_MUT,
  AD_CREATE,
  UPLOAD_TEMP_FILES,
  REMOVE_FILE,
  UPLOAD_USER_FILE,
  DROP_AD,
  PUBLISH_AD,
  AD_SUBSCRIBE,
  TOGGLE_FAVORITE,
  AD_VIEW_ADD,
  ADD_REVIEW,
};

const graphql = { querues, mutations };

export default graphql;
