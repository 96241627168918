import React, {useContext, useState, useEffect} from "react"
import { AppContext } from "../App"
import {NewItem} from "../models/NewItem"
import { Link } from "react-router-dom"
import { GetByLanguage } from "./langs/languages"
import Moment from 'react-moment';
import moment from 'moment';

interface NewProps
{
    item:NewItem
    lang:string
}

const New = (props:NewProps) =>
{
    var imageSrc = props.item.photos ? props.item.photos[0] : "https://static.1tv.ru/uploads/video/material/splash/2022/04/01/718886/big/718886_big_cfa36ddd7b.jpg"
    return <div className="col-lg-12">
    <div className="blog-box-layout1">
        <div className="item-img">
            {/* <a href="single-blog.html"> */}
            <Link to={"/new/"+props.item?.id}><img src={imageSrc.toString()} alt={props.item?.title_eng}/></Link>
        </div>
        <div className="item-content">
            <ul className="post-meta">
                <li><Link to={"/new/"+props.item?.id}>{props.item?.category?.title_ru ?? "Категория не задана"}</Link></li>
                <li>/ <Moment format="DD.MM.YYYY HH:mm" date={props.item.createdAt}></Moment></li>
            </ul>
            <h3 className="post-title"><Link to={"/new/"+props.item?.id}>{GetByLanguage(props.item, props.lang) ?? ""}</Link></h3>
        </div>
    </div>
    </div>
}

var lastNews:any = null
const News = ()=>
{
    var context = useContext(AppContext)
    var [news, setNews]  = useState<NewItem[]>(context.dataStore?.getState()?.news ?? [])

    const StoreUpdate = () =>
    {
        var currentNews = context.dataStore.getState().news
        if (lastNews!=currentNews)
        {
            setNews(context.dataStore.getState().news);
            lastNews = currentNews
        }
    }

    useEffect(()=>
    {
        context.dataStore?.subscribe(StoreUpdate);
    }, []);

    return <section className="section-padding-top-heading bg-accent" style={{paddingTop:0, marginTop:0}}>
    <div className="container">
        <div className="flex-heading-layout1">
            <div className="heading-layout2">
            <Link to="/news" className="item-btn">
                <h2 className="heading-title">{context.langdata["Last news"]}</h2>
            </Link> 
            </div>
        </div>

        <div className="row">
            {
                news?.slice(0, 3)?.map((item, index)=>
                {
                    return <New lang={context.lang} item={item}></New>
                })
            }
        </div>

        <div className="d-flex justify-content-center">
                <div className="header-action-layout1">
                    <ul>
                        <li className="header-btn">
                            <Link to="/news" className="item-btn">{context.langdata["Open news"]}</Link>
                        </li>
                    </ul>
                </div>
            </div>
    </div>
</section>
}

export default News
