import React, {useContext, useEffect, useState, useCallback, useRef} from "react"
import { shallowEqual, useSelector, useStore } from 'react-redux'
import { Link } from "react-router-dom"
import { AppContext } from "../App"
import { AdItem, currencyType, GetCurrencyIcon, useAdsList } from "../models/AdItem"
import {AdCardSmall} from "./adcard/adcard"

import News from './news'
import Info from './info'
import Locations from './location'

function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

const CurrencyToDollar = (currency:currencyType, value:number, currencydata:any) =>
{
    if (currency == "DOLLAR")
    {
        return value
    }

    if (currency == "EURO")
    {
        return value / currencydata.EUR
    }

    if (currency == "RUBLE")
    {
        return value / currencydata.RUB
    }

    if (currency == "POUND")
    {
        return value / currencydata.GBP
    }

    if (currency == "LIRA")
    {
        return value / currencydata.TRY
    }

    return value
}

const ConvertAdCurrency = (ad:AdItem, contextcurrency:string, currencydata:any) =>
{
        var newad = {...ad}
        var dollars = CurrencyToDollar(newad.currency, newad.cost, currencydata)

        if (contextcurrency == "Euro")
        {
            newad.recalculatedcost = dollars * currencydata.EUR
        }

        if (contextcurrency == "Dollar")
        {
            newad.recalculatedcost = dollars 
        }

        if (contextcurrency == "Ruble")
        {
            newad.recalculatedcost = dollars * currencydata.RUB
        }

        if (contextcurrency == "Pound")
        {
            newad.recalculatedcost = dollars * currencydata.GBP
        }

        if (contextcurrency == "Lira")
        {
            newad.recalculatedcost = dollars * currencydata.TRY
        }

    return newad
}

var searchInt:any = null
const ProductsGrid = ({search, country, category}:{search:string, country:string, category:string}) => {

    const [AdsFilter, SetAdsFilter] = useState("All ads")
    const [adsList, setadsList] = useState<AdItem[]>([])
    const [preparedadsList, setPreparedadsList] = useState<AdItem[]>([])
    const [scroll, setScroll] = useState(0);
    const [uploadPage, SetUploadPage] = useState(false)
    const adsFooterController = useRef<HTMLDivElement>(null);

    var currency = useSelector((state:any)=>state.currency)
    const store = useStore()

    var context = useContext(AppContext)

    const onScroll = useCallback(() => setScroll(Math.round(window.scrollY)), []);

    var adsListLoader = useAdsList((data)=>
    {
        var newList = adsList.concat(data?.adslist?.items ?? [])
        setadsList(newList)
        SetUploadPage(false)
    }, 
    (error)=>{
        console.log(`Не удалось загрузить объявления! [${error}].`)
    })

    useEffect(()=>
    {
        var date = adsList.length>0 ? adsList[adsList.length-1].createdAt : new Date()
        adsListLoader({variables:{startKey: date, itemsCount: 27, adstype: AdsFilter, actived: true, search: search, category:category, country:country, adsType: "All ads"}})
    }, [])

    useEffect(()=>
    {
        onScroll()
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [onScroll])

    useEffect(()=>{
        var date = new Date()
        setadsList([])
        adsListLoader({variables:{startKey: date, itemsCount: 27, adstype: AdsFilter, actived: true, search: search, category:category, country:country, adsType: "All ads"}})
    }, [country, category])

    useEffect(()=>{
        clearInterval(searchInt)
        searchInt = setTimeout(()=>
        {
            var date = new Date()
            setadsList([])
            adsListLoader({variables:{startKey: date, itemsCount: 27, adstype: AdsFilter, actived: true, search: search, category:category, country:country, adsType: "All ads"}})
        }, 400)
        return ()=>{
            clearInterval(searchInt)
        }
    }, [search])


    useEffect(()=>{
        if (adsFooterController.current && uploadPage == false)
        {
            var bodyRect = document.body.getBoundingClientRect()
            var rect = adsFooterController.current.getBoundingClientRect()
            var offset = rect.top - bodyRect.top;

            if ((offset-scroll)<window.screen.height)
            {
                SetUploadPage(true)
                var date = adsList.length>0 ? adsList[adsList.length-1].createdAt : new Date()
                adsListLoader({variables:{startKey: date, itemsCount: 27, adstype: AdsFilter, actived: true, search: search, category:category, country:country, adsType: "All ads"}})
            }
        }
    }, [scroll])

    useEffect(()=>
    {
        //Dollar, Euro, Ruble, Pound, Lira
        adsList.forEach((ad:AdItem)=>
        {
            var newad = ConvertAdCurrency(ad, context.currency, currency)
            ad.recalculatedcost = newad.recalculatedcost
        })

        setPreparedadsList([...adsList])

    }, [currency, context.currency, adsList])
    
    const UploadAds = (newfilter:string) =>
    {
        adsListLoader({variables:{startKey: new Date(), itemsCount: 27, adstype: newfilter, actived: true, search: search, category:category, country:country, adsType: "All ads"}})
        SetAdsFilter(newfilter)
    }
 
    return <section className="section-padding-top-heading bg-accent" style={{paddingTop:0}}>
        <div className="container">
            <div className="row">
                <div className="col-xl-9 col-lg-10 sidebar-break-md sidebar-widget-area">
                    <div className="heading-layout2 d-flex align-items-center">
                            <Link to="/ads/last" className="item-btn">
                                <h2 className="heading-title">{context.langdata["Recent announcements"]}</h2>
                            </Link>
                            <span className="mx-2 item-btn">|</span>
                            <Link to="/ads" className="item-btn">
                                <h2 className="heading-title">{context.langdata["Open the ad catalog"]}</h2>
                            </Link>
                    </div>
                    <div className="isotope-wrap">
                        {/*
                            <div className="text-center">
                                <div className="isotope-classes-tab isotop-fill-btn">
                                    <a className={AdsFilter == "All ads" ? "current nav-item" : "nav-item"} data-filter="*" onClick={()=>UploadAds("All ads")}>{context.langdata["All"]}</a>
                                    <a className={AdsFilter == "Recommended ads" ? "current nav-item" : "nav-item"} data-filter=".featured" onClick={()=>UploadAds("Recommended ads")}>{context.langdata["Recommended"]}</a>
                                    <a className={AdsFilter == "New ads" ? "current nav-item" : "nav-item"} data-filter=".new" onClick={()=>UploadAds("New ads")}>{context.langdata["New advs"]}</a>
                                    <a className={AdsFilter == "Popular ads" ? "current nav-item" : "nav-item"} data-filter=".popular" onClick={()=>UploadAds("Popular ads")}>{context.langdata["Popular advs"]}</a>
                                </div>
                            </div>
                        */}
                        <div className="row featuredContainer">
                            {preparedadsList?.map((item, itemIndex)=> {
                                return <AdCardSmall key={"ad_"+itemIndex} item={item}></AdCardSmall>    
                            })}
                            <div ref={adsFooterController} className="w-100 d-block" style={{height:1}}></div>
                            
                            {uploadPage == true ? <div className="w-100 text-center">...</div> : <></>}
                        </div>
                    </div>

                   {/*
                    <div className="d-flex justify-content-center">
                        <div className="header-action-layout1">
                            <ul>
                                <li className="header-btn">
                                    <Link to="/ads" className="item-btn">{context.langdata['Open the ad catalog']}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    */}
                </div>

                <div className="col-xl-3 col-lg-2 sidebar-break-md sidebar-widget-area">
                    <Info></Info>
                    <News></News>
                    <Locations></Locations>
                </div>
            </div>
        </div>
    </section>
}

export default ProductsGrid
export {ConvertAdCurrency, CurrencyToDollar, currencyFormat}