import React, {Fragment, useContext, useEffect, useState} from "react"
import {useSelector} from "react-redux"
import { AppContext } from "../App"
import { CategoryItem } from "../models/Category";
import { RegionItem,CountryItem,CityItem } from "../models/Region";
import {GetByLanguage} from "../bb/langs/languages";

let currentValue:any = null

interface IBanners
{
    search?:string
    country?:string
    category?:string
    onlySearch?:boolean
    OnSearchChange?:(search:string)=>void
    OnCategoryChange?:(category:CategoryItem | undefined)=>void
    OnCountryChange?:(cuntry:CountryItem)=>void
}

const Banners = (props:IBanners) => {

    var context = useContext(AppContext)
    const locationCountry = useSelector((state: any) => state.regions.ActivedCountry)
    const Countries = useSelector((state: any) => state.regions.Countries)
    const Categories = useSelector((state: any) => state.categories)

    var [categories, setCatecories] = useState<[CategoryItem]>()
    var [countries, setCountires] = useState<[CountryItem]>()
    var [regions, setRegions] = useState<[RegionItem]>()
    var [cities, setCities] = useState<[CityItem]>()
    var [SelectedCategory, setSelectedCategory] = useState<CategoryItem[]>([])
    var [ActivedRegion, setActivedRegion] = useState("")
    var [SearchText, setSearchText] = useState(props.search ?? "")
    var [regionModal, showRegion] = useState(false)
    
    var [country, SetActivedCountry] = useState<CountryItem | undefined>(undefined)
    var [category, SetActivedCateegory] = useState<CategoryItem | undefined>(undefined)
    

    const SetChildCategories = (cat:CategoryItem) =>
    {
        var childCats = Categories?.filter((c:CategoryItem)=>c.parent==cat.id)

        var tempcat:CategoryItem | undefined = cat
        var fullpath = [cat]
        while (tempcat!=undefined)
        {
            if (tempcat.parent)
            {
                tempcat = Categories?.filter((c:CategoryItem)=>c.id==tempcat?.parent)[0]
                if (tempcat) fullpath.push(tempcat)            
            } else {
                tempcat = undefined
            }
        }

        var fullpathreversed = fullpath.reverse()
        setSelectedCategory(fullpathreversed)
        
        setCatecories(childCats);

        if (!childCats || childCats.length == 0)
        {
            var element:unknown = document.getElementById("modal-category")
            if (element != undefined)
            {
                var dialog = (element as HTMLDivElement)
                var btn:unknown = dialog.getElementsByClassName("modal-close")[0]
                if (btn != undefined)
                {
                    (btn as HTMLButtonElement).click()
                }
            }            
        }
    }
    
    useEffect(()=>
    {
        var rootCats = Categories?.filter((c:CategoryItem)=>c.parent==null)
        setCatecories(rootCats);
    }, [Categories])

    useEffect(()=>
    {
        if (Countries && Countries.length > 0)
        {
            var countryData = Countries.find((c:CountryItem)=>c.Country_code==(props?.country || locationCountry))

            if (countryData)
            {
                if (context.lang == "ru") setActivedRegion(countryData.Country_ru)
                if (context.lang == "en") setActivedRegion(countryData.Country_eng)
                if (context.lang == "tur") setActivedRegion(countryData.Country_tur)
                if (context.lang == "greek") setActivedRegion(countryData.Country_greek)
                if (context.lang == "deutsch") setActivedRegion(countryData.Country_deutsch)
            } else {
                setActivedRegion(props?.country || locationCountry)
            }
        } else
        {
            setActivedRegion(props?.country || locationCountry)
        }
    }, [locationCountry, Countries, context.lang, props?.country])

    useEffect(()=>
    {
        if (country)
        {
            if (context.lang == "ru") setActivedRegion(country.Country_ru)
            if (context.lang == "en") setActivedRegion(country.Country_eng)
            if (context.lang == "tur") setActivedRegion(country.Country_tur)
            if (context.lang == "greek") setActivedRegion(country.Country_greek)
            if (context.lang == "deutsch") setActivedRegion(country.Country_deutsch)
        }
    }, [country])

    var fullcat = SelectedCategory?.map((cat, idx)=> {return <>{`${idx>0 ? " • " : ""}`}<a href="javascript:void(0)" className="text-dark mouse-underline" onClick={()=>{
        SetChildCategories(cat)
    }}>{GetByLanguage(cat, context.lang)}</a></>})

    useEffect(()=>{
        if (category) SetChildCategories(category)
    }, [category])

    useEffect(()=>
    {   
        if (props?.category && Categories)
        {
            SetActivedCateegory(Categories?.find((c:CategoryItem)=>c.id==props?.category))
        }
    }, [props?.category, Categories])


    return <><section className="main-banner-wrap-layout2 bg-dark-overlay-2 bg-common bg-accent" 
                data-bg-image="media/figure/topic_statistics.jpg" style={{height:90, padding:15, backgroundImage: `url(media/figure/topic_statistics.jpg)`}}>
        <div className="container ">
            <div className="main-banner-box-layout1 animated-headline">
                <h1 className="ah-headline item-title d-none">
                    <span className="ah-words-wrapper">
                        <b className="is-visible" dangerouslySetInnerHTML={{ __html: context.langdata["MainSlogan"] }}></b>
                        <b dangerouslySetInnerHTML={{ __html: context.langdata["MainSlogan"] }}></b>
                    </span>
                </h1>
                <div className="item-subtitle d-none" dangerouslySetInnerHTML={{ __html: context.langdata["MainSloganSubtitle"] }}></div>
                <div className="search-box-layout1" style={{padding:0, margin:0}}>
                    <form action="#" className="">
                        <div className="row no-gutters">
                            {props.onlySearch == undefined || props.onlySearch == false ? (<>
                            <div className="col-lg-3 form-group">
                                <div className="input-search-btn search-location" data-toggle="modal"  data-target="#modal-location">
                                    <i className="fas fa-map-marker-alt"></i>
                                    <label>{ActivedRegion == "" ? context.langdata["Select location"] : ActivedRegion}</label>
                                </div>
                            </div>
                            <div className="col-lg-3 form-group">
                                <div className="input-search-btn search-category" data-toggle="modal" data-target="#modal-category">
                                    <i className="fas fa-tags"></i>
                                    <label className="text-nowrap text-truncate">{SelectedCategory.length==0 ? context.langdata["Select Category"] : fullcat}</label>
                                </div>
                            </div>
                            <div className="col-lg-4 form-group">
                                <div className="input-search-btn search-keyword">
                                    <i className="fas fa-text-width"></i>
                                    <input type="text" className="form-control" value={SearchText} onChange={(e)=>
                                        {
                                            setSearchText(e.target.value)
                                            if (props.OnSearchChange) props.OnSearchChange(e.target.value)
                                        }} placeholder={context.langdata["Enter Keyword here"]} name="keyword" />
                                </div>
                            </div>
                            </>) :(
                                <div className="col-lg-10 form-group">
                                    <div className="input-search-btn search-keyword">
                                        <i className="fas fa-text-width"></i>
                                        <input type="text" className="form-control" value={SearchText} onChange={(e)=>
                                            {
                                                setSearchText(e.target.value)
                                                if (props.OnSearchChange) props.OnSearchChange(e.target.value)
                                            }} placeholder={context.langdata["Enter Keyword here"]} name="keyword" />
                                    </div>
                                    </div>
                            )}
                            <div className="col-lg-2 form-group">
                                <button onClick={(e)=>
                                {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    if (props?.OnSearchChange)
                                    {
                                        props?.OnSearchChange(SearchText)
                                    }
                                }} className="submit-btn"><i className="fas fa-search"></i>{context.langdata["Search"]}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    
    <div className="modal fade modal-location" id="modal-location" tabIndex={-1} role="dialog"  aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <button type="button" className="close modal-close" data-dismiss="modal" aria-label="Close">
                    <span className="fas fa-times" aria-hidden="true"></span>
                </button>
                <div className="location-list">
                    <h4 className="item-title">{context.langdata["Location"]}</h4>
                    <ul>
                        {Countries?.map((region:CountryItem, index:number) => {
                            return  <li key={index}><a href="javascript:void(0)" data-dismiss="modal" aria-label="Close" onClick={()=>
                                {
                                    SetActivedCountry(region) 
                                    if (props.OnCountryChange)
                                    {
                                        props.OnCountryChange(region)
                                    }
                                }}>{GetByLanguage(region, context.lang)}</a></li>
                        })}
                    </ul>
                </div>
            </div>
        </div>
    </div>
        
        
        <div className="modal fade modal-location" id="modal-category" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="close modal-close" data-dismiss="modal" aria-label="Close">
                        <span className="fas fa-times" aria-hidden="true"></span>
                    </button>
                    <div className="location-list">
                        <h4 className="item-title">{context.langdata["Category"]}</h4>
                        <h5 className="item-title">
                            {SelectedCategory.length>0 ?
                            <a href="javascript:void(0)" className="text-dark mouse-underline" onClick={()=>{
                                var rootCats = Categories?.filter((c:CategoryItem)=>c.parent==null)
                                setCatecories(rootCats);
                                setSelectedCategory([])
                                if (props.OnCategoryChange)
                                {
                                    props.OnCategoryChange(undefined)
                                }
                            }}>{context.langdata["All category"]}</a>: <></>}
                            {SelectedCategory.length>0 ? " • " : ""}
                            {fullcat}</h5>
                        <ul>
                            {categories?.map((category:CategoryItem, index:number) => {
                                return  <li key={index}>
                                            <a href="javascript:void(0)" onClick={()=>
                                                {
                                                    SetChildCategories(category)
                                                    if (props.OnCategoryChange)
                                                    {
                                                        props.OnCategoryChange(category)
                                                    }
                                                }}>
                                                {category.icon ? <span className="item-icon"><img src={`https://avgu.com/media/category/${category.id}/${category.icon[0]}`} alt="icon" /></span> : <></>}
                                                {GetByLanguage(category, context.lang)}</a>
                                        </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </>
}

export default Banners