import {useSelector, useDispatch, shallowEqual, useStore} from 'react-redux'

import {
    ApolloProvider, createHttpLink, ApolloClient, InMemoryCache, from, HttpLink, useLazyQuery, ApolloError, useMutation
  } from "@apollo/client"; 
import React, {useContext, useState} from "react"
import Moment from 'react-moment';
import { Link } from "react-router-dom"
import { AppContext } from "../../App"
import { AdItem, adsList, GetCurrencyIcon } from "../../models/AdItem"
import { GetByLanguage } from "../langs/languages"
import {currencyFormat} from "../productsgrid"

import graphql from "../../gql/gqlqueries"
import ReduxActions from '../../redux/reducers/actions';
import { CityItem, CountryItem, RegionItem } from '../../models/Region';

interface IAdCard
{
    item:AdItem
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
  }

/*  эта карточка сокращенная, для главной страницы */
const AdCardSmall = (props:IAdCard) =>
{
    var context = useContext(AppContext)
    
    var tempimages = ["https://amiel.club/uploads/posts/2022-03/1647628341_1-amiel-club-p-klassnie-kartinki-dlya-devochek-1.jpg",
                      "https://80.img.avito.st/640x480/12712257980.jpg",
                      "https://avatars.mds.yandex.net/i?id=dc7361b95e9b0527c543cbb558a72055_l-5878560-images-thumbs&n=27&h=384&w=480",
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaRHpiMn0-cN6zu4aLGCTEQ7bvfR_Yewb1U9ZpwmbuA6szB43zrrftdoe1cjGM1AF9xyU&usqp=CAU",
                      "https://avatars.mds.yandex.net/i?id=1f5f188c888c793b5ae8e6bd862efa47_l-5621412-images-thumbs&n=27&h=384&w=480",
                      "https://lifehacker.ru/wp-content/uploads/2020/01/1119-white-flower-2_1579261223.jpg",
                      "https://www.imagetext.ru/pics_max/images_10798.jpg",
                      "https://img1.akspic.ru/previews/0/9/1/1/7/171190/171190-nacionalnyj_park_kaziranga-atmosfera-mir-prirodnyj_landshaft-purpur-550x310.jpg",
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5J9NAvcVCNCwFtfOuYOAWO5uJ0D8RyBOhgw&usqp=CAU",
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkvDIVr6Eqv2TLUMAFDxjgHFD4YKesY2oac4yK5z_E2_rCIQWG6nDfkhJ6HQu4-SQQpNo&usqp=CAU"]

    const [imgindex, SetImgIndex] = useState(getRandomInt(0, tempimages.length))

    return <div className="col-lg-4 col-sm-6">
    <div className="product-box-layout1">
        <div className="item-img">
            <Link to={`/ad/${props.item.id}`} className={props.item.trending ? "item-trending text-center" : " text-center"}>
                {props?.item?.photos && props?.item?.photos.length>0 ? <img src={`/media/resources/${props?.item?.id}/${props?.item?.photos[0]}`} alt="Product" style={{height:200}} /> : <img src={tempimages[imgindex]} style={{height:200}} alt="Product" />}
            </Link>
        </div>
        <div className="item-content">
            <div className="item-tag"><Link to={`/ads/category/${props.item.category?.id ?? ""}`}>
                {context.lang == "ru" ? props.item.category?.title_ru ?? "" : <></>}
                {context.lang == "en" ? props.item.category?.title_eng ?? "" : <></>}
                {context.lang == "tur" ? props.item.category?.title_tur ?? "" : <></>}
                {context.lang == "greek" ? props.item.category?.title_greek ?? "" : <></>}
                {context.lang == "deutsch" ? props.item.category?.title_deutsch ?? "" : <></>}
                </Link></div>
            <h3 className="item-title" style={{height:55, overflow:"hidden", textOverflow:"ellipsis"}}><Link to={`/ad/${props.item.id}`}>{GetByLanguage(props.item, context.lang)}</Link></h3>
            <ul className="entry-meta">
                <li><i className="far fa-clock"></i><Moment format="DD.MM.YYYY hh:mm">{props.item.makedAt}</Moment></li>
                <li><i className="fas fa-map-marker-alt"></i>{GetByLanguage(props.item.country as CountryItem, context.lang)}, {GetByLanguage(props.item.region as RegionItem, context.lang)}, {GetByLanguage(props.item.city as CityItem, context.lang)}</li>
            </ul>
            <div className="item-price" style={{fontSize:16}}>
                <div>
                <span className="currency-symbol">{GetCurrencyIcon(props.item.currency)}</span>
                {currencyFormat(props.item.cost)}
                </div>
                {props.item.currency.toUpperCase() != context.currency.toUpperCase() ?
                <div style={{color:"blue"}}>
                <span className="currency-symbol">{GetCurrencyIcon(context.currency)}</span>
                {currencyFormat(props.item?.recalculatedcost ?? 0)}
                </div> : <></>}
            </div>
        </div>
    </div>
    </div>
}

interface IAdCardProps {
    item:AdItem
    view: string
    new?:boolean
    infavorites?:boolean
}

/* эта карточка для отображения на странице каталога объявлений */
const AdCard = (props: IAdCardProps) => {

    var context = useContext(AppContext)

    var tempimages = ["https://amiel.club/uploads/posts/2022-03/1647628341_1-amiel-club-p-klassnie-kartinki-dlya-devochek-1.jpg",
                      "https://80.img.avito.st/640x480/12712257980.jpg",
                      "https://avatars.mds.yandex.net/i?id=dc7361b95e9b0527c543cbb558a72055_l-5878560-images-thumbs&n=27&h=384&w=480",
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaRHpiMn0-cN6zu4aLGCTEQ7bvfR_Yewb1U9ZpwmbuA6szB43zrrftdoe1cjGM1AF9xyU&usqp=CAU",
                      "https://avatars.mds.yandex.net/i?id=1f5f188c888c793b5ae8e6bd862efa47_l-5621412-images-thumbs&n=27&h=384&w=480",
                      "https://lifehacker.ru/wp-content/uploads/2020/01/1119-white-flower-2_1579261223.jpg",
                      "https://www.imagetext.ru/pics_max/images_10798.jpg",
                      "https://img1.akspic.ru/previews/0/9/1/1/7/171190/171190-nacionalnyj_park_kaziranga-atmosfera-mir-prirodnyj_landshaft-purpur-550x310.jpg",
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5J9NAvcVCNCwFtfOuYOAWO5uJ0D8RyBOhgw&usqp=CAU",
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkvDIVr6Eqv2TLUMAFDxjgHFD4YKesY2oac4yK5z_E2_rCIQWG6nDfkhJ6HQu4-SQQpNo&usqp=CAU"]

    const [ToggleVavorite] = useMutation(graphql.mutations.TOGGLE_FAVORITE,
    {
        fetchPolicy: "network-only",
        onCompleted:(data)=>
        {
            context.dispatch(ReduxActions.favorites.ToggleFavoriteCountry(props.item?.id, data.toggleFavorite))
        },
        onError: (error) =>
        {
            
        }
    });    

    
    var [phone, setPhone] = useState<string>("")

    const ToggleFavoriteAd = (id:string) => {
        ToggleVavorite({variables:{id:id}})
    }

    return <div className="col-xl-4 col-md-6">
        {props.view == "grid" ? (
            <Link to={"/ad/"+props.item.id}>
            <div className="product-grid-view">
                <div className="grid-view-layout4">
                    <div className={props.item.toprated ? "product-box-layout1 top-rated-grid" : "product-box-layout1"}>
                        <div className="item-img">
                            <Link to={"/ad/"+props.item.id} className={props.item.trending ? "item-trending" : ""}>
                                {props?.item?.photos && props?.item?.photos.length>0  ? <img src={`/media/resources/${props?.item?.id}/${props?.item?.photos[0]}`} alt="Product" /> : <img src={tempimages[getRandomInt(0, tempimages.length)]} style={{height:200}} alt="Product" />}
                            </Link>
                        </div>
                        <div className="item-content">
                            <div className="item-tag">
                                {context.lang == "ru" ? props.item.category?.title_ru ?? "" : <></>}
                                {context.lang == "en" ? props.item.category?.title_eng ?? "" : <></>}
                                {context.lang == "tur" ? props.item.category?.title_tur ?? "" : <></>}
                                {context.lang == "greek" ? props.item.category?.title_greek ?? "" : <></>}
                                {context.lang == "deutsch" ? props.item.category?.title_deutsch ?? "" : <></>}
                            </div>
                            <h3 className="item-title">{GetByLanguage(props.item, context.lang)}{props.new ? <span>{context.langdata["New"]}</span> : <></>}</h3>
                            <div className="item-price" style={{fontSize:16}}>
                                <div className="d-block">
                                <span className="currency-symbol">{GetCurrencyIcon(props.item.currency)}</span>
                                {currencyFormat(props.item.cost)}
                                </div>
                                {props.item.currency.toUpperCase() != context.currency.toUpperCase() ?
                                <div className="d-block"  style={{color:"blue"}}>
                                <span className="currency-symbol">{GetCurrencyIcon(context.currency)}</span>
                                {currencyFormat(props.item?.recalculatedcost ?? 0)}
                                </div>
                                : <></>}
                            </div>
                            <ul className="entry-meta">
                                <li><i className="far fa-clock"></i><Moment format="DD.MM.YYYY hh:mm">{props.item.createdAt}</Moment></li>
                                <li><i className="fas fa-map-marker-alt"></i>{props.item.location}</li>
                            </ul>
                            <div className="item-action-area">
                                <div className="item-phone">
                                    <a href="javascript:void(0)" onClick={(e)=>{e.preventDefault(); setPhone(props.item.user?.phone)}} className="classima-phone-reveal not-revealed">
                                        <i className="fa fa-phone"></i>
                                        <span className="number-hidden">{phone == "" ? context.langdata["Show Phone No"] : phone}</span>
                                    </a>
                                </div>
                                <div className="item-fav">
                                    <a href="javascript:void(0)" onClick={(e)=>{e.preventDefault(); ToggleFavoriteAd(props?.item?.id)}} style={{backgroundColor: props.infavorites  ? "red" : "transparent"}}>
                                    <i className="far fa-heart" style={{color: props.infavorites  ? "white" : "darkgray"}}></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></Link>) : (
            <Link to={"/ad/"+props.item.id}>
            <div className="product-list-view">
                <div className="list-view-layout4">
                    <div className= {props.item.toprated ? "product-box-layout3 top-rated-list" : "product-box-layout3"}>
                        <div className="item-img">
                            <a href="single-product1.html" className={props.item.trending ? "item-trending" : ""}>{props?.item?.photos  ? <img src={`/media/resources/${props?.item?.id}/${props?.item?.photos[0]}`} alt="Product" /> : <></>}</a>
                        </div>
                        <div className="product-info">
                            <div className="item-content">
                            <div className="item-tag">
                                {context.lang == "ru" ? props.item.category?.title_ru ?? "" : <></>}
                                {context.lang == "en" ? props.item.category?.title_eng ?? "" : <></>}
                                {context.lang == "tur" ? props.item.category?.title_tur ?? "" : <></>}
                                {context.lang == "greek" ? props.item.category?.title_greek ?? "" : <></>}
                                {context.lang == "deutsch" ? props.item.category?.title_deutsch ?? "" : <></>}
                            </div>
                                <h3 className="item-title">{GetByLanguage(props.item, context.lang)}{props?.new ? <span>New</span> : <></>}</h3>
                                <ul className="item-condition">
                                    <li><span>Condition:</span> New</li>
                                </ul>
                                <ul className="entry-meta">
                                    <li><i className="far fa-clock"></i><Moment format="DD.MM.YYYY hh:mm">{props.item.createdAt}</Moment></li>
                                    <li><i className="fas fa-map-marker-alt"></i>{props.item.location}</li>
                                    <li><i className="far fa-eye"></i>{context.langdata["views"]}: {props.item.views}</li>
                                </ul>
                            </div>
                            <div className="item-right">
                                <div className="right-meta">
                                    <span><i className="fas fa-shopping-cart"></i>{context.langdata["Sell"]}</span>
                                </div>
                                <div className="item-price" style={{fontSize:16}}>
                                    <div className="d-block">
                                    <span className="currency-symbol">{GetCurrencyIcon(context.currency)}</span>
                                    {currencyFormat(props.item.cost)}
                                    </div>
                                    <div className="d-block"  style={{color:"blue"}}>
                                    <span className="currency-symbol">{GetCurrencyIcon(context.currency)}</span>
                                    {currencyFormat(props.item.recalculatedcost)}
                                    </div>
                                </div>
                                <div className="item-action-area">
                                    <div className="action-btn">
                                        <a href="javascript:void(0)" onClick={(e)=>{e.preventDefault(); setPhone(props.item.user?.phone)}} className="item-btn classima-phone-reveal not-revealed">
                                            <i className="fa fa-phone"></i>
                                            <span className="number-hidden">{phone == "" ? context.langdata["Show Phone No"] : phone}</span>
                                        </a>
                                    </div>
                                    <div className="action-btn">
                                        <a href="javascript:void(0)" onClick={(e)=>{e.preventDefault(); ToggleFavoriteAd(props?.item?.id)}} className="item-btn" style={{backgroundColor: props.infavorites  ? "red" : "", color: props.infavorites  ? "white" : "darkgray"}}>
                                        <i className="far fa-heart" style={{color: props.infavorites  ? "white" : "darkgray"}}></i>
                                        {context.langdata["Add to Favourites"]}</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Link>
        )}
    </div>
}

export default AdCard
export {AdCardSmall}
export type {IAdCardProps}