import { ReducerAction } from "./reducers"

const NewsCommands = {
    GET_NEWS: 'GET_NEWS',
    ADD_NEW: 'ADD_NEW',
    REMOVE_NEW: 'REMOVE_NEW',
    NEWS_LOADED: 'NEWS_LOADED'
}

export const news = (state = [], action:ReducerAction) => {
    switch (action.type) {
        case NewsCommands.GET_NEWS:
            return action.params
        case NewsCommands.ADD_NEW:    
            return action.params
        case NewsCommands.REMOVE_NEW:
            return state.filter((ad:any) => ad.id !== action.params.id)
        case NewsCommands.NEWS_LOADED:
            return action.params ?? state
        default:
            return state
    }
}

export {NewsCommands}