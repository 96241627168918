import React, { useContext, useEffect, useState } from "react";
import {
    Routes,
    Route,
    Link,
    useLocation,
} from "react-router-dom"

import { AppContext } from '../App'
import { useSelector } from 'react-redux'

import {
    ApolloProvider, createHttpLink, ApolloClient, InMemoryCache, from, HttpLink, useLazyQuery
  } from "@apollo/client"; 

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Store } from 'react-notifications-component';
import graphql from "../gql/gqlqueries";
import Products from './products'
import ProductsGrid from './productsgrid'
import Process from './process'
import Banners from "./banners";
import Header from './header'
import Footer from './footer'
import ModalsDialods from './modals'
import Categories from './categories'
import NewAdForm from './newad/newadpage'
import Account from './account/account'
import AllAds from './allads/allads'
import AdView from './adview/adview'
import ShopView from './shopview/shopview'
import ProfileView from './profileview/profileview'
import Breadcroombs from './Breadcroombs/Breadcroombs'
import {User} from '../models/User'
import {Store as AvguStore} from '../models/Store'
import useGeoLocation from "react-ipgeolocation";
import ReduxActions from "../redux/reducers/actions";
import { CountryItem } from "../models/Region";
import { CategoryItem } from "../models/Category";

interface IMainPage
{
    OnLangChange:(lang:string)=>void 
    OnCurrencyChange:(currency:string)=>void
    OnCountryChange:(country:string)=>void
    OnLogin: (user:User)=>void
    OnLogout: ()=>void
    SetUserStore: (store:AvguStore)=>void
}

const Statistics = () => {
    
    var context = useContext(AppContext)

    var [totalads, setTotalAds] = useState(0)
    var [totalusers, setTotalUsers] = useState(0)
    var [verifiedusers, setVerifiedUsers] = useState(0)

    const [Stats] = useLazyQuery(graphql.querues.STATS_QUERY, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        onCompleted: (data) => {
          var statdata = data.stat
          setTotalAds(statdata.adsCount)
          setTotalUsers(statdata.usersCount)
          setVerifiedUsers(statdata.usersVerified)
        },
      });

    useEffect(() => {
        Stats()
    }, [])

    return <section className="counter-wrap-layout1 bg-attachment-fixed" data-bg-image="media/figure/topic_statistics.jpg" style={{backgroundImage: `url(media/figure/topic_statistics.jpg)`}}>
    <div className="container">
        <div className="row">
            <div className="col-lg-4 d-flex justify-content-center">
                <div className="counter-box-layout1">
                    <div className="item-icon">
                        <i className="flaticon-gift"></i>
                    </div>
                    <div className="item-content">
                        <div className="counter-number">
                            <span className="counter">{totalads}</span>
                            <span>+</span>
                        </div>
                        <div className="item-title">{context.langdata["Published Ads"]}</div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-center">
                <div className="counter-box-layout1">
                    <div className="item-icon">
                        <i className="flaticon-users"></i>
                    </div>
                    <div className="item-content">
                        <div className="counter-number">
                            <span className="counter">{totalusers}</span>
                            <span>+</span>
                        </div>
                        <div className="item-title">{context.langdata["Registered Users"]}</div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-center">
                <div className="counter-box-layout1">
                    <div className="item-icon">
                        <i className="flaticon-shield"></i>
                    </div>
                    <div className="item-content">
                        <div className="counter-number">
                            <span className="counter">{verifiedusers}</span>
                            <span>+</span>
                        </div>
                        <div className="item-title">{context.langdata["Verified Users"]}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
}

const MainPage = (props:IMainPage) => {

    var context = useContext(AppContext)
    var browserlocation = useLocation()
    const locationCountry = useSelector((state: any) => state.regions.ActivedCountry)
    
    var [search, setSearch] = useState(localStorage.getItem("search") ?? "")
    var [country, setCountry] = useState(localStorage.getItem("country") ?? "")
    var [countrycode, setCountrycode] = useState(localStorage.getItem("countrycode") ?? "")
    var [category, setCategory] = useState(localStorage.getItem("category") ?? "")

   // var state = useState(()=>{props.OnLangChange(activeLang)})
    const location = useGeoLocation();
    var unsubscribe = null

    useEffect(()=>
    {
        document.title = context.langdata["mainpage_title"]
    }, []);

    useEffect(() => {
        context.dispatch(ReduxActions.regions.setActiveCountry(location.country))
    }, [location?.country])

    useEffect(() => {
        console.log("locationCountry", locationCountry)
    }, [locationCountry])

    const SearchChange = (text:string) => {
        localStorage.setItem("search", text)
        setSearch(text)
    }

    const CountryChange = (country:CountryItem) => {
        localStorage.setItem("countrycode", country.Country_code)
        localStorage.setItem("country", country.id)
        setCountry(country.id)
        setCountrycode(country.Country_code)
    }

    const CategoryChange = (category:CategoryItem | undefined) => {
        if (category)
        {
            localStorage.setItem("category", category?.id)
        } else 
        {
            localStorage.removeItem("category")
        }
        setCategory(category?.id ?? "")
    }

    return <div id="wrapper" className="wrapper">
        <Header OnLangChange={props.OnLangChange} OnCurrencyChange={props.OnCurrencyChange} OnCountryChange={props.OnCountryChange}></Header>
        <Routes>
            <Route path='/' element={<>
                <Banners search={search} country={countrycode} category={category} OnSearchChange={SearchChange} OnCategoryChange={CategoryChange} OnCountryChange={CountryChange}></Banners>
                <Categories OnCategoryChange={CategoryChange}></Categories>
                    <ProductsGrid search={search} country={country} category={category}></ProductsGrid>
                    {/*
                    <Statistics></Statistics>
                    */}
            </>} />

            
            <Route path='/ads/last' element={<AllAds></AllAds>} />
            <Route path='/ads/cars' element={<AllAds type='cars'></AllAds>} />
            <Route path='/ads/realty' element={<AllAds type='realty'></AllAds>} />
            <Route path='/ads/work' element={<AllAds type='work'></AllAds>} />
            <Route path='/ads/services' element={<AllAds type='services'></AllAds>} />
            <Route path='/ads/category/:category' element={<AllAds></AllAds>} />
            <Route path='/ads' element={<AllAds></AllAds>} />

            <Route path='/account' element={<>
                <Breadcroombs title={context.langdata['My Account']}></Breadcroombs>
                <Account OnLogin={props.OnLogin} OnLogout={props.OnLogout} SetUserStore={props.SetUserStore}></Account>
            </>} />

            <Route path='/contact' element={<>
                <Breadcroombs title={context.langdata['Contacts']}></Breadcroombs>
            </>} />

            <Route path='/newad' element={<>
                <NewAdForm editad={false}></NewAdForm>
            </>} />

            <Route path='/editad/:adid' element={<>
                <NewAdForm editad={true}></NewAdForm>
            </>} />

            <Route path='/ad/:adid' element={<>
                <AdView></AdView>
            </>} />

            <Route path='/shop/:userid' element={<>
                <ShopView></ShopView>
            </>} />

            <Route path='/profile/:adid' element={<>
                <ProfileView></ProfileView>
            </>} />
        </Routes>
        <Footer></Footer>
        
    </div>
}


export default MainPage