import { ReducerAction } from "./reducers"

const CategoriesCommands = {
    CATEGORIES_LOADED: 'CATEGORIES_LOADED',
}

export const categories = (state = null, action:ReducerAction) => {
    switch (action.type) {
        case CategoriesCommands.CATEGORIES_LOADED:
            return action.params
        default:
            return state
    }
}

export {CategoriesCommands}