const en = {
  Russian: "Russian",
  English: "English",
  Turkish: "Turkish",
  Greek: "Greek",
  Deutsch: "Deutsch",
  RussianCountry: "Russia",
  EnglishCountry: "USA",
  TurkishCountry: "Turkey",
  GreekCountry: "Greece",
  DeutschCountry: "Germany",
  "Post Your Ad": "Post Your Ad",
  Contact: "Contact",
  Language: "Language",
  Home: "Home",
  MainSlogan: "Buy, Sell, Rent &amp; Exchange in one click",
  MainSloganSubtitle:
    "Search from over 2000+ Active Ads in 29+ Categories for Free",
  "Select location": "Select Location",
  "Select Category": "Select Category",
  "Enter Keyword here": "Enter Keyword here",
  Search: "Search",
  "Last news": "Last news",
  "The best places": "The best places",
  "Recent announcements": "Recent announcements",
  "Open the ad catalog": "Open the ad catalog",
  All: "All",
  Recommended: "Recommended",
  "New advs": "New advs",
  "Popular advs": "Popular advs",
  "Ad Categories": "Ad Categories",
  "Login/Register": "Login/Register",
  Auto: "Auto",
  Realty: "Realty",
  Work: "Work",
  Services: "Services",

  Filter: "Filter",
  Type: "Type",
  Category: "Category",
  Location: "Location",
  "Price Range": "Price Range",
  "Apply Filters": "Apply Filters",
  Showing: "Showing",
  of: "of",
  results: "results",

  "Sort By": "Sort By",
  "A to Z (title)": "A to Z (title)",
  "Z to A (title)": "Z to A (title)",
  "Data Added (oldest)": "Data Added (oldest)",
  "Most Viewed": "Most Viewed",
  "Less Viewed": "Less Viewed",
  "Price (low to high)": "Price (low to high)",
  "Price (high to low)": "Price (high to low)",
  Next: "Next",
  Previous: "Previous",
  New: "New",
  "Show Phone No": "Show Phone No",
  "Add to Favourites": "Add to Favourites",
  Sell: "Sell",
  views: "views",
  Details: "Details",
  Features: "Features",
  "Report Abuse": "Report Abuse",
  Share: "Share",
  "Related Ads": "Related Ads",
  "Leave a Review": "Leave a Review",
  "Your email address will not be published. Required fields are marked":
    "Your email address will not be published. Required fields are marked",
  "Your Name": "Your Name",
  "Your Email": "Your Email",
  "Review Title": "Review Title",
  "Your Review": "Your Review",
  SUBMIT: "SUBMIT",
  "LOAD MORE": "LOAD MORE",
  "Seller Information": "Seller Information",
  "Click to reveal phone number": "Click to reveal phone number",
  "Email to Seller": "Email to Seller",
  "Safety Tips for Buyers": "Safety Tips for Buyers",
  "Meet seller at a public place": "Meet seller at a public place",
  "Check The item before you buy": "Check The item before you buy",
  "Pay only after collecting The item": "Pay only after collecting The item",

  Dollar: "Dollar",
  Euro: "Euro",
  Ruble: "Ruble",
  Pound: "Pound",
  Lira: "Lira",

  Dashboard: "Dashboard",
  "My Listings": "My Listings",
  Store: "Store",
  Favourites: "Favourites",
  Payments: "Payments",
  "Account details": "Account details",
  Logout: "Logout",

  Login: "Login",
  "Username or E-mail": "Username or E-mail",
  Password: "Password",
  "Remember Me": "Remember Me",
  "Forgot your password": "Forgot your password",
  Register: "Register",
  "Username cannot be changed": "Username cannot be changed",
  "Email address": "Email address",
  Username: "Username",
  Phone: "Phone",
  "Repeat password": "Repeat password",

  "Sub Category": "Sub Category",
  "Next step": "Next step",
  "Send for verification": "Send for verification",
  "Product Information": "Product Information",
  Title: "Title",
  "Price Type": "Price Type",
  Fixed: "Fixed",
  Negotiable: "Negotiable",
  "On Call": "On Call",
  Price: "Price",
  Condition: "Condition",
  Used: "Used",
  Brand: "Brand",
  Authenticity: "Authenticity",
  Original: "Original",
  Copy: "Copy",
  Description: "Description",
  "Features List": "Features List",
  Images: "Images",
  "Drop files here to add them.": "Drop files here to add them.",
  "Browse files": "Browse files",
  "Recommended image size to": "Recommended image size to",
  "Image maximum size": "Image maximum size",
  "Allowed image type": "Allowed image type",
  "You can upload up to 10 images": "You can upload up to 10 images",
  "Contact Details": "Contact Details",
  City: "City",
  "Zip Code": "Zip Code",
  Address: "Address",
  "Whatsapp Number": "Whatsapp Number",
  Email: "Email",
  Map: "Map",
  "Don't show the Map": "Don't show the Map",
  "Ad Type": "Ad Type",
  "Personal car": "Personal car",
  "Car bought for sale": "Car bought for sale",
  Color: "Color",
  Video: "Video",
  Mileage: "Mileage",
  "Сar condition": "Сar condition",
  "Not broken": "Not broken",
  Broken: "Broken",
  "VIN or body number": "VIN or body number",
  "Government number": "Government number",
  Marka: "Marka",
  Model: "Model",
  "Year of issue": "Year of issue",
  Body: "Body",
  Generation: "Generation",
  "Engine's type": "Engine's type",
  CarDrive: "Car Drive",
  Transmission: "Transmission",
  "Rudder position": "Rudder position",
  "Prev step": "Prev step",
  "Save and exit": "Save and exit",

  "Basic Information": "Basic Information",
  Account: "Account",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Change Password": "Change Password",
  "WhatsApp Phone": "WhatsApp Phone",
  Website: "Website",
  State: "State/Region",
  Country: "Country",
  "Update Account": "Update Account",
  "Store Images": "Store Images",
  "Store Banner": "Store Banner",
  "Store Logo": "Store Logo",
  "Store Schedule": "Store Schedule",
  "Opening Hours": "Opening Hours",
  "Always open": "Always open",
  "Select Opening Hours": "Select Opening Hours",
  "Store Information": "Store Information",
  Name: "Name",
  Slogan: "Slogan",
  Socials: "Socials",
  "Update Store": "Update Store",
  "Maximum file size": "Maximum file size",
  "My goods": "My goods",
  "Membership Report": "Membership Report",
  Status: "Status",
  Active: "Active",
  Blocked: "Blocked",
  Created: "Created",
  "Ads created": "Ads created",
  "Finished transactions": "Finished transactions",
  "Items in the store": "Items in the store",
  "Goods sold": "Goods sold",
  Property: "Property",
  "Places an ad": "Places an ad",
  Owner: "Owner",
  Mediator: "Mediator",
  Developer: "Developer",
  "Housing type": "Housing type",
  Flat: "Flat",
  Apartments: "Apartments",
  House: "House",
  "Property condition": "Property condition",
  Level: "Level",
  Rooms: "Rooms",
  Studio: "Studio",
  "10 rooms and more": "10 rooms and more",
  "Free layout": "Free layout",
  "Balcony or loggia": "Balcony or loggia",
  Balcony: "Balcony",
  Loggia: "Loggia",
  Wardrobe: "Wardrobe",
  "Panoramic windows": "Panoramic windows",
  Square: "Square",
  "Total space": "Total space",
  "Living space": "Living space",
  "Ceiling height": "Ceiling height",
  Bathroom: "Bathroom",
  Combined: "Combined",
  Separated: "Separated",
  Need: "Need",
  Cosmetic: "Cosmetic",
  Designer: "Designer",
  Repair: "Repair",
  Windows: "Windows",
  "To the yard": "To the yard",
  "To the street": "To the street",
  "To the sunny side": "To the sunny side",
  Furniture: "Furniture",
  Kitchen: "Kitchen",
  "Clothes storage": "Clothes storage",
  "Sleeping places": "Sleeping places",
  Technics: "Technics",
  "Air conditioning": "Air conditioning",
  Fridge: "Fridge",
  "Washing machine": "Washing machine",
  Dishwasher: "Dishwasher",
  "Water heater": "Water heater",
  "About home": "About home",
  "House type": "House type",
  Brick: "Brick",
  Panel: "Panel",
  Blocky: "Blocky",
  Monolithic: "Monolithic",
  "Monolithic - brick": "Monolithic - brick",
  Wooden: "Wooden",
  Concierge: "Concierge",
  "Garbage chute": "Garbage chute",
  Gas: "Gas",
  "Year of construction": "Year of construction",
  "Floors in the house": "Floors in the house",
  "Passenger lift": "Passenger lift",
  "Cargo lift": "Cargo lift",
  None: "None",
  Yard: "Yard",
  Parking: "Parking",
  "Closed area": "Closed area",
  Playground: "Playground",
  "Sports ground": "Sports ground",
  Underground: "Underground",
  "Ground multilevel": "Ground multilevel",
  "Open in the yard": "Open in the yard",
  "Behind the gate in the yard": "Behind the gate in the yard",
  "Select a category": "Select a category",
  "Select a subcategory": "Select a subcategory",
  slogan: "Slogan Sloagn Slogan",
  HeaderLinks1Title: "How to Sell Fast",
  HeaderLinks2Title: "Information",
  HeaderLinks3Title: "Help & Support",
  cantcreateads: "You cannot create ads until you have registered!",
  cantcreateads_blocked: "You cannot create ads until you blocked!",
  RegisterOrLogin: "Login",
  notset: "Not set",

  mainpage_title: "AVGU - Bulletin board",
  "new Ad": "new Ad",
  "Main Page": "Main page",
  "Personal cabinet": "Сabinet",
  "No data": "No data",
  wheelLeft: "Steering wheel on the left",
  wheelRight: "Steering wheel on the right",
  Currency: "Currency",
  NewAd: "New Ad",
  NewAdMessage:
    "Your ad has been sent for moderation. After verification, it will be published on the site.",
  "Write a feature in each line eg": "Write a feature in each line eg",
  Feature: "Feature",
  "My Account": "My Account",
  Published: "Published",
  Hidden: "Hidden",
  "Remove from publish": "Remove from publish",
  Publish: "Publish",
  Delete: "Delete",
  "Reviews list": "Reviews list",
  Longitude: "Longitude",
  Latitude: "Latitude",
  "Edit draft": "Edit draft",
  "Edit ad": "Edit ad",
  "photos not uploaded": "Photos not uploaded",

  Loading: "Loading",
  "Ad Cars Details": "Ad Car Details",
  "Ad Prop Details": "Ad Property Details",
  "Left Rudder": "steering wheel on the left",
  "Right Rudder": "steering wheel on the right",
  "Clear Filters": "Clear Filters",
  "Select Country": "Select Country",
  "Select State/Region": "Select State/Region",
  "Select City": "Select City",
  "Country not selected": "Country not selected",
  "Region/State not selected": "Region/State not selected",
  "City not selected": "City not selected",
  "Color not selected": "Color not selected",
  "Car Ad type not set": "Car Ad type not set",
  "Mileage not set": "Mileage not set",
  "VIN or body number not set": "VIN or body number not set",
  "Price Type not set": "Price Type not set",
  "Currency not set": "Currency not set",
  "Cost not set": "Cost not set",
  "View Store": "View Store",
  Rating: "Rating",
  "Remove from Favourites": "Remove from Favourites",
  "Deal completed": "Deal completed",
  Yes: "Yes",
  No: "No",
  "Visit Website": "Visit Website",
  "Published Ads": "Published Ads",
  "Registered Users": "Registered Users",
  "Verified Users": "Verified Users",
  Ads: "Ads",
  "ad catalog": "Ad catalog",
  Info: "Info",
  "Open news": "Open news",
  "Total ads": "Total ads",
  "Total users": "Total users",
  "Total shops": "Total shops",
  "Total deals": "Total deals",
  "Rules for posting ads": "Rules for posting ads",
  Advertising: "Advertising",
  "Open locations": "Open locations",
  Contacts: "Contacts",
  "In start": "In start",
  LastVisit: "Last visit",
};

export default en;
