import React, { MouseEventHandler, useContext, useEffect, useState, useRef } from "react";
import {useSelector, useDispatch} from 'react-redux'

import Moment from 'react-moment';
import moment from 'moment';

import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { Store } from 'react-notifications-component';
import { css } from "@emotion/react";
import {User} from '../../models/User'
import ClipLoader from "react-spinners/ClipLoader";
import graphql from "../../gql/gqlqueries";
import { AdItem, currencyType } from "../../models/AdItem";
import { GetByLanguage } from "../langs/languages";
import { Link } from "react-router-dom";
import {AppContext} from '../../App'
import ReduxActions from '../../redux/reducers/actions'

import { FiDollarSign } from 'react-icons/fi';
import {BsCurrencyEuro} from 'react-icons/bs'
import {BiRuble} from 'react-icons/bi'
import {BiPound} from 'react-icons/bi'
import {BiLira} from 'react-icons/bi'

interface GoodItemProps
{
    good?: AdItem;
    lang?: string;
    OnPublishedChange?:(id:string)=>void;
    OnRemoved?:(id:string)=>void;
}

const GetAddress = (item:AdItem | undefined, lang:string)=>
{
    switch (lang)
    {
        case "ru": return item?.address_ru ?? "";
        case "en": return item?.address_eng ?? "";
        case "tur": return item?.address_tur ?? "";
        case "greek": return item?.address_greek ?? "";
        case "deutsch": return item?.address_deutsch ?? "";
    }

    return "";
}

const GetCurrencySymvol = (currency:currencyType | string)=>
{
    switch (currency.toUpperCase())
    {
        case "RUBLE": return <BiRuble/>;
        case "DOLLAR": return <FiDollarSign />;
        case "LIRA": return <BiLira/>;
        case "POUND": return <BiPound/>;
        case "EURO": return <BsCurrencyEuro/>;
    }

    return "";
}

const GoodItem = (props:GoodItemProps) =>
{
    var context = useContext(AppContext)
    var state = context.dataStore.getState()
    const userProfile = useSelector((state: any) => state.user)
    const dispatcher = useDispatch()

    const [DropAdFromServer] = useMutation(graphql.mutations.DROP_AD, {
        onCompleted:(data)=>
            {
                Store.addNotification({
                    title: "Удаление",
                    message: "Ваше объявление удалено.",
                    type: "success",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  });

                  if (props?.OnRemoved) props?.OnRemoved(props.good?.id ?? "")
            },
            onError: (error) =>
            {
                Store.addNotification({
                    title: "Ошибки сервера!",
                    message: error.message,
                    type: "danger",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  });
            }
    });

    const [AdPublish] = useMutation(graphql.mutations.PUBLISH_AD, {
        onCompleted:(data)=>
            {
                if (props?.OnPublishedChange) props?.OnPublishedChange(props.good?.id ?? "")
            },
            onError: (error) =>
            {
                Store.addNotification({
                    title: "Ошибки сервера!",
                    message: error.message,
                    type: "danger",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  });
            }
    });

    const DropAd = () =>
    {
        if (!props.good) return;

        DropAdFromServer({
            variables: {
                id: props.good.id
            }
        })
    }

    const StopPublishAd = () =>
    {
        if (!props.good) return;

        AdPublish({
            variables: {
                id: props.good.id, publish: false
            }
        })
    }

    const PublishAd = () =>
    {
        if (!props.good) return;

        AdPublish({
            variables: {
                id: props.good.id, publish: true
            }
        })
    }

    const EditAd = (id:string | undefined) =>
    {
        if (!id) return;


    }

    return <div className="list-view-layout1">
    <div className="product-box-layout3">
        {(props.good?.photos && (props.good?.photos!!.length > 0)) ? 
        (
            <div className="item-img">
                <img src={`/media/resources/${props.good?.id}/`+props.good?.photos[0] ?? ""} alt="" style={{width:200}} />
            </div>
        ) : <></>}
        <div className="product-info">
            <div className="item-content">
                <h3 className="item-title"><Link to={"/ad/"+props.good?.id}>{props.good ? GetByLanguage(props.good, props.lang ?? "eng") : ""}</Link>
                 {props.good?.Actived ? <p>{context.langdata["Published"]}</p> : <span>{context.langdata["Hidden"]}</span>}</h3>
                <ul className="entry-meta">
                    <li><i className="far fa-clock"></i><Moment format="DD.MM.YYYY HH:mm">{props.good?.createdAt}</Moment></li>
                    <li><i className="fas fa-map-marker-alt"></i>{GetAddress(props.good, props.lang ?? "ru")}</li>
                    <li><i className="far fa-eye"></i>{props.good?.views ?? 0} {context.langdata["Views"]}</li>
                </ul>
                <ul className="item-condition">
                    <li><span>{context.langdata["Condition"]}:</span> {context.langdata["New"]}</li>
                    <li><span>{context.langdata["Brand"]}:</span> {props.good?.brand ?? ""}</li>
                </ul>
                {props?.OnPublishedChange ? (
                <div className="btn-group">
                    {!props.good?.draft && props.good?.Actived ? <a href="javascript:void(0)" onClick={()=>StopPublishAd()}>{context.langdata["Remove from publish"]}</a> : <a href="javascript:void(0)" onClick={()=>PublishAd()}>{context.langdata["Publish"]}</a>}
                    {props.good?.Actived == false ? <a href="javascript:void(0)" onClick={()=>DropAd()}>{context.langdata["Delete"]}</a> : <></>}
                </div>):(<></>)}
                {props.good?.Actived == false ? (
                <div className="btn-group">
                    {props.good?.draft == true ? <Link to={"/editad/"+props.good?.id}>{context.langdata["Edit draft"]}</Link> : <Link to={"/editad/"+props.good?.id}>{context.langdata["Edit ad"]}</Link>}
                </div>) :(<></>)}
            </div>
            <div className="item-right">
                <div className="item-price">
                    <span className="currency-symbol">{GetCurrencySymvol(props.good?.currency ?? "")}</span>
                    {props.good?.cost}
                </div>
                <div className="item-btn">
                    <Link to={"/ad/"+props.good?.id}>{context.langdata["Details"]}</Link>
                </div>
            </div>
        </div>
    </div>
</div>
}

export {GoodItem, GetAddress, GetCurrencySymvol}