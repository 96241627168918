import React, { useContext, useState } from "react";
import {
    Routes,
    Route,
    Link,
} from "react-router-dom"

import { AppContext } from '../../App'

interface IBreadcroombsProps
{
    title:string,
}


const Breadcroombs = (props: IBreadcroombsProps) => {
    var context = useContext(AppContext)
    

    return  <section className="inner-page-banner" data-bg-image="media/banner/banner1.jpg">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs-area">
                    <h1>{props.title}</h1>
                    <ul>
                        <li>
                            <Link to="/">{context.langdata['Home']}</Link>
                        </li>
                        <li>{context.profile?.Account() ?? context.langdata['My Account']}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
}

export default Breadcroombs
