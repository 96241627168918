/* eslint import/no-webpack-loader-syntax: off */

import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from 'react-redux'
import { Routes, Route, Link, useParams } from "react-router-dom";
//import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import * as mapboxgl from 'mapbox-gl';
import {
  ApolloProvider, createHttpLink, ApolloClient, InMemoryCache, from, HttpLink, useLazyQuery, ApolloError, useMutation, gql
} from "@apollo/client"; 

import Resizer from "react-image-file-resizer";
import Moment from 'react-moment';
import { AppContext, AppContextData } from "../../App";
import { AdItem, PhotoAlbum, PhotoItem, Review, useAdsList } from "../../models/AdItem";
import { ReviewItem } from "../../models/ReviewItem";
import { GoodItem, GetAddress, GetCurrencySymvol } from "../account/gooditem";
import useAd, {useSubscribe} from "../../hooks/adhooks";
import useReviews from "../../hooks/reviewshooks";
import { CategoryItem } from "../../models/Category";
import { Generation, Marka, Model } from "../../models/CarMarka";
import { ConvertAdCurrency, currencyFormat } from "../productsgrid";
import { AdFavorites, User } from "../../models/User";

import graphql from "../../gql/gqlqueries"
import ReduxActions from '../../redux/reducers/actions';
import { AllAdsFilter, AllAdsHeaderFilter } from "../allads/allads";
import { queries } from "@testing-library/react";
import AdCard from "../adcard/adcard";
import MailBox from "../modals/mailbox";

interface IAdViewHeader {
  item?: AdItem;
  title?: string;
  store?: boolean
}

const AdViewHeader = (props: IAdViewHeader) => {
  var context = useContext(AppContext);
  
  return (
    <section
      className="inner-page-banner"
      data-bg-image="/media/figure/topic_statistics.jpg"
      style={{backgroundImage: `url(/media/figure/topic_statistics.jpg)`}}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumbs-area">
              <h1>
                {props.title != "" ? props.title : ""}
                {!props.title && context.lang == "ru" ? props?.item?.title_ru : ""}
                {!props.title && context.lang == "en" ? props?.item?.title_eng : ""}
                {!props.title && context.lang == "tur" ? props?.item?.title_tur : ""}
                {!props.title && context.lang == "greek" ? props?.item?.title_greek : ""}
                {!props.title && context.lang == "deutsch" ? props?.item?.title_deutsch : ""}
              </h1>
              <ul>
                <li>
                  <Link to="/">{context.langdata["Home"]}</Link>
                </li>
                
                {props.store ? <li>
                  <a href="javascript:void(0)">{context.langdata["Store"]}</a>
                </li> : <></>}

              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

interface IAdViewLightBox {
  item: AdItem;
}

const LighBoxGallery = (props: IAdViewLightBox) => {

  var [previews, SetPreviews] = useState<string[]>([]);
  var [photos, SetPhotos] = useState<string[]>([]);

  const resizeFile = (file:any, width:number, height:number) =>  new Promise((resolve) => {
    Resizer.imageFileResizer(
    file,
    width,
    height,
    "JPEG",
    100,
    0,
    (uri:any) => {
        resolve(uri);
    },
    "bse64"
    );
});


  async function getFileFromUrl(url:string, name:string, defaultType = 'image/jpeg'){
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  useEffect(() => {
  /*
      var previewsArray:string[] = [];

        props.item?.photos?.map(async (photo: string, index: number) => {
            var fileData = await getFileFromUrl(`/media/resources/${props.item?.id}/${photo}?`+(new Date().toDateString()), photo)
            var previewimage = await resizeFile(fileData, 160, 110) as string
            previewsArray.push(previewimage);
        });
      
      SetPreviews(previewsArray)
        */
  }, [props.item, props.item?.photos]);

  return (
    <div className="item-img-gallery">
      
      <div className="tab-content">
        {props.item.photos?.map((photo: string, index: number) => {
            return  <div
            className={"tab-pane fade " + (index == 0 ? "show active" : "")}
            id={"gallery"+index}
            role="tabpanel"
            key={"photo_"+index}
          >
            <a href="javascript:void(0)">
              <img className="zoom_01" src={`/media/resources/${props.item?.id}/${photo}?`} alt="product" data-zoom-image={`/media/resources/${props.item?.id}/${photo}?`} />
            </a>
          </div>
        })}
      </div>
    
      <ul className="nav nav-tabs" role="tablist">
        {props.item.photos?.map((photo: string, index: number) => {
          return <li className="nav-item" key={"preview_"+index}>
          <a
            className={"nav-link " + (index == 0 ? "active" : "")}
            data-toggle="tab"
            href={"#gallery"+index}
            role="tab"
            aria-selected="true"
          >
            <img style={{width: "auto", height: "110px", overflow: "hidden"}}
              src={`/media/resources/${props.item?.id}/${photo}?`}
              alt="thumbnail"
            />
          </a>
        </li>
        })}
      </ul>

    </div>
  );
};

interface IAdView {}

const AdDetails = ({ad, cars}:{ad:AdItem, cars:any}) => {
    var context = useContext(AppContext);
    
    return <div className="item-review light-shadow-bg">
      <h3 className="widget-border-title">
          {ad.category.autocar ? context.langdata["Ad Cars Details"] : <></>}
          {ad.category.property ? context.langdata["Ad Prop Details"] : <></>}
      </h3>

      {ad.category.autocar ? (
      <div className="light-box-content">
        <p>
          VIN: {ad.vin}
        </p>
        <p>
          Номер автомобиля: {ad.carnumber}
        </p>
        <p>
          Цвет: {cars.Edits && cars.Edits.CarColor ? cars.Edits.CarColor.find((item: any) => item.id == ad.color?.id)?.colorName_ru : ""}
        </p>
        <p>
          Пробег: {ad.mileage}
        </p>
        <p>
          Состояние автомобиля: {ad.state}
        </p>
        <p>
          Год выпуска: {ad.makeYear}
        </p>
        <p>
          Кузов: {cars.Edits && cars.Edits.CarBody ? cars.Edits.CarBody.find((item: any) => item.id == ad.cusov?.id)?.bodytype_ru : ""}
        </p>
        <p>
          Двигатель: {cars.Edits && cars.Edits.CarEngine ? cars.Edits.CarEngine.find((item: any) => item.id == ad.engine?.id)?.engine_ru : ""}
        </p>
        <p>
          Привод: {cars.Edits && cars.Edits.CarDrive ? cars.Edits.CarDrive.find((item: any) => item.id == ad.driveunit?.id)?.name_ru : ""}
        </p>
        <p>
          Коробка передач: {cars.Edits && cars.Edits.CarTransmission ? cars.Edits.CarTransmission.find((item: any) => item.id == ad.transmission?.id)?.transmission_ru : ""}
        </p>

        {ad.rudderposition>0 ? (
        <p>
          Положение руля: {ad.rudderposition == 1 ? context.langdata["Left Rudder"] : context.langdata["Right Rudder  "]}
        </p>
        ) : <></>}

      </div> ) : <></>}

      {ad.category.property ? ( <div className="light-box-content">
        <p>
          Адрес недвижимости: {ad.address_ru}
        </p>
        <p>
          Недвижимость: {ad.proptype}
        </p>
        <p>
          Состояние: {ad.propstate}
        </p>
        <p>
          Этаж: {ad.level} из {ad.levelsInHome}
        </p>
        <p>
          Количество комнат: {ad.roomsCount}
        </p>
        <p>
          Балкон {ad.balcony ? "ДА" : "НЕТ"}, Лоджия {ad.loggia ? "ДА" : "НЕТ"}, Гардероб {ad.wardrobe ? "ДА" : "НЕТ"}, Панорамные окна {ad.panoramicwindows ? "ДА" : "НЕТ"}
        </p>
        <p>
          Площадь общая {ad.totalarea}, жилая {ad.livingspace}
        </p>
        <p>
          Высота потолков {ad.сeilingheight}
        </p>
        <p>
          Санузел {ad.bathroom}
        </p>
        <p>
            окна во двор {ad.windowstothecourtyard ? "ДА" : "НЕТ"}, окна на улицу {ad.windowstothecourtyard ? "ДА" : "НЕТ"}, окна на солнечную сторону {ad.windowsonthesunnyside ? "ДА" : "НЕТ"}
        </p>
        <p>
            Кухня {ad.kitchen ? "ДА" : "НЕТ"}, хранение одежду {ad.clothesstorage ? "ДА" : "НЕТ"}, спальные места {ad.sleepingplaces ? "ДА" : "НЕТ"}
        </p>
        <p>
            Кондиционер {ad.airconditioner ? "ДА" : "НЕТ"}, холодильник {ad.fridge ? "ДА" : "НЕТ"}, стиральная машина {ad.washingmachine ? "ДА" : "НЕТ"}, посудомоечная машина {ad.dishwasher ? "ДА" : "НЕТ"}, водонагреватель {ad.waterheater ? "ДА" : "НЕТ"}
        </p>
        <p>
            Ремонт {ad.repair}
        </p>
        </div>) : <></>}
    </div>
}

const LocationMap = () => {

  mapboxgl.accessToken = 'pk.eyJ1IjoiaXZrb21lIiwiYSI6ImNsOGN5Mm96NTBuanczdnE5dzAxNXV3eWUifQ.y7jzCxJf2lsg5hFTaKH_VQ';

  var context = useContext(AppContext);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  useEffect(() => {
      if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom
      });
  });

  return (
    <div className="item-google-map light-shadow-bg">
      <h3 className="widget-border-title">{context.langdata["Location"]}</h3>
      <div className="google-map light-box-content">
        <div ref={mapContainer} className="map-container" style={{ width: "100%", height: "460px" }} />
      </div>
    </div>
  );
};

interface IReviewProps {
    id: string
    context: AppContextData;
    reviews?: Review[];
    LoadMoreViews?: () => void;
}

const RelatedAds = ({context}:IReviewProps) => {
  
  var adslist: AdItem[] = [];

  if (adslist.length > 0) {
    return (
      <div className="item-related-product light-shadow-bg">
        <div className="flex-heading-layout2">
          <h3 className="widget-border-title">
            {context.langdata["Related Ads"]}
          </h3>
          <div id="owl-nav1" className="smart-nav-layout1">
            <span className="rt-prev">
              <i className="fas fa-angle-left"></i>
            </span>
            <span className="rt-next">
              <i className="fas fa-angle-right"></i>
            </span>
          </div>
        </div>
        <div className="light-box-content">
          <div
            className="rc-carousel"
            data-loop="true"
            data-items="4"
            data-margin="30"
            data-custom-nav="#owl-nav1"
            data-autoplay="false"
            data-autoplay-timeout="3000"
            data-smart-speed="1000"
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-r-x-small="1"
            data-r-x-small-nav="false"
            data-r-x-small-dots="false"
            data-r-x-medium="2"
            data-r-x-medium-nav="false"
            data-r-x-medium-dots="false"
            data-r-small="2"
            data-r-small-nav="false"
            data-r-small-dots="false"
            data-r-medium="2"
            data-r-medium-nav="false"
            data-r-medium-dots="false"
            data-r-large="3"
            data-r-large-nav="false"
            data-r-large-dots="false"
            data-r-extra-large="3"
            data-r-extra-large-nav="false"
            data-r-extra-large-dots="false"
          >
            {adslist.map((item, index) => {
              return (
                <div className="product-box-layout1 box-shadwo-light mg-1">
                  <div className="item-img">
                    <a href="single-product1.html">
                      <img
                        src="/media/product/product1.jpg"
                        alt="Product"
                      />
                    </a>
                  </div>
                  <div className="item-content">
                    <h3 className="item-title">
                      <a href="single-product1.html">
                        HD 27 inch Mac 1 year used only
                      </a>
                      <span>New</span>
                    </h3>
                    <ul className="entry-meta">
                      <li>
                        <i className="far fa-clock"></i>4 months ago
                      </li>
                      <li>
                        <i className="fas fa-map-marker-alt"></i>Port Chester,
                        New York
                      </li>
                    </ul>
                    <div className="item-price">
                      <span className="currency-symbol">$</span>
                      2,500
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return <></>
};

const Raiting = ({startraiting, onChange}:{startraiting:number, onChange:(raiting:number)=>void}) => {

  var [tempraiting, SetTempRaiting] = useState(startraiting);
  var [raiting, SetRatinig] = useState(startraiting);

  useEffect(()=>{
    onChange(raiting)
  }, [raiting])


  return <>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(1)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(1)} style={{fontSize:30, color: raiting>=1 || tempraiting>=1 ? "#f85c70" : ""}}></i></a>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(2)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(2)} style={{fontSize:30, color: raiting>=2 || tempraiting>=2 ? "#f85c70" : ""}}></i></a>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(3)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(3)} style={{fontSize:30, color: raiting>=3 || tempraiting>=3 ? "#f85c70" : ""}}></i></a>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(4)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(4)} style={{fontSize:30, color: raiting>=4 || tempraiting>=4 ? "#f85c70" : ""}}></i></a>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(5)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(5)} style={{fontSize:30, color: raiting>=5 || tempraiting>=5 ? "#f85c70" : ""}}></i></a>
  </>
}

const AddReview = ({id, context}:IReviewProps) => {

  var user = useSelector((state: any) => state.user);
  var [caption, SetCaption] = useState("");
  var [review, SetReview] = useState("");
  var [rating, SetRating] = useState(0);
  var [dealdone, SetDealDone] = useState<Boolean | null>(null);

  const [AddReview] = useMutation(graphql.mutations.ADD_REVIEW,
    {
        fetchPolicy: "network-only",
        onCompleted:(data)=>
        {
            //context.dispatch(ReduxActions.favorites.ToggleFavoriteCountry(adid, data.toggleFavorite))
        },
        onError: (error) =>
        {
            
        }
    });    

  const RaitingSet = (raiting:number) => {
    SetRating(raiting)
  }

  const SendReview = (e:any) => {
      e.stopPropagation()
      e.preventDefault()
      AddReview({variables:{id: id, caption: caption, review:review, rating:rating, lang:"", completed:false}})
  }

  return (user ? 
    <div className="item-review light-shadow-bg">
      <h3 className="widget-border-title">
        {context.langdata["Leave a Review"]}
      </h3>
      <div className="light-box-content">
        <form action="#">
          <div className="item-text">
            {
              context.langdata[
                "Your email address will not be published. Required fields are marked"
              ]
            }{" "}
            *
          </div>
          
          {!user ? (<>
          <div className="form-group">
            <label>{context.langdata["Your Name"]} *</label>
            <input type="text" className="form-control" name="name" value={user?.UserF +" "+ user?.UserI} />
          </div>
          <div className="form-group">
            <label>{context.langdata["Your Email"]} *</label>
            <input type="email" className="form-control" name="email" value={user?.email} />
          </div></> ) : (<></>)}

          <div className="form-group">
            <label>{context.langdata["Review Title"]} *</label>
            <input type="text" className="form-control" name="review" value={caption} onChange={(e)=>SetCaption(e.target?.value ?? "")} />
          </div>
          <div className="form-group">
            <label>{context.langdata["Your Review"]} *</label>
            <textarea
              className="form-control textarea"
              name="message"
              id="message"
              value={review} onChange={(e)=>SetReview(e.target?.value ?? "")}
              style={{ columnCount: 30 }}
            ></textarea>
          </div>
          <div className="form-group">
            <label>{context.langdata["Rating"]} *</label>
            <div className="w-100 d-block">
              <Raiting startraiting={rating} onChange={RaitingSet} />
            </div>
          </div>
          <div className="form-group">
            <label>{context.langdata["Deal completed"]} *</label>
             
            <div className="w-100 d-block">
              <button className={dealdone == true ? "item-btn mr-2" : "item-btn item-btn-hover mr-2"} onClick={(e)=>{
                e.preventDefault()
                e.stopPropagation()
                SetDealDone(true)}}>
                {context.langdata["Yes"]}
              </button>

              <button className={dealdone == false ? "item-btn" : "item-btn item-btn-hover"} onClick={(e)=>{
                e.preventDefault()
                e.stopPropagation()
                SetDealDone(false)}}>
                {context.langdata["No"]}
              </button>
            </div>
          </div>
          <div className="form-group text-center">
            <button className="item-btn" onClick={SendReview}>
              {context.langdata["SUBMIT"]}
            </button>
          </div>
        </form>
      </div>
    </div> : <></>
  );
};

const Reviews = ({context, reviews, LoadMoreViews}:IReviewProps) => {
  return reviews==undefined ? <></> : <div className="item-review light-shadow-bg">
    <h3 className="widget-border-title">
      {context.langdata["Reviews list"]}
    </h3>
    <div className="light-box-content">
      
      {reviews?.map((item, index) => {
          return <div className="blog-box-layout2">
            <div className="item-content">
                <ul className="blog-entry-meta">
                    <li><i className="far fa-calendar-alt"></i><Moment format="DD.MM.YYYY HH:mm">{item.createdAt}</Moment></li>
                    <li><i className="fas fa-user"></i><a href="javascript:void(0)">{item.owner?.account ?? ""}</a></li>
                </ul>
                {item.review_ru}
            </div>
         </div>
      })}
      
      <form className="text-center" >
        <div className="form-group">
            <a href="javascript:void(0);" className="item-btn" onClick={(e)=>{e.stopPropagation(); if (LoadMoreViews) LoadMoreViews()}}>
              {context.langdata["LOAD MORE"]}
            </a>
        </div>
      </form>
    </div>

  </div>
}

const ShopView = (props: IAdView) => {
  //var [aditem, setAditem] = useState<AdItem>()
  
  const currency = useSelector((state:any)=>state.currency)
  const categories = useSelector((state: any) => state.categories)
  var userfavorites = useSelector((state:any)=>state.userfavorites)
  const cars = useSelector((state: any) => state.cars)

  let { userid } = useParams();
  var context = useContext(AppContext);
  const [adsList, setadsList] = useState<AdItem[]>([])
  const [userdata, setUserData] = useState<User | undefined>(undefined)
  const [preparedadsList, setPreparedadsList] = useState<AdItem[]>([])

  var adsListLoader = useAdsList((data)=>
    {
        setTotalAds(data?.adslist?.total ?? 0)
        setadsList(data?.adslist?.items ?? [])
    }, 
    (error)=>{
        console.log(`Не удалось загрузить объявления! [${error}].`)
    })

    var [userProfile] = useLazyQuery(graphql.querues.USER_DETAILS_QUERY,{
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted:(data)=>
      {
        setUserData(data.user)
      },
      onError: (error) =>
      {
        console.log(`Не удалось загрузить данные пользователя! [${error}].`)
      }
    })
    
  
  var [view, SetView] = useState("grid")
  const [totalads, setTotalAds] = useState(0)
  var [sorting, setSorting] = useState(localStorage.getItem("sorting") ?? "A->Z")

  useEffect(() => {
    adsListLoader({variables:{startKey: (new Date()).toISOString(), itemsCount: 25, adstype: "All ads", actived: true,  sort: sorting, search:"", owner: userid}})
  }, []);

  useEffect(() => {
    adsListLoader({variables:{startKey: (new Date()).toISOString(), itemsCount: 25, adstype: "All ads", actived: true,  sort: sorting, search:"", owner: userid}})
  }, [sorting])


  useEffect(() => {
    userProfile({variables:{id: userid}})
  }, [userid])


  useEffect(()=>
    {
        //Dollar, Euro, Ruble, Pound, Lira
        adsList.forEach((ad:AdItem)=>
        {
            var newad = ConvertAdCurrency(ad, context.currency, currency)
            ad.recalculatedcost = newad.recalculatedcost
        })

        setPreparedadsList([...adsList])

    }, [currency, context.currency, adsList])


  return (
    <>
      {adsList == undefined ? (
        <div className="d-block text-center">{context.langdata["Loading"]}...</div>
      ) : (<>

    {/* <AdViewHeader title={userdata?.store?.name} store={true} ></AdViewHeader> */}

    <section className="store-banner-wrap-layout1 bg-accent">
            <div className="container">
                <div className="store-banner-box" style={{backgroundImage: `url(${userdata?.shopbanner})`}}>
                    <div className="banner-content">
                        <div className="store-logo">
                            <img src={userdata?.shoplogo} alt="RadiusTheme" />
                        </div>
                        <div className="store-content">
                            <h2 className="item-title">{userdata?.store?.name}</h2>
                            <div className="store-tagline">{userdata?.store?.slogan}</div>
                            <ul className="item-meta">
                                <li><i className="fas fa-map-marker-alt"></i>{userdata?.store?.address}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="store-wrap-layout2  bg-accent">
            <div className="container">
                <div className="row">
                    <div className="col-xl-9 col-lg-8">
                        <AllAdsHeaderFilter SetSortBy={setSorting} sorting={sorting} totalItems={totalads} itemsPerPage={10} startItenmNumber={totalads>0 ? 1 : 0} OnToggleView={(view) =>{ SetView(view)} }></AllAdsHeaderFilter>
                        <div id="product-view" className={view == "list" ? "product-box-list" : "product-box-grid"}>
                            <div className="row">
                                  {preparedadsList.map((item:AdItem, index:number) => {

                                      var infav = false
                                      return <AdCard key={index} view={view} new={false} item={item} infavorites={infav}></AdCard>
                                  })}
                            </div>
                        </div>
                        <div className="pagination-layout1">
                            <div className="btn-prev disabled">
                                <a href="#"><i className="fas fa-angle-double-left"></i>Previous</a>
                            </div>
                            <div className="page-number">
                                <a href="#" className="active">1</a>
                                <a href="#">2</a>
                            </div>
                            <div className="btn-next">
                                <a href="#">Next<i className="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 sidebar-break-md sidebar-widget-area">
                        <div className="widget-lg widget-store-detail widget-light-bg">
                            <h3 className="widget-border-title">{context.langdata["Details"]}</h3>
                            <div className="store-content">
                                <p>
                                  {userdata?.store?.description ?? ""}</p>
                            </div>
                        </div>
                        <div className="widget-lg widget-author-info widget-store-info widget-light-bg">
                            <h3 className="widget-border-title">{context.langdata["Store Information"]}</h3>
                            <div className="author-content">
                                <div className="store-website">
                                    <a target={"_blank"} href={userdata?.store?.website}><i className="fas fa-globe-asia"></i>{context.langdata["Visit Website"]}</a>
                                </div>
                                <div className="store-website">
                                    {userdata?.store?.phone1 != "" ? <a href={`tel:${userdata?.store?.phone1}`}><i className="fas fa-phone"></i>{userdata?.store?.phone1}</a> : <></>}
                                </div>
                                <div className="store-website">
                                    {userdata?.store?.phone2 != "" ? <a href={`tel:${userdata?.store?.phone2}`}><i className="fas fa-phone"></i>{userdata?.store?.phone2}</a> : <></>}
                                </div>
                                <div className="store-website">
                                    {userdata?.store?.email1 != "" ? <a href={`email:${userdata?.store?.email1}`}><i className="fa fa-envelope"></i>{userdata?.store?.email1}</a> : <></>}
                                </div>
                                <div className="store-website">
                                    {userdata?.store?.email2 != "" ? <a href={`email:${userdata?.store?.email1}`}><i className="fa fa-envelope"></i>{userdata?.store?.email2}</a> : <></>}
                                </div>
                                {userdata?.store?.houers!="" ? 
                                <div className="store-opening-hour">
                                    <span className="item-title"><i className="far fa-clock"></i>{context.langdata["Opening Hours"]}</span>
                                    <ul>
                                      {
                                          (userdata?.store?.houers ?? "").split(/\r?\n/).map((item, index) => {
                                            var chanks = item.split(":",1)
                                            var endofstr = item.replace(chanks[0]+":","")
                                            return <li><span>{chanks[0]}</span>{endofstr}</li>
                                          })
                                      }
                                    
                                    </ul>
                                </div> : <></>}
                                <div className="phone-number classima-phone-reveal not-revealed" data-phone={userdata?.phone}>
                                    <div className="number"><i className="fas fa-phone"></i><span>{userdata?.phone.substring(0, 5)+"XXXXX"}</span></div>
                                    <div className="item-text">{context.langdata["Click to reveal phone number"]}</div>
                                </div>
                                <div className="author-mail">
                                    <a href="#" className="mail-btn" data-toggle="modal" data-target="#author-mail">
                                        <i className="fas fa-envelope"></i>{context.langdata["Email to Seller"]}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>   
       
        <MailBox></MailBox>

        </>
      )}
    </>
  );
};

export default ShopView;
export {AdViewHeader}