import * as React from 'react';

import {
    ApolloProvider, createHttpLink, ApolloClient, InMemoryCache, from, HttpLink, useLazyQuery, ApolloError
  } from "@apollo/client"; 

import graphql from "../gql/gqlqueries";
import { type } from 'os';

type Marka = 
{
    id:string
    Marka_ru:String
    Marka_eng:String
    Marka_tur:String
    Marka_greek:String
    Marka_deutsch:String
    Actived:Boolean
}

type Model = 
{
    id:string
    markaId:string
    Model_ru:String
    Model_eng:String
    Model_tur:String
    Model_greek:String
    Model_deutsch:String
    Actived:boolean
}

type Generation = 
{
    id:string
    markaId:string
    modelId:string
    Generation_ru:String
    Generation_eng:String
    Generation_tur:String
    Generation_greek:String
    Generation_deutsch:String
    Icon:String
    Cartype:string
}

const useCarMarka = (OnDone:(data:any)=>void, OnError?:(error:ApolloError)=>void) =>
{
    const [LoadCategories, { loading:l6, error:userAccountError, data:d6 }] = useLazyQuery(graphql.querues.CAR_MARKS,
        {
            fetchPolicy: "network-only",
            nextFetchPolicy: "network-only",
            onCompleted:function (data)
            {
                OnDone(data)
            },
            onError: (error) =>
            {
                if (OnError) OnError(error)
            }
        });    

    return LoadCategories
}

const useCarModel = (OnDone:(data:any)=>void, OnError?:(error:ApolloError)=>void) =>
{
    const [LoadCategories, { loading:l6, error:userAccountError, data:d6 }] = useLazyQuery(graphql.querues.CAR_MODELS,
        {
            fetchPolicy: "network-only",
            nextFetchPolicy: "network-only",
            onCompleted:function (data)
            {
                OnDone(data)
            },
            onError: (error) =>
            {
                if (OnError) OnError(error)
            }
        });    

    return LoadCategories
}

const useCarGeneration = (OnDone:(data:any)=>void, OnError?:(error:ApolloError)=>void) =>
{
    const [LoadCategories, { loading:l6, error:userAccountError, data:d6 }] = useLazyQuery(graphql.querues.CAR_GENERATIONS,
        {
            fetchPolicy: "network-only",
            nextFetchPolicy: "network-only",
            onCompleted:function (data)
            {
                OnDone(data)
            },
            onError: (error) =>
            {
                if (OnError) OnError(error)
            }
        });    

    return LoadCategories
}

export {useCarMarka, useCarModel, useCarGeneration}
export type {Marka, Model, Generation}