import { ReducerAction } from "./reducers"

const CarsCommands = {
    MARKS_LOADED: 'MARKS_LOADED',
    MODELS_LOADED: 'MODELS_LOADED',
    GENERATIONS_LOADED: 'GENERATIONS_LOADED',
    CAREDITS_LOADED: 'CAREDITS_LOADED'
}

export const cars = (state:any = null, action:ReducerAction) => {
    switch (action.type) {
        case CarsCommands.MARKS_LOADED:
            var newstate = {...state};
            newstate["Marks"] = action.params
            return newstate
        case CarsCommands.MODELS_LOADED:
            var newstate = {...state};
            newstate["Models"] = action.params
            return newstate
        case CarsCommands.GENERATIONS_LOADED:
            var newstate = {...state};
            newstate["Generations"] = action.params
            return newstate
        case CarsCommands.CAREDITS_LOADED:
            var newstate = {...state};
            newstate["Edits"] = action.params
            return newstate
        default:
            return state
    }
}

export {CarsCommands}